import React from 'react';
import { Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCart } from '../../../contexts/CartContext';
import AnimatedFab from '../shared/AnimatedFab';
import shoppingCartIntro from '../../../assets/lottie/shopping-cart-intro.json';
import addToShoppingCart from '../../../assets/lottie/add-to-shopping-cart.json';

export default function CartFloatingButton() {
    const { cart } = useCart();

    return (
        <Link to={cart.uuid ? `/cart/${cart.uuid}` : '#'}>
            <AnimatedFab
                fabColor="primary"
                animationData={shoppingCartIntro}
                repeatAnimation={cart.qty}
                repeatAnimationData={addToShoppingCart}
                sx={{
                    bottom: 90,
                }}
            >
                <Badge
                    badgeContent={cart.qty ? cart.qty : 0}
                    showZero
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        position: 'relative',
                        top: -22,
                        right: -1,

                        '& .MuiBadge-badge': {
                            border: '2px solid',
                            borderColor: 'primary.main',
                            backgroundColor: 'neutral.100',
                            color: 'neutral.900',
                            fontSize: 11,
                            fontWeight: 600,
                            minWidth: 24,
                            height: 24,
                            borderRadius: 24,
                        },
                    }}
                />
            </AnimatedFab>
        </Link>
    );
}
