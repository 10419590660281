import { apiUrl } from './config';
import PersistentStorage from './PersistentStorage';

class DataController {
    constructor() {
        this.baseURL = apiUrl;
        this.apiCallInc = 0;
    }

    async APICall(url, opts) {
        // logging
        console.log(`[DataController] APICall ${this.apiCallInc}`, url, opts);
        this.apiCallInc++;

        const fullUrl = url.startsWith('http') ? url : this.baseURL + url;

        // legacy API call if opts.raw
        // does not transform to JSON
        if (opts.raw) {
            delete opts.raw;
            return await fetch(fullUrl, opts);
        }

        try {
            const res = await fetch(fullUrl, opts);
            const resJSON = await res.json();

            // console.log('[DataController] fetching: ', fullUrl);
            // console.log('[DataController] resJSON: ', resJSON);

            // TODO: throw an error if not success
            // See how to forward an error to caller
            // if (!resJSON.success) {
            //     throw new Error(resJSON.error);
            // }

            return resJSON;
        } catch (e) {
            // TODO: log an error - show a toast
            console.error('[DataController] error message: ', e.message);
        }
    }

    APICallAuth(url, opts) {
        const user = PersistentStorage.get('user');

        if (!user) {
            console.log('[DataController] token not found');
            return new Promise((resolve, reject) =>
                resolve({
                    success: false,
                })
            );
        }

        const authHeaders = {
            Accept: 'application/json',
            Authorization: `Bearer ${user.token}`,
        };
        const optHeaders = opts.headers ? opts.headers : {};
        const newHeaders = { ...authHeaders, ...optHeaders };

        const newOpts = Object.assign(opts, {
            headers: newHeaders,
        });

        return this.APICall(url, newOpts);
    }

    APICallAuthOptional(url, opts) {
        const user = PersistentStorage.get('user');

        let authHeaders;
        if (user) {
            authHeaders = {
                Accept: 'application/json',
                Authorization: `Bearer ${user.token}`,
            };
        } else {
            authHeaders = {
                Accept: 'application/json',
            };
        }

        const optHeaders = opts.headers ? opts.headers : {};
        const newHeaders = { ...authHeaders, ...optHeaders };

        const newOpts = Object.assign(opts, {
            headers: newHeaders,
        });

        return this.APICall(url, newOpts);
    }

    login({ phone }) {
        const formData = new FormData();
        formData.append('phone', phone);

        const opt = {
            method: 'POST',
            body: formData,
        };

        return this.APICall(`/verify/sms`, opt);
    }

    validate({ phone, code }) {
        const formData = new FormData();
        formData.append('phone', phone);
        formData.append('code', code);

        const opt = {
            method: 'POST',
            body: formData,
        };

        return this.APICall(`/verify/sms/validate`, opt);
    }

    verifyInvite({ inviteCode }) {
        const opt = {
            method: 'GET',
        };

        return this.APICall(`/verify/invite/${inviteCode}`, opt);
    }

    requestOneTimeLink({ phone }) {
        const formData = new FormData();
        const uri = window.location.pathname;

        formData.append('phone', phone);
        formData.append('uri', uri);

        const opt = {
            method: 'POST',
            body: formData,
        };

        return this.APICall(`/verify/invite/`, opt);
    }

    user = {
        get: () => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/me`, opt);
        },
    };

    post = {
        get: ({ hashId, pin, lang }) => {
            const opt = {
                method: 'GET',
            };

            const paramsObj = {};

            if (pin) {
                const pinBase64 = btoa(pin);
                paramsObj.pin = pinBase64;
            }

            if (lang) {
                paramsObj.lang = lang;
            }

            const params = new URLSearchParams(paramsObj);

            let url = `/post/${hashId}?${params.toString()}`;

            return this.APICallAuthOptional(url, opt);
        },

        getSummary: ({ hashId }) => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/post/${hashId}/summary`, opt);
        },

        create: ({ tagId }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/post/create/${tagId}`, opt);
        },

        edit: ({ hashId, data }) => {
            const formData = new FormData();

            if ('title' in data) {
                formData.append('title', data.title);
            }

            if ('body' in data) {
                formData.append('body', data.body);
            }

            if ('is_private' in data) {
                if (data.is_private && !data.pin) {
                    throw new Error("Can't submit private post without pin");
                }

                formData.append('is_private', data.is_private || 0);
                formData.append('pin', data.pin);
            }

            if ('is_geocoded' in data) {
                if (data.is_geocoded && (!data.lat || !data.lng)) {
                    throw new Error(
                        "Can't submit geocoded post without coords"
                    );
                }
                formData.append('is_geocoded', data.is_geocoded || 0);
                formData.append('lat', data.lat);
                formData.append('lng', data.lng);
            }

            if ('is_translated' in data) {
                formData.append('is_translated', data.is_translated || 0);
            }

            if ('folder_id' in data) {
                formData.append('folder_id', data.folder_id);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/post/${hashId}/edit`, opt);
        },

        publish: ({ hashId }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/post/${hashId}/publish`, opt);
        },

        delete: ({ hashId }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/post/${hashId}/delete`, opt);
        },
    };

    tag = {
        validate: ({ tagId, isScan }) => {
            const opt = {
                method: 'GET',
            };

            const isScanParam = isScan ? '1' : '0';

            return this.APICallAuthOptional(
                `/tag/${tagId}?scan=${isScanParam}`,
                opt
            );
        },
    };

    media = {
        uploadProgressAuth: (url, opts, onProgress, auth = true) => {
            return new Promise((resolve, reject) => {
                try {
                    const request = new XMLHttpRequest();

                    request.open(
                        opts.method || 'POST',
                        url.startsWith('http') ? url : `${this.baseURL}${url}`,
                        true
                    );

                    const token = PersistentStorage.get('token');
                    //request.setRequestHeader('Content-Type', 'multipart/form-data');
                    request.setRequestHeader('Accept', 'application/json');
                    if (auth)
                        request.setRequestHeader(
                            'Authorization',
                            `Bearer ${user.token}`
                        );

                    request.upload.addEventListener('progress', (e) => {
                        if (typeof onProgress === 'function')
                            onProgress((e.loaded / e.total) * 100);
                    });

                    request.addEventListener('load', (e) => {
                        resolve(
                            opts.raw
                                ? request.response
                                : JSON.parse(request.response)
                        );
                    });

                    request.addEventListener('error', (e) =>
                        resolve({ error: e })
                    );
                    request.addEventListener('abort', (e) =>
                        resolve({ error: e })
                    );

                    request.send(opts.body);
                } catch (e) {
                    resolve({ error: e });
                }
            });
        },

        getFileUploadConfig: (filename) => {
            const opts = {
                method: 'GET',
            };

            return this.APICallAuth(
                `/media/upload/config?filename=${filename}`,
                opts
            );
        },

        postAssociateFiles: (postId, data) => {
            const opts = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            };

            return this.APICallAuth(`/media/associate/${postId}`, opts);
        },

        delete: ({ id }) => {
            const opts = {
                method: 'POST',
            };

            return this.APICallAuth(`/media/${id}/delete`, opts);
        },

        edit: ({ id, is_cover, caption }) => {
            const formData = new FormData();
            formData.append('is_cover', is_cover);
            formData.append('caption', caption);

            const opts = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/media/${id}/edit`, opts);
        },
    };

    link = {
        create: ({ postId, url }) => {
            const formData = new FormData();
            formData.append('url', url || '');

            const opts = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/link/create/${postId}`, opts);
        },

        delete: ({ id }) => {
            const opts = {
                method: 'POST',
            };

            return this.APICallAuth(`/link/${id}/delete`, opts);
        },
    };

    team = {
        get: ({ post_id }) => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/my/team?post_id=${post_id}`, opt);
        },

        invite: ({ team_id, name, phone, post_id, uuid }) => {
            const formData = new FormData();

            if (!phone && !uuid)
                return console.error('Either phone or uuid are required!');

            if (post_id) formData.append('post_id', post_id);
            if (name) formData.append('name', name);
            if (phone) formData.append('phone', phone);
            if (uuid) formData.append('uuid', uuid);

            const opts = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/team/${team_id}/invite`, opts);
        },

        uninvite: ({ team_id, phone, uuid, post_id }) => {
            const formData = new FormData();
            formData.append('post_id', post_id);

            if (uuid) formData.append('uuid', uuid);
            if (phone) formData.append('phone', phone);

            const opts = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/team/${team_id}/uninvite`, opts);
        },
    };

    folders = {
        get: () => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/my/folders`, opt);
        },

        create: ({ title }) => {
            if (!title)
                return console.error(
                    '[DataController folder.create]: title must be set'
                );

            const formData = new FormData();
            formData.append('title', title);

            const opts = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/folder/create`, opts);
        },
    };

    feed = {
        getAllFolders: (pageNum = 1) => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/feed/folders?page=${pageNum}`, opt);
        },

        getAllTags: ({ pageNum = 1, folderId, filterParamsString }) => {
            const opt = {
                method: 'GET',
            };

            let folderIdParam = '';
            if (folderId) {
                folderIdParam = `folder_id=${folderId}`;
            }

            return this.APICallAuth(
                `/feed/tags?${folderIdParam}&page=${pageNum}&${filterParamsString}`,
                opt
            );
        },
    };

    products = {
        get: () => {
            const opt = {
                method: 'GET',
            };

            return this.APICall(`/products`, opt);
        },
    };

    product = {
        getPrice: ({ size, color, roll, qty }) => {
            const opt = {
                method: 'GET',
            };

            return this.APICall(
                `/product/price?color=${color}&size=${size}&roll=${roll}&qty=${qty}`,
                opt
            );
        },
    };

    checkout = {
        get: ({ uuid }) => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuthOptional(`/checkout/${uuid}`, opt);
        },

        create: () => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuthOptional(`/checkout/create`, opt);
        },

        addItem: ({ uuid, size, color, roll, qty }) => {
            const formData = new FormData();

            formData.append('size', size);
            formData.append('color', color);
            formData.append('roll', roll);
            formData.append('qty', qty);

            const opt = {
                body: formData,
                method: 'POST',
            };

            return this.APICallAuthOptional(
                `/checkout/${uuid}/item/create`,
                opt
            );
        },

        editItem: ({ uuid, itemId, qty }) => {
            const formData = new FormData();
            formData.append('qty', qty);

            const opt = {
                body: formData,
                method: 'POST',
            };

            return this.APICallAuthOptional(
                `/checkout/${uuid}/item/${itemId}/edit`,
                opt
            );
        },

        deleteItem: ({ uuid, itemId, qty }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuthOptional(
                `/checkout/${uuid}/item/${itemId}/delete`,
                opt
            );
        },

        claim: ({ uuid }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/checkout/${uuid}/claim`, opt);
        },

        setShipping: ({ uuid, fields }) => {
            const formData = new FormData();

            for (let key in fields) {
                formData.append(key, fields[key]);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/checkout/${uuid}/shipping`, opt);
        },

        setPayment: ({ uuid, token, paymentmethod_id }) => {
            const formData = new FormData();

            if (token) {
                formData.append('token', token);
            }
            if (paymentmethod_id) {
                formData.append('paymentmethod_id', paymentmethod_id);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/checkout/${uuid}/payment`, opt);
        },

        transact: ({ uuid }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/checkout/${uuid}/transact`, opt);
        },
    };

    addresses = {
        create: ({ fields }) => {
            const formData = new FormData();

            for (let key in fields) {
                formData.append(key, fields[key]);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/address/create`, opt);
        },

        makeDefault: ({ id }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/address/${id}/default`, opt);
        },

        delete: ({ id }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/address/${id}/delete`, opt);
        },

        edit: ({ id, fields }) => {
            const formData = new FormData();

            for (let key in fields) {
                formData.append(key, fields[key]);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/address/${id}/edit`, opt);
        },
    };

    profile = {
        editEmail: (email) => {
            const formData = new FormData();
            formData.append('email', email);

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/my/profile`, opt);
        },
        editPhone: (phone) => {
            const formData = new FormData();
            formData.append('phone', phone);

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/my/phone`, opt);
        },
    };

    phone = {
        edit: () => {},
    };

    orders = {
        get: () => {
            const opt = {
                method: 'GET',
            };

            return this.APICallAuth(`/my/orders`, opt);
        },
    };

    paymentmethods = {
        createCard: ({ token }) => {
            const formData = new FormData();

            formData.append('token', token);

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/paymentmethod/create/card`, opt);
        },

        makeDefault: ({ id }) => {
            const formData = new FormData();

            formData.append('is_default', 1);

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/paymentmethod/${id}/edit`, opt);
        },

        delete: ({ id }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/paymentmethod/${id}/delete`, opt);
        },
    };

    companies = {
        create: ({ fields }) => {
            const formData = new FormData();

            for (let key in fields) {
                formData.append(key, fields[key]);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/company/create`, opt);
        },

        delete: ({ id }) => {
            const opt = {
                method: 'POST',
            };

            return this.APICallAuth(`/company/${id}/delete`, opt);
        },

        edit: ({ id, fields }) => {
            const formData = new FormData();

            for (let key in fields) {
                formData.append(key, fields[key]);
            }

            const opt = {
                method: 'POST',
                body: formData,
            };

            return this.APICallAuth(`/company/${id}/edit`, opt);
        },
    };

    fetchTestLang() {
        const opt = {
            method: 'GET',
        };

        return this.APICall(`/test/language`, opt);
    }
}

export default new DataController();
