import React from 'react';
import { Box, Stack } from '@mui/material';

export default function ImageStack({ children }) {
    return (
        <Box
            sx={{
                width: 'calc(100% + 16px)',
                overflowX: 'auto',
                pb: 1.5,
            }}
        >
            <Stack direction="row" spacing={1} alignItems="flex-start">
                {children}
                <Box
                    sx={{
                        // end of row spacer
                        width: 8,
                        flexShrink: 0,
                    }}
                ></Box>
            </Stack>
        </Box>
    );
}
