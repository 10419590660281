import React from 'react';
import { Box, CircularProgress, FormControlLabel, Switch } from '@mui/material';

export default function ListaSwitch({
    label,
    color = 'primary',
    labelPlacement = 'start',
    onChange,
    checked,
    loading,
    disabled,
    sx,
    ...other
}) {
    return (
        <FormControlLabel
            control={
                <Box sx={{ position: 'relative' }}>
                    {loading && (
                        <CircularProgress
                            size={16}
                            pr={1.5}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                left: checked ? 40 : 8,
                                zIndex: 1,
                            }}
                            color={checked ? 'white' : color}
                        />
                    )}
                    <Switch
                        className={loading ? 'Lista-loading' : ''}
                        color={color}
                        onChange={(e) => onChange(e)} // read e.target.checked
                        checked={checked}
                        disabled={disabled}
                    />
                </Box>
            }
            label={label}
            labelPlacement={labelPlacement}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                ml: 0,
                mb: 3,
                ...sx,
            }}
            {...other}
        />
    );
}
