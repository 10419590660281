import React from 'react';
import { Stack, Typography, Box, Divider } from '@mui/material';
import Frame from '../shared/Frame';

export default function OrderSummary({ order }) {
    return (
        <Stack spacing={2}>
            {order.contents.items.map((item, index) => (
                <Frame condensed key={index}>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box
                                sx={{
                                    width: 60,
                                    height: 60,

                                    '& img': {
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'center center',
                                    },
                                }}
                            >
                                <img src={item.image} />
                            </Box>
                            <Stack>
                                <Typography variant="caption">
                                    {item.summary.title}
                                </Typography>
                                <Typography variant="body2" color="neutral.700">
                                    {item.summary.subtitle}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                width: '100%',
                                py: 1,
                            }}
                        >
                            <Typography variant="caption">
                                {item.qty}
                            </Typography>
                            <Typography variant="caption">
                                {item.price_formatted}
                            </Typography>
                        </Stack>
                    </Stack>
                </Frame>
            ))}

            <Divider />

            {order.receipt_lines.map((row, index) => (
                <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Typography
                        variant={row.is_total ? 'caption' : 'body2'}
                        color={row.is_total ? 'neutral.700' : 'neutral.500'}
                    >
                        {row.description}
                    </Typography>
                    <Typography variant="caption" color="neutral.800">
                        {row.value}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
}
