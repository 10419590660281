import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PersistentStorage from '../lib/PersistentStorage.js';
import initialState from './initialState.js';
import actions from './actions.js';
import reducer from './reducer.js';
import DataController from '../lib/DataController.js';

const _initialState = Object.assign(initialState, PersistentStorage.getAll());

const store = createContext(_initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (state.user && state.user.token) {
            DataController.user
                .get()
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        dispatch({ type: actions.SET_USER, payload: data });
                    }
                });
        }
    }, []);

    useEffect(() => {
        console.log('[store] state: ', state);
    }, [state]);

    return <Provider value={{ state, dispatch, actions }}>{children}</Provider>;
};

const useAppState = () => {
    return useContext(store);
};

export { StateProvider, useAppState };
