import MoreMenu from '../shared/MoreMenu';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import DataController from '../../../lib/DataController';
import { useAppState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import ListaDialog from '../shared/ListaDialog';
import { useEffect } from 'react';
import AddressForm from '../shared/AddressForm';
import ListaButton from '../shared/ListaButton';
import CompaniesForm from './CompaniesForm';

const CompaniesMoreMenu = ({ company }) => {
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const { setConfirmationPrompt } = useConfirmationPrompt();

    const [loading, setLoading] = useState(false);

    const [edit, setEdit] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editFields, setEditFields] = useState({});
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (edit) {
            setEditFields({
                name: company.name || '',
                email: company.email || '',
                phone: company.phone || '',
                website: company.website || '',
            });
            setTimeout(() => setEditOpen(true), 10);
        }
    }, [edit]);

    useEffect(() => {
        if (!editOpen) {
            setTimeout(() => {
                setEdit(false);
                setEditFields({});
                setErrors(null);
            }, 200);
        }
    }, [editOpen]);

    const options = [
        {
            label: 'Edit',
            onClick: () => setEdit(true),
        },
        {
            label: <Typography color="error">Delete</Typography>,
            onClick: () => {
                setConfirmationPrompt({
                    title: 'Are you sure?',
                    body: "This action can't be undone.",
                    confirmText: 'Yes, delete',
                })
                    .then(() => {
                        setLoading(true);

                        DataController.companies
                            .delete({ id: company.id })
                            .then(({ success, data, errors, message }) => {
                                if (success) {
                                    dispatch({
                                        type: actions.DELETE_COMPANY,
                                        payload: company.id,
                                    });
                                    enqueueSnackbar(message);
                                } else {
                                    console.error(message);
                                    enqueueSnackbar(message, {
                                        variant: 'error',
                                    });
                                }
                            })
                            .finally(() => setLoading(false));
                    })
                    .catch(() => {
                        console.log('canceled');
                        setLoading(false);
                    });
            },
        },
    ];

    const saveEdits = () => {
        setLoading(true);

        DataController.companies
            .edit({ id: company.id, fields: editFields })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({
                        type: actions.EDIT_COMPANY,
                        payload: data,
                    });
                    enqueueSnackbar(message);
                    setEditOpen(false);
                } else {
                    setErrors(errors);
                    console.error(message);
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <MoreMenu options={options} loading={loading} />
            {edit && (
                <ListaDialog
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    fullScreen={true}
                    title={'Edit company'}
                    actions={
                        <ListaButton
                            variant="contained"
                            fullWidth
                            onClick={saveEdits}
                            loading={loading}
                        >
                            Save
                        </ListaButton>
                    }
                >
                    <Box p={2}>
                        <CompaniesForm
                            fields={editFields}
                            setFields={setEditFields}
                            errors={errors}
                        />
                    </Box>
                </ListaDialog>
            )}
        </>
    );
};

export default CompaniesMoreMenu;
