import React, { useState } from 'react';
import palette from '../../../theme/palette';
import { Box, FormControl, InputLabel } from '@mui/material';

export default function StripeElementWrapper({ Component, label }) {
    const [isFocused, setIsFocused] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (e) => {
        if (e.empty) {
            setIsDirty(false);
        } else {
            setIsDirty(true);
        }

        setIsError(!!e.error);
        setErrorText(e.error && e.error.message);
    };

    return (
        <FormControl
            sx={{
                border: '2px solid',
                borderColor: isFocused ? 'primary.main' : 'neutral.400',
                borderRadius: 2,
                height: 52,
                display: 'flex',
                alignItems: 'center',
                padding: '24px 14px 4px',

                '& .StripeElement': {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',

                    '& div': {
                        width: '100%',
                    },
                },

                '& .MuiInputLabel-shrink': {
                    transform: 'scale(0.65) translate(22px, 8px)',
                },
            }}
        >
            <InputLabel shrink={isFocused || isDirty}>{label}</InputLabel>
            <Component
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                options={{
                    style: {
                        base: {
                            fontFamily: '"Poppins", sans-serif',
                            fontSize: '17px',
                            fontWeight: 'normal',
                            lineHeight: '24px',
                            color: palette.neutral[900],

                            '::placeholder': {
                                color: 'white',
                            },
                        },
                    },
                }}
            />
        </FormControl>
    );
}
