import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useWindowSize(maxWidth = 428) {
    const [size, setSize] = useState([
        window.innerWidth,
        document.documentElement.clientHeight,
        window.innerWidth,
    ]);
    const location = useLocation();

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < maxWidth) {
                setSize([
                    window.innerWidth,
                    window.innerHeight,
                    window.innerWidth,
                ]);
            } else {
                setSize([maxWidth, window.innerHeight, window.innerWidth]);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [maxWidth, location]);

    return size;
}
