import React from 'react';
import { useState, useContext } from 'react';
import { Typography, Dialog, Stack } from '@mui/material';
import Button from '@mui/material/Button';

const ConfirmationPrompt = React.createContext();

function ConfirmationPromptProvider({ children }) {
    const [confirm, setConfirm] = useState(null);

    const setConfirmationPrompt = async ({
        title,
        body,
        confirmText,
        cancelText,
        hasCancel = true,
    }) => {
        let promiseResolve;
        let promiseReject;

        let promise = new Promise(function (resolve, reject) {
            promiseResolve = resolve;
            promiseReject = reject;
        });

        setConfirm({
            title,
            body,
            confirmText: confirmText ? confirmText : 'Yes',
            cancelText: cancelText ? cancelText : 'Cancel',
            promiseResolve,
            promiseReject,
            hasCancel,
        });

        return promise;
    };

    const handleConfirm = () => {
        confirm.promiseResolve();
        setConfirm(null);
    };

    const handleCancel = () => {
        confirm.promiseReject();
        setConfirm(null);
    };

    return (
        <ConfirmationPrompt.Provider
            value={{
                setConfirmationPrompt,
            }}
        >
            {children}

            <Dialog open={!!confirm} onClose={handleCancel}>
                <Stack
                    p={2}
                    spacing={2}
                    alignItems="center"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {confirm && confirm.title && (
                        <Typography variant="subtitle1" element="h2">
                            {confirm.title}
                        </Typography>
                    )}

                    {confirm && confirm.body && (
                        <Typography variant="body2">{confirm.body}</Typography>
                    )}

                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        sx={{
                            width: '100%',
                            maxWidth: 300,
                        }}
                    >
                        {confirm && confirm.hasCancel && (
                            <Button
                                variant="contained"
                                color="neutral"
                                onClick={handleCancel}
                                fullWidth
                            >
                                {confirm && confirm.cancelText}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            fullWidth
                        >
                            {confirm && confirm.confirmText}
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </ConfirmationPrompt.Provider>
    );
}

const useConfirmationPrompt = () => {
    return useContext(ConfirmationPrompt);
};

export { ConfirmationPromptProvider, useConfirmationPrompt };
