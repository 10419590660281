import { Grid, Box, Typography } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import PageWrapModal from '../../layout/PageWrapModal';
import { useLocation, useParams } from 'react-router-dom';
import Tag from '../../elements/feed/tag/Tag';
import Header from '../../elements/post/Header';
import palette from '../../../theme/palette';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import CenteredCircularProgress from '../../elements/shared/CenteredCircularProgress';
import { useTags } from '../../../contexts/TagsContext';
import { useAppState } from '../../../store/store';
import { debounce } from '../../../lib/functions';

const truncateTitle = (title) => {
    if (title?.length <= 20) {
        return title;
    }
    return `${title?.slice(0, 20)}...`;
};

export default function ViewFolder() {
    const { folderId } = useParams();
    const { dispatch, actions } = useAppState();
    const { pathname } = useLocation();
    const [windowWidth, windowHeight] = useWindowSize();

    const { isLoading, getTags, tags, folderTitle, invalidate } = useTags();

    const intObserver = useRef();
    const lastTagRef = useCallback(
        (tag) => {
            if (isLoading) {
                return;
            }

            if (intObserver.current) {
                intObserver.current.disconnect();
            }

            intObserver.current = new IntersectionObserver((tags) => {
                if (tags[0].isIntersecting) {
                    getTags(folderId);
                }
            });

            if (tag) {
                intObserver.current.observe(tag);
            }
        },
        [isLoading]
    );

    useEffect(() => {
        if (tags.length < 1) {
            getTags(folderId);
        }

        dispatch({
            type: actions.SET_TAGS_GRID_CONTEXT,
            payload: {
                grid: 'folder',
                folderId: folderId,
            },
        });
    }, []);

    useEffect(() => {
        const setScrollOffset = debounce(() => {
            if (pathname.includes('/folder/')) {
                dispatch({
                    type: actions.SET_TAGS_SCROLL_OFFSET,
                    payload: window.scrollY,
                });
            }
        }, 200);

        window.addEventListener('scroll', setScrollOffset);
        return () => {
            window.removeEventListener('scroll', setScrollOffset);
        };
    }, [pathname]);

    return (
        <PageWrapModal>
            {isLoading && tags.length === 0 ? (
                <CenteredCircularProgress />
            ) : (
                <>
                    <Header
                        title={
                            truncateTitle(folderTitle) || `Folder ${folderId}`
                        }
                        isFolderView
                    />
                    {tags.length > 0 ? (
                        <Box
                            sx={{
                                px: 1,
                                mt: 9,
                            }}
                        >
                            <Grid container spacing={1}>
                                {tags.map((tag, i) => {
                                    if (tags.length === i + 1) {
                                        return (
                                            <Tag
                                                key={i}
                                                ref={lastTagRef}
                                                data={tag}
                                            />
                                        );
                                    }

                                    return <Tag key={i} data={tag} />;
                                })}
                            </Grid>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: windowHeight,
                                width: windowWidth,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: palette.neutral[600],
                                }}
                            >
                                Folder is empty.
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </PageWrapModal>
    );
}
