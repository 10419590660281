import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { IMaskInput } from 'react-imask';

export default function PhoneInput({ value, onChange, placeholder }) {
    return (
        <FormControl variant="outlined">
            <OutlinedInput
                value={value}
                onChange={onChange}
                name="textmask"
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
                placeholder={placeholder}
                type="tel"
            />
        </FormControl>
    );
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            unmask={true}
            onAccept={(unmaskedValue) => onChange(unmaskedValue)}
            overwrite
        />
    );
});
