import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppState } from '../../store/store';

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const { state, dispatch, actions } = useAppState();

    useEffect(() => {
        if (pathname === '/' || pathname.includes('/folder/')) {
            window.scroll({ top: state.scrollOffset.tags });
        } else {
            window.scroll({ top: 0, left: 0 });
        }
    }, [pathname]);

    useEffect(() => {
        if (
            pathname === '/' ||
            pathname.includes('/folder/') ||
            pathname.includes('/post/')
        )
            return;

        console.log('CLEAR TRIGGERED');
        dispatch({
            type: actions.SET_TAGS_SCROLL_OFFSET,
            payload: 0,
        });
    }, [pathname]);

    return null;
}
