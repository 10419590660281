import { Box, Stack, Typography } from '@mui/material';
import ListaDialog from '../shared/ListaDialog';
import ListaButton from '../shared/ListaButton';
import ListaTextField from '../shared/ListaTextField';
import palette from '../../../theme/palette';
import { useState, useCallback } from 'react';
import { useAppState } from '../../../store/store';
import DataController from '../../../lib/DataController';

export default function EmailEditDialog({ open, onClose }) {
    const { state } = useAppState();
    const [email, setEmail] = useState(state?.user?.email || '');
    const [error, setError] = useState('');

    const editEmail = useCallback(async () => {
        const response = await DataController.profile.editEmail(email);

        if (response.success) {
            console.log('[Edit Email]: ', response);
            setError();
            onClose();
        } else {
            console.log('[Edit Email]: ', response);
            setError(response?.errors?.email);
        }
    });

    return (
        <ListaDialog
            open={open}
            onClose={onClose}
            title={'Edit email'}
            actions={
                <ListaButton
                    variant="contained"
                    fullWidth
                    onClick={editEmail}
                    loading={false}
                >
                    Save
                </ListaButton>
            }
        >
            <Box p={2}>
                <Stack spacing={2}>
                    <ListaTextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {error && (
                        <Box mt={1}>
                            <Typography
                                variant="caption"
                                sx={{ color: palette.signalRed.main }}
                            >
                                {error}
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </Box>
        </ListaDialog>
    );
}
