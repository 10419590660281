import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box } from '@mui/material';
import { FormControl, Select } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as BoldToolIcon } from '../../../assets/icons/BoldToolIcon.svg';
import { ReactComponent as UnderlineToolIcon } from '../../../assets/icons/UnderlineToolIcon.svg';
import { ReactComponent as ItalicToolIcon } from '../../../assets/icons/ItalicToolIcon.svg';
import { ReactComponent as BulletListToolIcon } from '../../../assets/icons/BulletListToolIcon.svg';
import { ReactComponent as OrderedListToolIcon } from '../../../assets/icons/OrderedListToolIcon.svg';
import { useState } from 'react';

let Block = Quill.import('blots/block');
let Embed = Quill.import('blots/block/embed');

class HeadingBlot extends Block {}
HeadingBlot.blotName = 'heading';
HeadingBlot.tagName = 'h3';
Quill.register('formats/heading', HeadingBlot);

class Hr extends Embed {
    static create(value) {
        let node = super.create(value);
        // give it some margin
        node.setAttribute(
            'style',
            'height:0px; margin-top:10px; margin-bottom:10px;'
        );
        return node;
    }
}
Hr.blotName = 'hr'; //now you can use .ql-hr classname in your toolbar
Hr.tagName = 'hr';

var customHrHandler = function () {
    // get the position of the cursor
    var range = this.quill.getSelection();
    if (range) {
        // insert the <hr> where the cursor is
        this.quill.insertEmbed(range.index, 'hr', 'null');
    }
};
Quill.register('formats/hr', Hr);

const modules = {
    toolbar: {
        container: '#lista-quill-toolbar',
        handlers: {
            hr: customHrHandler,
        },
    },
};

const formats = [
    'heading', // custom header format
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'hr',
];

export default function RichTextField({ value, onChange, onBlur }) {
    const [focused, setFocused] = useState(false);

    const handleBlur = () => {
        setFocused(false);

        if (onBlur && typeof onBlur === 'function') {
            onBlur();
        }
    };

    const handleFocus = () => {
        setFocused(true);
    };

    return (
        <Box
            sx={{
                '.lista-quill-button': {
                    width: 32,
                    minWidth: 32,
                    height: 32,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 2,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',

                    '&:hover': {
                        backgroundColor: 'secondary.100',
                    },

                    '&.ql-active': {
                        backgroundColor: 'secondary.200',
                    },

                    '&:last-child': {
                        mr: 0,
                    },

                    // revisit this if new formats are added
                    '@media (max-width: 374px)': {
                        mr: 1,
                    },
                },

                '.ql-container': {
                    fontFamily: 'inherit',
                },

                '.ql-editor': {
                    fontFamily: 'inherit',
                    fontSize: 17,
                    lineHeight: '24px',
                    borderRadius: 2,
                    border: '2px solid',
                    borderColor: focused ? 'primary.main' : 'neutral.400',
                    minHeight: 100,

                    h3: {
                        fontSize: 18,
                        lineHeight: '110%',
                        mt: 4,
                        mb: 2,

                        '&:first-of-type': {
                            mt: 0,
                        },
                    },

                    ul: {
                        paddingLeft: 3,
                    },

                    ol: {
                        paddingLeft: 3,
                    },
                },
            }}
        >
            <CustomToolbar key="customToolbar" editorFocused={focused} />

            <ReactQuill
                theme={null}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                modules={modules}
                formats={formats}
                key="quillEditor"
            />
        </Box>
    );
}

const CustomToolbar = ({ editorFocused }) => (
    <Box
        component={motion.div}
        sx={{
            overflow: 'hidden',
        }}
        animate={{
            opacity: editorFocused ? 1 : 0,
            height: editorFocused ? 56 : 0,
            transition: { ease: 'linear' },
        }}
    >
        <Box
            id="lista-quill-toolbar"
            sx={{
                border: 'none',
                fontFamily: 'inherit',
                display: 'flex',
                alignItems: 'center',
                // pb: 2,

                // revisit this if new formats are added
                '@media (max-width: 374px)': {
                    justifyContent: 'space-between',
                },
            }}
        >
            <button className="lista-quill-button ql-heading">
                <Box
                    sx={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: 'secondary.main',
                    }}
                >
                    H
                </Box>
            </button>
            <button className="lista-quill-button ql-bold">
                <BoldToolIcon />
            </button>
            <button className="lista-quill-button ql-underline">
                <UnderlineToolIcon />
            </button>
            <button className="lista-quill-button ql-italic">
                <ItalicToolIcon />
            </button>
            <button className="lista-quill-button ql-list" value="bullet">
                <BulletListToolIcon />
            </button>
            <button className="lista-quill-button ql-list" value="ordered">
                <OrderedListToolIcon />
            </button>
            <button className="lista-quill-button ql-hr">
                <Box sx={{ fontSize: 20, color: 'secondary.main' }}>
                    &#8213;
                </Box>
            </button>

            {/* TODO - add link and divider */}
        </Box>
    </Box>
);
