import { Dialog, Box, Button, Typography, Divider } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import ListaTextButton from '../shared/ListaTextButton';
import { ReactComponent as SpeechBubbleIcon } from '../../../assets/icons/SpeechBubbleIcon.svg';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import Footer from '../post/Footer';

export default function Lightbox({ open, onClose, media, startAt = 0 }) {
    const [windowWidth] = useWindowSize();
    const { t } = useTranslation();

    const video = media.find((item) => item.is_video);
    const langRequested = video && video.lang_requested;

    const slickSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: startAt,

        afterChange: () => {
            // handle pausing/autoPlaying videos on slide change
            const allVideos = document.querySelectorAll(`.slick-slide video`);
            const newVideo = document.querySelector(`.slick-active video`);

            if (allVideos && allVideos.length > 0) {
                for (let i = 0; i < allVideos.length; i++) {
                    allVideos[i].pause();
                    allVideos[i].currentTime = 0;
                }
            }

            if (newVideo) {
                newVideo.play();
                loadVideoSubtitles(newVideo);
            }
        },

        onInit: () => {
            // handle autoplaying video if the video is the first slide that's opened (ie. "beforeChange" not triggered)
            const possibleVideo = document.querySelector(`.slick-active video`);
            if (possibleVideo) {
                possibleVideo.play();
                loadVideoSubtitles(possibleVideo);
            }
        },
    };

    const loadVideoSubtitles = (videoElement) => {
        const tracks = videoElement.querySelectorAll(`track`);

        tracks.forEach((track) => {
            loadTrackWithAjax(track);
        });

        for (let i = 0; i < videoElement.textTracks.length; i++) {
            let track = videoElement.textTracks[i];

            if (track.language === langRequested) {
                track.mode = 'showing';
            } else {
                track.mode = 'hidden';
            }
        }
    };

    function loadTrackWithAjax(track) {
        var xhttp = new XMLHttpRequest();
        const subsUrl = track.getAttribute('data-src');

        xhttp.onreadystatechange = function () {
            if (
                this.readyState == 4 &&
                this.status == 200 &&
                this.responseText
            ) {
                // If VTT fetch succeeded, replace the src with a BLOB URL.
                var blob = new Blob([this.responseText], { type: 'text/vtt' });
                track.setAttribute('src', URL.createObjectURL(blob));
            }
        };
        xhttp.open('GET', subsUrl, true);
        xhttp.send();
    }

    const VideoMediaItem = ({ item, index }) => {
        return (
            <video
                id={`videoElement${index}`}
                poster={item.thumbnail}
                src={item.url}
                playsInline
                controls
            >
                {Object.keys(item.transcriptions).map((key) => (
                    <track
                        key={key}
                        kind="subtitles"
                        data-src={item.transcriptions[key].url}
                        label={key}
                        srcLang={key}
                        default={langRequested === key}
                        id={key}
                    />
                ))}
            </video>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <Box
                sx={{
                    width: windowWidth,
                    margin: '0 auto',
                    height: '100vh',
                    overflow: 'hidden',

                    '.slick-slider': {
                        height: '100%',
                        touchAction: 'auto !important', // needed to allow pinch-zoom
                    },

                    '.slick-list': {
                        height: '100%',
                    },

                    '.slick-track': {
                        height: '100%',
                    },

                    '.slick-slide': {
                        maxHeight: 'calc(100vh - 32px)',

                        '& > div': {
                            height: '100%',
                        },
                    },
                }}
            >
                <Slider {...slickSettings}>
                    {media.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'inline-flex !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 2,

                                img: {
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                },

                                video: {
                                    display: 'block',
                                    width: '100%',
                                    maxHeight: '100%',
                                },
                            }}
                        >
                            <Box>
                              {item.is_video && (
                                  <VideoMediaItem item={item} index={index} />
                              )}
                              
                              {item.is_image && <img src={item.url} />}
                              
                              {item.caption && (
                                  <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                                      {item.caption}
                                  </Typography>
                              )}
                              
                              {item.caption && item.is_video && item.is_transcribing && (
                                  <Divider sx={{ mt: 1, mb: .5 }} />
                              )}
                              
                              {item.is_video && item.is_transcribing && (
                                  <Box
                                      mx={0}
                                      pt={1}
                                      color="neutral.500"
                                      sx={{
                                          display: 'flex',
                                          svg: {
                                              width: 18,
                                              height: 18,
                                              mr: 1,
                                          },
                                      }}
                                  >
                                      <SpeechBubbleIcon />
                                      <Typography
                                          variant="body2"
                                          color="neutral.500"
                                      >
                                          {t('Video transcription is in progress, please standby')}
                                      </Typography>
                                  </Box>
                              )}
                            </Box>
                        </Box>
                    ))}
                </Slider>
                <Footer>
                    <Button variant="containedFullWidth" onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </Box>
        </Dialog>
    );
}
