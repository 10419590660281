import { Button, Divider, Box, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useAppState } from '../../../store/store';
import ListaAccordion from '../shared/ListaAccordion';
import SettingsItem from './SettingsItem';
import { useNavigate } from 'react-router-dom';
import actions from '../../../store/actions';
import EmailEditDialog from './EmailEditDialog';
import PhoneEditDialog from './PhoneEditDialog';

export default function Settings() {
    const navigate = useNavigate();
    const { state, dispatch } = useAppState();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
    const [isPhoneDialogOpen, setIsphoneDialogOpen] = useState(false);

    const toggleIsExpanded = useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    const logout = useCallback(() => {
        dispatch({
            type: actions.DELETE_USER,
        });
        navigate('/');
    }, []);

    return (
        <>
            <ListaAccordion
                expanded={isExpanded}
                onToggle={toggleIsExpanded}
                title="Login & security"
                contentExpanded={
                    <>
                        <SettingsItem
                            onChangeClick={() => setIsEmailDialogOpen(true)}
                            title="Email"
                            value={state?.user?.email}
                        />
                        <SettingsItem
                            onChangeClick={() => setIsphoneDialogOpen(true)}
                            title="Mobile number"
                            value={state?.user?.phone_formatted}
                        />
                        <Divider sx={{ mt: 2 }} />
                        <Box sx={{ overflow: 'hidden' }}>
                            <Button
                                color="secondary"
                                size="small"
                                onClick={logout}
                            >
                                Logout
                            </Button>
                        </Box>
                    </>
                }
            />
            {isEmailDialogOpen &&
                <EmailEditDialog
                    open={isEmailDialogOpen}
                    onClose={() => setIsEmailDialogOpen(false)}
                />
            }
            {isPhoneDialogOpen &&
                <PhoneEditDialog
                    open={isPhoneDialogOpen}
                    onClose={() => setIsphoneDialogOpen(false)}
                />
            }
        </>
    );
}