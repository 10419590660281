const palette = {
    primary: {
        // alpine orange
        main: '#F2A900', // 500
        100: '#FDF5E1',
        200: '#FBE2A7',
        300: '#F8D072',
        400: '#F5BE49',
        500: '#F2A900',
        600: '#D69E36',
        700: '#987024',
        800: '#5C4412',
        900: '#1F1603',
        contrastText: '#FFFFFF',
    },
    secondary: {
        // marine blue
        main: '#22588A', // 700
        100: '#E6F4FE',
        200: '#B4DEFC',
        300: '#85C9FA',
        400: '#5DB3F9',
        500: '#439EF8',
        600: '#337BC0',
        700: '#22588A',
        800: '#002B45',
        900: '#03121B',
    },
    neutral: {
        main: '#8F8F8F', // 500
        100: '#FFFFFF',
        200: '#EFEFEF',
        300: '#CFCFCF',
        400: '#AFAFAF',
        500: '#8F8F8F',
        600: '#707070',
        700: '#505050',
        800: '#303030',
        900: '#101010',
    },

    white: {
        main: '#FFFFFF',
    },

    info: {
        main: '#DEFF10',
    },

    signalRed: {
        400: '#EE564F',
        500: '#EE2324',
        main: '#EE2324', //500
    },
};

export default palette;
