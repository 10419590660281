import { Box } from "@mui/material";
import { ReactComponent as ImageIcon } from '../../../../assets/icons/ImageIcon.svg';
import { ReactComponent as PdfIcon } from '../../../../assets/icons/PdfIcon.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/icons/VideoIcon.svg';

export default function TagIcons({ hasImage, hasPdf, hasVideo }) {
     return (
          <Box sx={{
               display: 'flex',
               flexDirection: 'row',
               width: '100%',
               justifyContent: 'flex-end',
          }}>
               {hasImage &&
                    <Box
                         component={ImageIcon}
                    />
               }
               {hasVideo &&
                    <Box
                         component={VideoIcon}
                    />
               }
               {hasPdf &&
                    <Box
                         component={PdfIcon}
                    />
               }
          </Box>
     );
}