import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import { Box, Stack, CircularProgress, Typography } from '@mui/material';
import PageWrapModal from '../layout/PageWrapModal';
import CartHeader from '../elements/cart/CartHeader';
import { useAppState } from '../../store/store';
import ShippingForm from '../elements/checkout/ShippingForm';
import CheckoutConfirm from '../elements/checkout/CheckoutConfirm';
import DataController from '../../lib/DataController';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';

export default function CheckoutPage() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { getCart, loaded, loading, cart } = useCart();
    const { state, dispatch, actions } = useAppState();
    const [checkoutView, setCheckoutView] = useState('loading'); // loading | address | confirm | summary
    const [settingConfirmPage, setsettingConfirmPage] = useState(false);

    useEffect(() => {
        async function getCurrentCart() {
            await getCart({ uuid });
        }

        if (uuid != cart.uuid) {
            getCurrentCart();
        }
    }, []);

    useEffect(() => {
        console.log('[CheckoutPage] useeffect started');
        if (!cart.address_id && state.user.addresses.length === 0) {
            setCheckoutView('address');
        } else {
            initCheckoutOptions();
        }
    }, [loaded]);

    const initCheckoutOptions = async () => {
        setsettingConfirmPage(true);
        await setShippingAddress();
        await setPaymentMethod();
        setsettingConfirmPage(false);
        setCheckoutView('confirm');
    };

    const setShippingAddress = async () => {
        // try to find and set shipping address based on user's addresses.
        // if cart already has an address associated, skip and use that.
        if (cart.address_id) return;

        if (state.user.addresses && state.user.addresses.length > 0) {
            // try to find default
            // if no default set to first address in array
            let address_id;
            let address = state.user.addresses.find(
                (address) => address.is_default
            );

            if (address) {
                address_id = address.id;
            } else {
                address_id = state.user.addresses[0].id;
            }

            await DataController.checkout
                .setShipping({ uuid, fields: { address_id } })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        dispatch({ type: actions.SET_CART, payload: data });

                        console.log(
                            '[setShippingAddress] Shipping address autoset to address_id: ',
                            address_id
                        );
                    } else {
                        console.log(
                            '[setShippingAddress] API error while setting the address. ',
                            address_id
                        );
                    }
                });
        }
    };

    const setPaymentMethod = async () => {
        // try to find and set paymentmethod based on user's paymentmethods.
        // if cart already has a paymentmethod associated, skip and use that.
        if (cart.paymentmethod_id) return;

        if (
            state.user.payment_methods &&
            state.user.payment_methods.length > 0
        ) {
            // try to find default
            // if no default set to first address in array
            let paymentmethod_id;
            let paymentmethod = state.user.payment_methods.find(
                (paymentmethod) => paymentmethod.is_default
            );

            if (paymentmethod) {
                paymentmethod_id = paymentmethod.id;
            } else {
                paymentmethod_id = state.user.payment_methods[0].id;
            }

            await DataController.checkout
                .setPayment({
                    uuid,
                    paymentmethod_id,
                })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        dispatch({ type: actions.SET_CART, payload: data });
                        console.log(
                            '[setPaymentMethod] Payment method autoset to paymentmethod_id: ',
                            paymentmethod_id
                        );
                    } else {
                        console.log(
                            '[setPaymentMethod] API error while setting the payment method. ',
                            paymentmethod_id
                        );
                    }
                });
        }
    };

    const handleSuccess = (uuid) => {
        navigate(`/track/${uuid}`);
    };

    return (
        <PageWrapModal>
            {!loaded || settingConfirmPage ? (
                <CenteredCircularProgress />
            ) : (
                <Stack mt={10} spacing={2} px={2} pb={20}>
                    <CartHeader title="Checkout" />

                    {checkoutView === 'address' && (
                        <>
                            <Typography variant="h4">Shipping</Typography>
                            <ShippingForm
                                onContinue={() => setCheckoutView('confirm')}
                            />
                        </>
                    )}

                    {checkoutView === 'confirm' && (
                        <CheckoutConfirm onOrderCompleted={handleSuccess} />
                    )}
                </Stack>
            )}
        </PageWrapModal>
    );
}
