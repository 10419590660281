import { useEffect, useRef } from 'react';
import { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DataController from '../lib/DataController';
import { useAppState } from '../store/store';
import { useFilters } from './TagsFilterContext';

const TagsContext = createContext();

function TagsProvider({ children }) {
    const { pathname } = useLocation();
    const { state } = useAppState();

    const { filterParams, enabled: filtersEnabled } = useFilters();

    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [folderTitle, setFolderTitle] = useState('');

    const fetched = useRef(false);
    const meta = useRef({});

    const getTags = (folderId = null) => {
        if (!fetched.current || meta.current.page < meta.current.total_pages) {
            const page = fetched.current ? meta.current.page + 1 : 1;

            setIsLoading(true);

            DataController.feed
                .getAllTags({
                    pageNum: page,
                    folderId,
                    filterParamsString: filtersEnabled ? filterParams : '',
                })
                .then(({ success, message, data, meta: newMeta }) => {
                    if (success) {
                        setTags((prev) => [...prev, ...data]);
                        if (data.length > 0 && data[0].folder_title) {
                            setFolderTitle(data[0].folder_title);
                        }
                    } else {
                        setError(message);
                    }

                    meta.current = newMeta;
                    fetched.current = true;
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (
            pathname === '/' ||
            pathname.includes('/post/') ||
            pathname.includes('/folder')
        )
            return;

        console.log('[TagsContext] path changed, will invalidate');
        invalidate();
    }, [pathname]);

    useEffect(() => {
        console.log('[TagsContext] currentTab changed, will invalidate');
        invalidate();
    }, [state.currentTab]);

    const invalidate = () => {
        setTags([]);
        meta.current = {};
        fetched.current = false;
    };

    const refetchSummary = async (tagHash) => {
        const theTag = tags.find((tag) => tag.hash === tagHash);

        if (!theTag) return;

        try {
            const { success, data, errors, message } =
                await DataController.post.getSummary({ hashId: tagHash });

            if (success) {
                const newTags = [...tags];
                const tagIndex = newTags.findIndex(
                    (tag) => tag.hash === tagHash
                );
                newTags[tagIndex] = data[0];
                setTags(newTags);
            } else {
                console.error(message);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <TagsContext.Provider
            value={{
                isLoading,
                tags,
                error,
                meta,
                getTags,
                invalidate,
                folderTitle,
                refetchSummary,
            }}
        >
            {children}
        </TagsContext.Provider>
    );
}

const useTags = () => {
    return useContext(TagsContext);
};

export { TagsProvider, useTags };
