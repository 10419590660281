import palette from '../palette';

const MuiToggleButton = {
    styleOverrides: {
        root: {
            border: '2px solid',
            borderColor: palette.neutral[400],
            color: palette.neutral[400],

            '&:not(:last-of-type)': {
                borderRight: 'none',
            },

            '&.Mui-selected': {
                color: palette.neutral[700],
                background: palette.neutral[200],
            },
        },
    },
};

export default MuiToggleButton;
