import palette from '../palette';

const MuiLinearProgress = {
    styleOverrides: {
        root: {
            height: 10,
            border: '2px solid',
            borderRadius: 5,
        },

        colorSignalRed: {
            borderColor: palette.signalRed.main,
            backgroundColor: palette.neutral[100],
        },
    },
};

export default MuiLinearProgress;
