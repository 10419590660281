import React from 'react';
import { Stack, MenuItem } from '@mui/material';
import ListaTextField from '../shared/ListaTextField';
import ListaSelect from '../shared/ListaSelect';
import shippingStates from '../../../lib/shippingStates';

export default function AddressForm({ fields, setFields }) {
    const updateField = (key, value) => {
        const newVal = { ...fields };
        newVal[key] = value;
        delete newVal.address_id;
        setFields(newVal);
    };

    return (
        <Stack spacing={2}>
            <ListaTextField
                label="First name"
                value={fields.firstname || ''}
                onChange={(e) => updateField('firstname', e.target.value)}
            />
            <ListaTextField
                label="Last name"
                value={fields.lastname || ''}
                onChange={(e) => updateField('lastname', e.target.value)}
            />
            <ListaTextField
                label="Email"
                type="email"
                value={fields.email || ''}
                onChange={(e) => updateField('email', e.target.value)}
            />
            <ListaTextField
                label="Company"
                value={fields.company || ''}
                onChange={(e) => updateField('company', e.target.value)}
            />
            <ListaTextField
                label="Street"
                value={fields.street || ''}
                onChange={(e) => updateField('street', e.target.value)}
            />
            <ListaTextField
                label="Street2"
                value={fields.street2 || ''}
                onChange={(e) => updateField('street2', e.target.value)}
            />
            <ListaTextField
                label="Street3"
                value={fields.street3 || ''}
                onChange={(e) => updateField('street3', e.target.value)}
            />
            <ListaTextField
                label="City"
                value={fields.city || ''}
                onChange={(e) => updateField('city', e.target.value)}
            />

            <ListaSelect
                label="State"
                labelId="state-select"
                value={fields.state || ''}
                onChange={(e) => updateField('state', e.target.value)}
            >
                {shippingStates.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.title}
                    </MenuItem>
                ))}
            </ListaSelect>

            <ListaTextField
                label="ZIP Code"
                value={fields.zip || ''}
                onChange={(e) => updateField('zip', e.target.value)}
            />

            <ListaSelect
                label="Country/region"
                labelId="country-select"
                disabled
                value={fields.country || ''}
                onChange={(e) => updateField('country', e.target.value)}
            >
                <MenuItem value={'US'}>United States</MenuItem>
            </ListaSelect>
        </Stack>
    );
}
