import ListaThumbPlaceholder from '../assets/img/lista-thumb-placeholder.png';
import PersistentStorage from './PersistentStorage';

export async function generateVideoThumb(file) {
    var fileReader = new FileReader();

    let promiseResolve;
    let promiseReject;

    let promise = new Promise(function (resolve, reject) {
        promiseResolve = resolve;
        promiseReject = reject;
    });

    fileReader.onload = function () {
        var blob = new Blob([fileReader.result], { type: file.type });
        var url = URL.createObjectURL(blob);
        var video = document.createElement('video');

        var timeupdate = function () {
            if (snapImage()) {
                video.removeEventListener('timeupdate', timeupdate);
                video.pause();
            }
        };

        video.addEventListener('loadeddata', function () {
            if (snapImage()) {
                video.removeEventListener('timeupdate', timeupdate);
            }
        });

        var snapImage = function () {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
                .getContext('2d')
                .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
                // var img = document.createElement('img');
                // img.src = image;
                // setThumb(image);
                promiseResolve(image);
                URL.revokeObjectURL(url);
            }
            return success;
        };

        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
    };

    fileReader.readAsArrayBuffer(file);

    return promise;
}

export function getMediaThumb(media) {
    let thumbToShow;

    if (media.temp_thumb) thumbToShow = media.temp_thumb;
    else if (media.has_thumbnail) thumbToShow = media.thumbnail;
    else thumbToShow = ListaThumbPlaceholder;

    return thumbToShow;
}

/**
 * Compare mixed objects
 *
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export const compareMixed = (obj1, obj2) => {
    const getType = (obj) =>
        Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

    const areArraysEqual = () => {
        if (obj1.length !== obj2.length) return false;
        for (let i = 0; i < obj1.length; i++) {
            if (!compareMixed(obj1[i], obj2[i])) return false;
        }
        return true;
    };

    const areObjectsEqual = () => {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
        for (let key in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, key)) {
                if (!compareMixed(obj1[key], obj2[key])) return false;
            }
        }
        return true;
    };

    const areFunctionsEqual = () => obj1.toString() === obj2.toString();

    const arePrimitivesEqual = () => obj1 === obj2;

    const type = getType(obj1);
    if (type !== getType(obj2)) return false;
    if (type === 'array') return areArraysEqual();
    if (type === 'object') return areObjectsEqual();
    if (type === 'function') return areFunctionsEqual();
    return arePrimitivesEqual();
};

export const getPostPinFromPersistentStorage = (hash) => {
    const recent = PersistentStorage.get('recentPosts');

    if (recent) {
        const pin = recent[hash]?.pin || null;
        return pin;
    } else {
        return null;
    }
};

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

export const scrollIntoView = (el) => {
    const OFFSET = 200;
    const top =
        el.getBoundingClientRect().top + document.documentElement.scrollTop;

    setTimeout(() => {
        window.scrollTo({ top: top - OFFSET, behavior: 'smooth' });
    }, 1);
};
