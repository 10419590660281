import lottie from 'lottie-web';
import { useCallback, useEffect, useState } from 'react';

export default function useLottieAnimation(
    ref,
    animationData,
    autoplay = false,
    loop = false
) {
    const [shouldAnimationPlay, setAnimationShouldPlay] = useState(autoplay);
    const [lottieAnimation, setLottiAnimation] = useState(null);

    useEffect(() => {
        let newLottieAnimation = {};
        if (ref.current && animationData) {
            newLottieAnimation = lottie.loadAnimation({
                container: ref.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData,
            });
            newLottieAnimation.goToAndStop(1, true);
            setLottiAnimation(newLottieAnimation);
        }

        if (loop !== false) {
            newLottieAnimation.addEventListener('complete', () => {
                setTimeout(() => {
                    newLottieAnimation.goToAndStop(0, true);
                    newLottieAnimation.play();
                }, loop);
            });
        }

        if (shouldAnimationPlay) {
            newLottieAnimation.play();
        }

        return () => {
            newLottieAnimation.destroy();
        };
    }, [ref.current, shouldAnimationPlay, animationData]);

    const lottieAnimationGoToAndPlay = useCallback(
        (frame) => {
            if (lottieAnimation) {
                lottieAnimation.goToAndPlay(frame, true);
            }
        },
        [lottieAnimation]
    );

    return {
        setAnimationShouldPlay,
        lottieAnimationGoToAndPlay,
        lottieAnimation,
    };
}
