import { Box } from '@mui/material';
import React from 'react';
import AppHeader from './AppHeader';
import sharedStyles from '../../theme/sharedStyles';

export default function AppWrapper({ children }) {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                // backgroundColor: 'neutral.200',
                color: 'neutral.900',
                ...sharedStyles.container,
            }}
        >
            <AppHeader />

            {children}
        </Box>
    );
}
