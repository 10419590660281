const isProduction = window.location.hostname === 'app.lista.codes';

// API Url
const _qaApiUrl = 'https://api-qa.lista.codes/api/v1';
const _prodApiUrl = 'https://lista.codes/api/v1';
export const apiUrl = isProduction ? _prodApiUrl : _qaApiUrl;

// Stripe keys.
const _testStripeKey =
    'pk_test_51LCly2DnBx99Lo0DoWaAVnq97dBPYhTD65TK6kptq8jEajB6dpUTbMB6shSNuCDYit0rB0beUuFrqTRZVARAakkJ00drWzUvLk';
const _liveStripeKey =
    'pk_live_51LCly2DnBx99Lo0DsrWNR9UuyI9mRtD4oSj8c6YuRFTDvMtwQaYQ9pCMvmcC3A8ytzVa5beCWhCHPt8HnSHhAUq200K2sXSqpy';
export const stripeKey = isProduction ? _liveStripeKey : _testStripeKey;

// Algolia
export const algoliaApplicationId = '5UFMQB11UI';
export const algoliaApiKey = 'd1bc90a9770aaddbb56d49edffd1167a';

const algoliaIndexNameQa = 'apiqa_lista';
const algoliaIndexNameProd = 'prod_lista'; // TO BE ADDED
export const algoliaIndexName = isProduction
    ? algoliaIndexNameProd
    : algoliaIndexNameQa;
