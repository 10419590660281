import { Button, Stack, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import DataController from '../../../lib/DataController';
import { useAppState } from '../../../store/store';
import PhoneInput from '../shared/PhoneInput';
import PinInput from '../shared/PinInput';

export default function Login() {
    const { dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { setConfirmationPrompt } = useConfirmationPrompt();

    const [view, setView] = useState('mobile'); // mobile | verification

    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    const [requesting, setRequesting] = useState(false);

    const handleContinue = () => {
        if (!phone) return;

        // TODO: Loading button
        DataController.login({ phone }).then(
            ({ success, errors, data, message }) => {
                console.log({ success, errors, data, message });

                if (success) {
                    setView('verification');
                } else {
                    console.error('[Login] login error: ', errors, message);
                }
            }
        );
    };

    const handleValidate = () => {
        // TODO: handle validation error / wrong code
        // TODO: idea ?? have CANCEL button to redirect anon to shop or lista homepage??

        if (!phone) return;
        if (!code) return;

        setLoading(true);

        DataController.validate({ phone, code }).then(
            ({ success, errors, data, message }) => {
                console.log({ success, errors, data, message });

                if (success) {
                    console.log('[Login - handleValidate] success: ', data);
                    dispatch({
                        type: actions.SET_USER,
                        payload: data,
                    });
                    enqueueSnackbar(message);
                } else {
                    console.error('[Login] login error: ', errors, message);
                }

                setLoading(false);
            }
        );
    };

    const requestOneTimeLink = () => {
        setRequesting(true);

        DataController.requestOneTimeLink({ phone })
            .then(({ success, data, message, errors }) => {
                if (success) {
                    setConfirmationPrompt({
                        body: t('loginRequestPromptBody'),
                        confirmText: t('loginRequestBackToLogin'),
                        hasCancel: false,
                    }).then(
                        () => setView('mobile'),
                        () => setView('mobile')
                    );
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            })
            .finally(() => setRequesting(false));
    };

    useEffect(() => {
        if (code.length === 6) {
            handleValidate();
        }
    }, [code]);

    return (
        <Box
            sx={{
                textAlign: 'center',
                maxWidth: 600,
                margin: '0 auto',
            }}
        >
            {view === 'mobile' && (
                <Stack p={2} spacing={2}>
                    <Box>
                        <Typography variant="subtitle1" element="h2">
                            {t('loginFormTitle')}
                        </Typography>
                        <Typography variant="body2">
                            {t('loginFormBody')}
                        </Typography>
                    </Box>
                    <Stack spacing={1}>
                        <PhoneInput
                            value={phone}
                            onChange={(value) => setPhone(value)}
                            placeholder={t('loginPhonePlaceholder')}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleContinue}
                        >
                            {t('continue')}
                        </Button>
                    </Stack>
                </Stack>
            )}

            {view === 'verification' && (
                <>
                    <Stack p={2} spacing={2} alignItems="center">
                        <Box>
                            <Typography variant="subtitle1" element="h2">
                                {t('loginVerifyTitle')}
                            </Typography>
                            <Typography variant="body2">
                                {t('loginVerifyBody')}
                            </Typography>
                        </Box>
                        <Stack spacing={1}>
                            <PinInput
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                length={6}
                            />

                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleValidate}
                                disabled={loading}
                            >
                                {t('loginLoginButton')}
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            borderTop: '1px solid',
                            borderColor: 'neutral.300',
                            pt: 2,
                        }}
                    >
                        <Button color="neutral" onClick={requestOneTimeLink}>
                            {requesting ? (
                                <CircularProgress size={28} />
                            ) : (
                                <Stack>
                                    <Typography variant="body2">
                                        {t('loginDidnotReceiveCode')}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ textDecoration: 'underline' }}
                                    >
                                        {t('loginRequestOneTimeLink')}
                                    </Typography>
                                </Stack>
                            )}
                        </Button>
                    </Stack>
                </>
            )}
        </Box>
    );
}
