import { useCallback, useState, useEffect } from 'react';
import DataController from '../DataController';

export default function useFolders(currentTab, tabs, pageNum) {
    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [hasNextPage, setHasNextPage] = useState(false);

    const getFeedFolders = useCallback(async () => {
        const { success, message, data, meta } =
            await DataController.feed.getAllFolders(pageNum);

        if (success) {
            setFolders((prev) => [...prev, ...data]);
        } else {
            setError(message);
        }
        console.log('[DataController] Feed Folders ', data);
        setIsLoading(false);
        setHasNextPage(pageNum < meta.total_pages);
    }, [pageNum]);

    useEffect(() => {
        if (currentTab === tabs.FOLDERS) {
            setIsLoading(true);
            getFeedFolders();
        }
    }, [currentTab]);

    return {
        isLoading,
        folders,
        error,
        hasNextPage,
    };
}
