const actions = {
    SET_USER: 'SET_USER',
    DELETE_USER: 'DELETE_USER',
    SET_LANG: 'SET_LANG',
    SET_POST: 'SET_POST',
    SET_TAB: 'SET_TAB',
    SET_TAGS_GRID_CONTEXT: 'SET_TAGS_GRID_CONTEXT',

    ADD_POST_MEDIA: 'ADD_POST_MEDIA',
    EDIT_POST_MEDIA: 'EDIT_POST_MEDIA',
    REMOVE_POST_MEDIA: 'REMOVE_POST_MEDIA',
    ADD_POST_DOCUMENT: 'ADD_POST_DOCUMENT',
    REMOVE_POST_DOCUMENT: 'REMOVE_POST_DOCUMENT',
    ADD_POST_BOOKMARK: 'ADD_POST_BOOKMARK',
    REMOVE_POST_BOOKMARK: 'REMOVE_POST_BOOKMARK',

    SET_FOLDERS: 'SET_FOLDERS',
    ADD_NEW_FOLDER: 'ADD_NEW_FOLDER',

    SET_TEAM: 'SET_TEAM',

    SET_PRODUCTS: 'SET_PRODUCTS',

    SET_CART: 'SET_CART',
    CLEAR_CART: 'CLEAR_CART',

    SET_ORDERS: 'SET_ORDERS',

    SET_TAGS_SCROLL_OFFSET: 'SET_TAGS_SCROLL_OFFSET',

    ADD_ADDRESS: 'ADD_ADDRESS',
    UPDATE_DEFAULT_ADDRESS: 'UPDATE_DEFAULT_ADDRESS',
    DELETE_ADDRESS: 'DELETE_ADDRESS',
    EDIT_ADDRESS: 'EDIT_ADDRESS',

    ADD_COMPANY: 'ADD_COMPANY',
    DELETE_COMPANY: 'DELETE_COMPANY',
    EDIT_COMPANY: 'EDIT_COMPANY',

    UPDATE_DEFAULT_PAYMENTMETHOD: 'UPDATE_DEFAULT_PAYMENTMETHOD',
    DELETE_PAYMENTMETHOD: 'DELETE_PAYMENTMETHOD',
    ADD_PAYMENTMETHOD: 'ADD_PAYMENTMETHOD',
};

export default actions;
