import { Divider, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../../store/store';
import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListaAccordion from '../shared/ListaAccordion';

export default function TagDetails({    
    expandedInitial = false,
}) {
    const { t } = useTranslation();
    const { state } = useAppState();
    const tag = state.post.data?.tag ?? {};
    
    const [expanded, setExpanded] = useState(expandedInitial);

    const data = [
        { label: t('tagDetailFormat'), value: tag.sku?.size },
        { label: t('tagDetailColorway'), value: tag.sku?.colorway },
        {
            label: t('tagDetailDimensions'),
            value: tag.sku?.dimensions_formatted,
        },
        { label: t('tagDetailHash'), value: tag.hash },
        { label: t('tagDetailSKU'), value: tag.sku?.sku },
    ];
    
    // { label: t('tagDetailTagId'), value: tag.uuid }, 

    return (
        <Stack spacing={2}>
            
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="body2" color="neutral">Created</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" color="neutral">Scans</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" color="neutral">Last Scan</Typography>
                    </TableCell>                
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="caption">{tag.created_at_formatted}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">{tag.total_scans}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption">{tag.lastscan_at_formatted}</Typography>
                    </TableCell>                
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            
            <ListaAccordion
                title="Details"
                expanded={expanded}
                scrollToHeaderOnClose={false}
                onToggle={() => setExpanded(!expanded)}
                contentExpanded={
                    <Stack spacing={2} pt={2}>
                        {data.map((row, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography variant="body2" color="neutral">
                                    {row.label}
                                </Typography>
                                <Typography variant="caption">{row.value}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                }
            />
            
        </Stack>
    );
}
