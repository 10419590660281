import React, { useEffect, useCallback, useState } from 'react';
import {
    CircularProgress,
    Box,
    Stack,
    Button,
    Typography,
} from '@mui/material';
import useAppData from '../../store/useAppData';
import PageWrapDefault from '../layout/PageWrapDefault';
import ColorOptionsWidget from '../elements/shop/ColorOptionsWidget';
import SizeOptionsWidget from '../elements/shop/SizeOptionsWidget';
import RollOptionsWidget from '../elements/shop/RollOptionsWidget';
import SelectedProductName from '../elements/shop/SelectedProductName';
import { ReactComponent as RatingStarIcon } from '../../assets/icons/RatingStarIcon.svg';
import sharedStyles from '../../theme/sharedStyles';
import ListaNumberInput from '../elements/shared/ListaNumberInput';
import { debounce } from '../../lib/functions';
import DataController from '../../lib/DataController';
import { useSnackbar } from 'notistack';
import ProductDetails from '../elements/shop/ProductDetails';
import DynamicTag from '../elements/shop/DynamicTag';
import CartFloatingButton from '../elements/shop/CartFloatingButton';
import { useCart } from '../../contexts/CartContext';
import useWindowSize from '../../lib/hooks/useWindowSize';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';

export default function ShopPage() {
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppData({
        queries: ['getProducts'],
    });
    const { addToCart } = useCart();
    const loaded = state.products.loaded;
    const products = loaded ? state.products.data : null;

    const [color, setColor] = useState();
    const [size, setSize] = useState();
    const [roll, setRoll] = useState();
    const [qty, setQty] = useState(1);
    const [price, setPrice] = useState(false);

    const [windowWidth] = useWindowSize();

    useEffect(() => {
        if (state.products.loaded) {
            setColor(products.options.colors[0]);
            setSize(products.options.sizes[0]);
            setRoll(products.options.rolls[0]);
        }
    }, [state.products.loaded]);

    const getPrice = ({ size, color, roll, qty }) => {
        DataController.product
            .getPrice({ color, size, roll, qty })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    setPrice(data.unit_price);
                } else {
                    console.error('[getPrice] getting price failed');
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const getPriceDebounced = useCallback(debounce(getPrice, 500), []);

    useEffect(() => {
        if (!color || !size || !roll || !qty) return;
        getPriceDebounced({
            size: size.value,
            color: color.value,
            roll: roll.value,
            qty,
        });
    }, [size, color, roll, qty]);

    return (
        <PageWrapDefault>
            {!loaded ? (
                <CenteredCircularProgress />
            ) : (
                <Box>
                    <Stack spacing={3} px={2} pb={10}>
                        <Stack direction="row" pt={2}>
                            <Stack
                                justifyContent="space-between"
                                sx={{
                                    width: 'calc(100% - 130px)',
                                }}
                            >
                                <DynamicTag color={color} size={size} />

                                <Box>
                                    <SelectedProductName
                                        color={color}
                                        size={size}
                                    />
                                    <Stack
                                        mt={1}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        px={2}
                                        sx={{
                                            gap: 1,
                                            backgroundColor: 'neutral.200',
                                            maxWidth: 180,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box sx={{ fontSize: 11 }}>
                                            {products.total_ratings}
                                        </Box>
                                        <Box
                                            sx={{
                                                color: 'primary.main',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <RatingStarIcon />
                                        </Box>
                                        <Button>
                                            <Box
                                                sx={{
                                                    color: 'secondary.600',
                                                    textTransform: 'uppercase',
                                                    fontSize: 11,
                                                }}
                                            >
                                                {products.total_reviews} Reviews
                                            </Box>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                            <Stack spacing={5} sx={{ width: 130 }}>
                                {color && (
                                    <ColorOptionsWidget
                                        onChange={(color) => setColor(color)}
                                        colors={products.options.colors}
                                        selected={color}
                                    />
                                )}
                                {size && (
                                    <SizeOptionsWidget
                                        onChange={(size) => setSize(size)}
                                        sizes={products.options.sizes}
                                        selected={size}
                                    />
                                )}
                                {roll && (
                                    <RollOptionsWidget
                                        onChange={(roll) => setRoll(roll)}
                                        rolls={products.options.rolls}
                                        selected={roll}
                                    />
                                )}
                            </Stack>
                        </Stack>

                        {products && size && (
                            <ProductDetails
                                details={products}
                                selectedSize={size}
                            />
                        )}
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        pt={1}
                        pb={3.5}
                        px={2}
                        sx={{
                            position: 'fixed',
                            width: '100%',
                            left: 0,
                            bottom: 0,
                            ...sharedStyles.getFixedContainerStyles(windowWidth),
                            ...sharedStyles.glassmorphism,
                        }}
                    >
                        <Box>
                            {price && (
                                <Stack direction="row" alignItems="baseline">
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 700,
                                            color: 'neutral.500',
                                            alignSelf: 'flex-start',
                                            position: 'relative',
                                            top: -4,
                                        }}
                                    >
                                        $
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 28,
                                            fontWeight: 700,
                                            color: 'neutral.700',
                                        }}
                                    >
                                        {price}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 700,
                                            color: 'neutral.500',
                                        }}
                                    >
                                        /roll
                                    </Typography>
                                </Stack>
                            )}
                        </Box>
                        <Stack direction="row" spacing={1}>
                            <ListaNumberInput
                                value={qty}
                                onChange={(val) => setQty(val)}
                                min={0}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    addToCart({ size, color, roll, qty })
                                }
                            >
                                Add to cart
                            </Button>
                        </Stack>
                    </Stack>
                    <CartFloatingButton />
                </Box>
            )}
        </PageWrapDefault>
    );
}
