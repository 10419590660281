import actions from './actions.js';
import PersistentStorage from '../lib/PersistentStorage.js';

const reducer = (state, action) => {
    switch (action.type) {
        /**
         * Set user data
         */
        case actions.SET_USER:
            let user = action.payload;
            PersistentStorage.set('user', { ...state.user, ...user });
            return { ...state, user: { ...state.user, ...user } };

        case actions.DELETE_USER:
            PersistentStorage.set('user', {});
            return { ...state, user: {} };

        case actions.SET_LANG:
            return { ...state, lang: action.payload };

        case actions.SET_POST:
            return (() => {
                let post = action.payload;

                let recent = PersistentStorage.get('recentPosts');

                if (!recent) {
                    recent = {};
                }

                if (!recent[post.hash]) {
                    recent[post.hash] = {};
                }

                const recentEntry = recent[post.hash];

                if (post.lastPin) {
                    recentEntry.pin = post.lastPin;
                }
                recentEntry.timestamp = Date.now();

                PersistentStorage.set('recentPosts', recent);

                console.log('[SET_POST]', {
                    ...state,
                    post: { data: post, loaded: true },
                });

                return { ...state, post: { data: post, loaded: true } };
            })();

        case actions.SET_TAB:
            const newTab = action.payload;
            return {
                ...state,
                currentTab: newTab,
            };
        case actions.ADD_POST_MEDIA:
            let newMedia = action.payload;
            return {
                ...state,
                post: {
                    ...state.post,
                    data: {
                        ...state.post.data,
                        media: [newMedia, ...state.post.data.media],
                    },
                },
            };

        case actions.EDIT_POST_MEDIA:
            let editedMedia = action.payload;
            let mediaArray = [...state.post.data.media];
            let editedMediaIndex = mediaArray.findIndex(
                (el) => el.id === editedMedia.id
            );
            mediaArray[editedMediaIndex] = editedMedia;

            return {
                ...state,
                post: {
                    ...state.post,
                    data: {
                        ...state.post.data,
                        media: mediaArray,
                    },
                },
            };

        case actions.REMOVE_POST_MEDIA:
            return (() => {
                let mediaId = action.payload;

                let mediaArray = [...state.post.data.media];
                const index = mediaArray.findIndex((el) => el.id === mediaId);
                mediaArray.splice(index, 1);

                return {
                    ...state,
                    post: {
                        ...state.post,
                        data: {
                            ...state.post.data,
                            media: mediaArray,
                        },
                    },
                };
            })();

        case actions.ADD_POST_DOCUMENT:
            let newDoc = action.payload;
            return {
                ...state,
                post: {
                    ...state.post,

                    data: {
                        ...state.post.data,
                        documents: [newDoc, ...state.post.data.documents],
                    },
                },
            };

        case actions.REMOVE_POST_DOCUMENT:
            let docId = action.payload;

            let documents = [...state.post.data.documents];
            const index = documents.findIndex((el) => el.id === docId);
            documents.splice(index, 1);

            return {
                ...state,
                post: {
                    ...state.post,

                    data: {
                        ...state.post.data,
                        documents: [...documents],
                    },
                },
            };

        case actions.ADD_POST_BOOKMARK:
            return (() => {
                let linkData = action.payload;

                return {
                    ...state,
                    post: {
                        ...state.post,

                        data: {
                            ...state.post.data,
                            links: [linkData, ...state.post.data.links],
                        },
                    },
                };
            })();

        case actions.REMOVE_POST_BOOKMARK:
            return (() => {
                let linkId = action.payload;

                let links = [...state.post.data.links];
                const index = links.findIndex((el) => el.id === linkId);
                links.splice(index, 1);

                return {
                    ...state,
                    post: {
                        ...state.post,

                        data: {
                            ...state.post.data,
                            links: [...links],
                        },
                    },
                };
            })();

        case actions.SET_TEAM:
            return (() => {
                let data = action.payload;
                return { ...state, team: { data, loaded: true } };
            })();

        case actions.SET_FOLDERS:
            let data = action.payload;
            return { ...state, folders: { data, loaded: true } };

        case actions.ADD_NEW_FOLDER:
            let newFolder = action.payload;
            return {
                ...state,
                folders: {
                    data: [newFolder, ...state.folders.data],
                    loaded: state.folders.loaded,
                },
            };

        case actions.SET_PRODUCTS:
            return (() => {
                let data = action.payload;
                return { ...state, products: { data, loaded: true } };
            })();

        case actions.SET_CART:
            return (() => {
                let data = action.payload;
                return { ...state, cart: { data, loaded: true } };
            })();

        case actions.CLEAR_CART:
            return (() => {
                let data = {};
                return { ...state, cart: { data, loaded: true } };
            })();

        case actions.SET_ORDERS:
            return (() => {
                let data = action.payload;
                return { ...state, orders: { data, loaded: true } };
            })();

        case actions.SET_TAGS_SCROLL_OFFSET:
            return (() => {
                let data = action.payload;
                return {
                    ...state,
                    scrollOffset: { ...state.scrollOffset, tags: data },
                };
            })();

        case actions.ADD_ADDRESS:
            return (() => {
                let newAddress = action.payload;
                let addresses = [...state.user.addresses];
                addresses.push(newAddress);

                return {
                    ...state,
                    user: { ...state.user, addresses },
                };
            })();

        case actions.UPDATE_DEFAULT_ADDRESS:
            return (() => {
                let data = action.payload;
                let addresses = [...state.user.addresses];

                addresses.forEach((address) => {
                    address.is_default = 0;
                });

                let index = addresses.findIndex(
                    (address) => address.id === data.id
                );

                addresses[index] = data;

                return {
                    ...state,
                    user: { ...state.user, addresses: addresses },
                };
            })();

        case actions.DELETE_ADDRESS:
            return (() => {
                let id = action.payload;
                let addresses = [...state.user.addresses];

                let index = addresses.findIndex((address) => address.id === id);
                addresses.splice(index, 1);

                return {
                    ...state,
                    user: { ...state.user, addresses: addresses },
                };
            })();

        case actions.EDIT_ADDRESS:
            return (() => {
                let data = action.payload;
                let addresses = [...state.user.addresses];

                let index = addresses.findIndex(
                    (address) => address.id === data.id
                );

                addresses[index] = data;

                return {
                    ...state,
                    user: { ...state.user, addresses: addresses },
                };
            })();

        case actions.ADD_COMPANY:
            return (() => {
                let newCompany = action.payload;
                let companies = [...state.user.companies];
                companies.push(newCompany);

                return {
                    ...state,
                    user: { ...state.user, companies },
                };
            })();

        case actions.DELETE_COMPANY:
            return (() => {
                let id = action.payload;
                let companies = [...state.user.companies];

                let index = companies.findIndex((company) => company.id === id);
                companies.splice(index, 1);

                return {
                    ...state,
                    user: { ...state.user, companies: companies },
                };
            })();

        case actions.EDIT_COMPANY:
            return (() => {
                let data = action.payload;
                let companies = [...state.user.companies];

                let index = companies.findIndex(
                    (company) => company.id === data.id
                );

                companies[index] = data;

                return {
                    ...state,
                    user: { ...state.user, companies: companies },
                };
            })();

        case actions.ADD_PAYMENTMETHOD:
            return (() => {
                let newPM = action.payload;
                let payment_methods = [...state.user.payment_methods];
                payment_methods.push(newPM);

                return {
                    ...state,
                    user: { ...state.user, payment_methods },
                };
            })();

        case actions.UPDATE_DEFAULT_PAYMENTMETHOD:
            return (() => {
                let data = action.payload;
                let payment_methods = [...state.user.payment_methods];

                payment_methods.forEach((paymentmethod) => {
                    paymentmethod.is_default = 0;
                });

                let index = payment_methods.findIndex(
                    (paymentmethod) => paymentmethod.id === data.id
                );

                payment_methods[index] = data;

                return {
                    ...state,
                    user: { ...state.user, payment_methods },
                };
            })();

        case actions.DELETE_PAYMENTMETHOD:
            return (() => {
                let id = action.payload;
                let payment_methods = [...state.user.payment_methods];

                let index = payment_methods.findIndex((pm) => pm.id === id);
                payment_methods.splice(index, 1);

                return {
                    ...state,
                    user: { ...state.user, payment_methods: payment_methods },
                };
            })();

        case actions.SET_TAGS_GRID_CONTEXT:
            return (() => {
                return {
                    ...state,
                    tagsGridContext: action.payload,
                };
            })();
    }
};

export default reducer;
