import { IconButton } from '@mui/material';
import palette from '../../../theme/palette.js';

const HamburgerButton = ({ active, ...props }) => {
    return <IconButton
        sx={{
            position: 'relative',
            width: 36,
            height: 36,
            display: 'inline-flex',
            flexDirection: 'column',
            ml: 0,
            mr: 'auto',

            '& i': {
                display: 'block',
                width: 23,
                flex: '0 0 2px',
                backgroundColor: palette.secondary.main,
                transition: `transform 250ms cubic-bezier(0.4, 0, 0.2, 1), 
                    opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)`,

                '& + i': {
                    mt: '6px'
                },

                '&:nth-of-type(1)': {
                    transform: active ? 'rotate(45deg) translate(8px, 6px)' : ''
                },

                '&:nth-of-type(2)': {
                    opacity: active ? 0 : 1
                },

                '&:nth-of-type(3)': {
                    transform: active ? 'rotate(-45deg) translate(5px, -4px)' : ''
                }
            }
        }}
        {...props}>
        <i/>
        <i/>
        <i/>
    </IconButton>;
};

export default HamburgerButton;