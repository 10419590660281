import MuiCssBaseline from './components/MuiCssBaseline';
import MuiOutlinedInput from './components/MuiOutlinedInput';
import MuiPaper from './components/MuiPaper';
import MuiButton from './components/MuiButton';
import MuiIconButton from './components/MuiIconButton';
import MuiLinearProgress from './components/MuiLinearProgress';
import MuiDialog from './components/MuiDialog';
import MuiCheckbox from './components/MuiCheckbox';
import MuiSwitch from './components/MuiSwitch';
import MuiAvatar from './components/MuiAvatar';
import MuiToggleButton from './components/MuiToggleButton';

const components = {
    MuiCssBaseline,
    MuiOutlinedInput,
    MuiPaper,
    MuiButton,
    MuiIconButton,
    MuiLinearProgress,
    MuiDialog,
    MuiCheckbox,
    MuiSwitch,
    MuiAvatar,
    MuiToggleButton,
};

export default components;
