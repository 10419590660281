import palette from './palette';

const sharedHeadingStyles = {
    fontFamily: ['"BT Brik"', 'sans-serif'].join(','),
    lineHeight: '80%',
};

const subHeadingStyle = {
    fontFamily: [
        // Safari's having problems rendering this font
        // '"BT Brik XL Condensed Oblique"',
        
        // added this as a fallback
        '"BT Brik XXL Oblique"',
        
        'sans-serif',
    ].join(','),
    lineHeight: '80%',
};

const headingStyle = {
    fontFamily: ['BT Brik XXL Oblique', 'sans-serif'].join(','),
    lineHeight: '80%',
};

const sansSerifFont = {
    fontFamily: ['"SF Pro"', 'sans-serif'].join(','),
};

const typography = {
    fontFamily: [
        '"SF Pro"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),

    h1: {
        ...sharedHeadingStyles,
        fontSize: 44,
    },
    h2: {
        ...subHeadingStyle,
        fontSize: 32,
    },
    h3: {
        ...headingStyle,
        fontSize: 24,
    },
    h4: {
        ...subHeadingStyle,
        fontSize: 18,
        lineHeight: '110%',
    },
    h5: {
        ...subHeadingStyle,
        fontSize: 12,
        lineHeight: '110%',
    },
    h6: {
        ...subHeadingStyle,
        fontSize: 9,
        lineHeight: '122%',
    },

    subtitle1: {
        ...sansSerifFont,
        fontSize: 15,
        lineHeight: '22px',
        fontWeight: 600,
    },

    body1: {
        ...sansSerifFont,
        fontSize: 17,
        lineHeight: '24px',
    },
    
    postTitle: {
        ...sansSerifFont,
        fontSize: 32,
        lineHeight: '32px',
        fontWeight: 600,
        textTransform: 'none',
        letterSpacing: '-0.5px',
    },

    bodyMid: {
        ...sansSerifFont,
        fontSize: 15,
        lineHeight: '22px',
    },

    body2: {
        ...sansSerifFont,
        fontSize: 14,
        lineHeight: '18px',
        color: palette.neutral[700],
    },

    caption: {
        ...sansSerifFont,
        fontSize: 14,
        lineHeight: '18px',
        fontWeight: 600,
    },
    
    shopProductName: {
        ...subHeadingStyle,
        fontSize: 28,
    },

    overline: {
        ...sharedHeadingStyles,
        fontSize: 12,
        lineHeight: '110%',

        '& small': {
            fontSize: 9,
        },
    },

    button: {
        ...sansSerifFont,
        fontSize: 17,
        lineHeight: '24px',
        fontWeight: 600,
        textTransform: 'none',
    },
};

export default typography;
