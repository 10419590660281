import { Box, CircularProgress } from "@mui/material";

export default function CenteredCircularProgress({ sx = {} }) {
    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            ...sx,
        }}>
            <CircularProgress />
        </Box>
    );
}