import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    CircularProgress,
    TextField,
    Stack,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Divider,
    Typography,
} from '@mui/material';
import { ReactComponent as CaretRightIcon } from '../../../assets/icons/CaretRightIcon.svg';
import ListaDialog from '../shared/ListaDialog';
import { useAppState } from '../../../store/store';
import useAppData from '../../../store/useAppData';
import DataController from '../../../lib/DataController';
import { useSnackbar } from 'notistack';
import ListaCheckbox from '../shared/ListaCheckbox';
import ListaTextButton from '../shared/ListaTextButton';
import { ReactComponent as AddIcon } from '../../../assets/icons/AddIcon.svg';
import { ReactComponent as TeamIcon } from '../../../assets/icons/TeamIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/TrashIcon.svg';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ListaButton from '../shared/ListaButton';
import PhoneInput from '../shared/PhoneInput';

export default function TeamPicker() {
    const { t } = useTranslation();
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [displayAddNewMemberForm, setDisplayAddNewMemberForm] =
        useState(true);
    const [loading, setLoading] = useState(null); // null | folderId  -> to track which one is being set as folder in the dialog list

    const loaded = state.team.loaded;
    const team_id = state.team.data?.id ?? null;
    const teamSummary = state.team.data?.summary ?? '';
    const members = state.team.data?.members ?? [];
    const invites = state.team.data?.invites ?? [];
    // const team = [
    //     ...members.map((member) => ({ ...member, is_member: true })),
    //     ...invites.map((member) => ({
    //         ...member,
    //         is_invite: true,
    //         is_member: false,
    //     })),
    // ];

    const [nameValue, setNameValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');

    useAppData({
        queries: [['getTeam', { post_id: state.post.data.id }]],
    });

    useEffect(() => {
        if (!dialogOpen) {
            setDisplayAddNewMemberForm(false);
        }
    }, [dialogOpen]);

    const handleInviteAndAddAsEditor = () => {
        if (!phoneValue) return console.error('No phoneValue value.');
        if (!team_id) return console.error('No team_id value.');

        setLoading(true);
        DataController.team
            .invite({
                team_id,
                name: nameValue,
                phone: phoneValue,
                post_id: state.post.data.id,
            })
            .then(({ success, data, message }) => {
                if (success) {
                    setNameValue('');
                    setPhoneValue('');
                    setDisplayAddNewMemberForm(false);

                    setTimeout(() => {
                        dispatch({
                            type: actions.SET_TEAM,
                            payload: data,
                        });
                        enqueueSnackbar(message);
                    }, 400);
                } else {
                    enqueueSnackbar(message);
                }
            })
            .finally(() => setLoading(null));
    };

    const handleAddMemberAsEditor = (member) => {
        setLoading(member.phone);
        DataController.team
            .invite({
                team_id,
                uuid: member.uuid,
                post_id: state.post.data.id,
            })
            .then(({ success, data, message }) => {
                if (success) {
                    dispatch({
                        type: actions.SET_TEAM,
                        payload: data,
                    });
                    enqueueSnackbar(message);
                } else {
                    enqueueSnackbar(message);
                }
            })
            .finally(() => setLoading(null));
    };

    const handleUninvite = async (user) => {
        const isMember = user.is_member;

        const reqData = {
            team_id,
            phone: user.phone,
            post_id: state.post.data.id,
        };

        if (isMember) {
            reqData.uuid = user.uuid;
        }

        setLoading(user.phone);

        const { success, data, message, error } =
            await DataController.team.uninvite(reqData);

        if (success) {
            dispatch({
                type: actions.SET_TEAM,
                payload: data,
            });
            enqueueSnackbar(message);
        } else {
            enqueueSnackbar(message);
        }
        setLoading(null);
    };

    const handleMemberClick = (member) => {
        if (member.is_editor) {
            handleUninvite(member);
        } else {
            handleAddMemberAsEditor(member);
        }
    };

    const handleInviteClick = (invite) => {
        // if it's still invite, means it's not an editor, so you only can uninvite
        handleUninvite(invite);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                sx={{ justifyContent: 'space-between', pl: 1.5 }}
                onClick={() => setDialogOpen(true)}
            >
                <Stack direction="row" spacing={1.5} alignItems="center">
                    <TeamIcon
                        style={{
                            width: 26,
                            height: 26,
                        }}
                    />
                    <Typography>{teamSummary}</Typography>
                </Stack>

                <CaretRightIcon />
            </Button>

            <ListaDialog
                title={t('teamPickerDialogTitle')}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                {!loaded ? (
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box
                            p={2}
                            pt={0}
                            sx={{
                                overflow: 'auto',
                                maxHeight: 300,
                            }}
                        >
                            {!!members.length && (
                                <>
                                    <List
                                        sx={{
                                            width: '100%',
                                            px: 0,
                                        }}
                                    >
                                        <AnimatePresence>
                                            {members.map((user) => {
                                                return (
                                                    <ListItemUser
                                                        key={user.phone}
                                                        user={user}
                                                        loading={
                                                            user.phone ===
                                                            loading
                                                        }
                                                        onClick={() =>
                                                            handleMemberClick(
                                                                user
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                        </AnimatePresence>
                                    </List>
                                    <Divider />
                                </>
                            )}

                            {!!invites.length && (
                                <>
                                    <List
                                        sx={{
                                            width: '100%',
                                            px: 0,
                                        }}
                                    >
                                        <AnimatePresence>
                                            {invites.map((user) => {
                                                return (
                                                    <ListItemUser
                                                        type="invite"
                                                        key={user.phone}
                                                        user={user}
                                                        loading={
                                                            user.phone ===
                                                            loading
                                                        }
                                                        onClick={() =>
                                                            handleInviteClick(
                                                                user
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                        </AnimatePresence>
                                    </List>
                                    <Divider />
                                </>
                            )}

                            <Box pt={2} component={motion.div} layout>
                                <LayoutGroup>
                                    {displayAddNewMemberForm ||
                                    (members.length < 1 &&
                                        invites.length < 1) ? (
                                        <Stack
                                            spacing={2}
                                            component={motion.div}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            <TextField
                                                placeholder={t(
                                                    'teamPickerAddNewName'
                                                )}
                                                value={nameValue}
                                                onChange={(e) =>
                                                    setNameValue(e.target.value)
                                                }
                                            />
                                            <PhoneInput
                                                value={phoneValue}
                                                onChange={(value) =>
                                                    setPhoneValue(value)
                                                }
                                                placeholder={t(
                                                    'loginPhonePlaceholder'
                                                )}
                                            />
                                            <ListaButton
                                                variant="contained"
                                                fullWidth
                                                onClick={
                                                    handleInviteAndAddAsEditor
                                                }
                                                loading={loading !== null}
                                            >
                                                {t(
                                                    'teamPickerInviteAndAddAsEditor'
                                                )}
                                            </ListaButton>
                                        </Stack>
                                    ) : (
                                        <ListaTextButton
                                            large
                                            caption={t(
                                                'teamPickerAddNewMember'
                                            )}
                                            icon={<AddIcon />}
                                            onClick={() =>
                                                setDisplayAddNewMemberForm(true)
                                            }
                                            component={motion.button}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        />
                                    )}
                                </LayoutGroup>
                            </Box>
                        </Box>
                    </>
                )}
            </ListaDialog>
        </>
    );
}

function ListItemUser({ user, loading, onClick, type = 'member' }) {
    const labelId = `checkbox-list-label-${user.id}`;

    return (
        <ListItem
            disablePadding
            sx={{
                overflow: 'hidden',
            }}
            component={motion.li}
            initial={{
                height: 0,
                backgroundColor: 'success',
            }}
            animate={{
                height: 'auto',
                backgroundColor: 'white',
            }}
            exit={{
                height: 0,
            }}
        >
            <ListItemButton
                role={undefined}
                disabled={loading}
                onClick={onClick}
                sx={{
                    py: 0,
                    px: 0,
                    minHeight: 56,
                }}
            >
                <Stack
                    sx={{ flex: 1 }}
                    direction="row"
                    gap={1}
                    alignItems="baseline"
                >
                    {user.name && (
                        <Typography variant="body1" color="neutral.600">
                            {user.name}
                        </Typography>
                    )}

                    <Typography variant="body2" color="neutral.600">
                        {user.phone}
                    </Typography>
                    {type === 'invite' && (
                        <Stack
                            sx={{
                                backgroundColor: 'neutral.600',
                                borderRadius: 15,
                                padding: '0 8px',
                                height: 16,
                            }}
                        >
                            <Typography
                                variant="caption"
                                color="white.main"
                                sx={{
                                    fontSize: 11,
                                    lineHeight: '16px',
                                }}
                            >
                                INVITED
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <ListItemIcon
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                >
                    {type === 'member' && (
                        <ListaCheckbox
                            checked={user.is_editor}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            loading={loading}
                        />
                    )}
                    {type === 'invite' && (
                        <Box
                            sx={{
                                paddingRight: '6px',
                            }}
                        >
                            {loading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <TrashIcon width="24" height="24" />
                            )}
                        </Box>
                    )}
                </ListItemIcon>
            </ListItemButton>
        </ListItem>
    );
}
