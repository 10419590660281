import { Stack, Button, Typography, Dialog } from '@mui/material';
import Login from '../login/Login';
import React, { useState } from 'react';
import { useAppState } from '../../../store/store';
import { useTranslation } from 'react-i18next';

export default function LoginToEdit({}) {
    const { state } = useAppState();
    const [isLoginDialogShown, setIsLoginDialogShown] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Stack
                py={1}
                px={4}
                sx={{
                    width: '100%',
                    borderTop: `1px solid`,
                    borderColor: 'neutral.300',
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="body2" color="neutral">
                    is this your tag?
                </Typography>
                <Button
                    variant="text"
                    onClick={() => setIsLoginDialogShown(true)}
                >
                    <Typography variant="body2" color="primary.main">
                        Login to Edit
                    </Typography>
                </Button>
            </Stack>

            <Dialog
                open={!state?.user?.token && isLoginDialogShown}
                onClose={() => setIsLoginDialogShown(false)}
            >
                <Login />
            </Dialog>
        </>
    );
}