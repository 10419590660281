import { Stack, Typography } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import Frame from './Frame';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/ArrowDownIcon.svg';
import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { scrollIntoView } from '../../../lib/functions';

export default function ListaAccordion({
    expanded = false,
    scrollToHeaderOnClose = true,
    onToggle,
    title,
    contentExpanded,
    contentClosed,
}) {
    const titleLineRef = useRef();
    const [loaded, setLoaded] = useState(false);

    const toggle = () => {
        onToggle();
    };

    // Scroll title into view on accordion close
    useEffect(() => {
        if ( scrollToHeaderOnClose ) {
          if (!expanded && loaded) {
            scrollIntoView(titleLineRef.current);
          }
        }
    }, [expanded, scrollToHeaderOnClose]);

    // skip autoscrilling to title line on first
    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <Frame>
            <Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    component="button"
                    onClick={toggle}
                    ref={titleLineRef}
                    sx={{ maxWidth: '100%' }}
                >
                    {typeof title === 'string' ? (
                        <Typography variant="subtitle1">{title}</Typography>
                    ) : (
                        title
                    )}
                    <ArrowDownIcon
                        style={{
                            transform: expanded ? 'rotate(180deg)' : '',
                            transition: 'transform 0.3s ease-in-out',
                            position: 'relative',
                            right: 7,
                            // minWidth: 32,
                            marginLeft: 16,
                        }}
                    />
                </Stack>

                <AnimatePresence>
                    {/* Accordion body */}
                    {expanded && (
                        <Box
                            transition={{ duration: 0.6 }}
                            key="expanded"
                            component={motion.div}
                            sx={{ overflow: 'hidden', width: '100%' }}
                            initial={{
                                opacity: 0,
                                height: 0,
                            }}
                            animate={{
                                opacity: 1,
                                height: 'auto',
                            }}
                            exit={{
                                opacity: 0,
                                height: 0,
                            }}
                        >
                            {contentExpanded}
                        </Box>
                    )}

                    {/* body when closed - ie. selected address, etc... */}
                    {!expanded && (
                        <Box
                            transition={{ duration: 0.6 }}
                            component={motion.div}
                            key="notexpanded"
                            sx={{ overflow: 'hidden', width: '100%' }}
                            initial={{
                                opacity: 0,
                                height: 0,
                            }}
                            animate={{
                                opacity: 1,
                                height: 'auto',
                            }}
                            exit={{
                                opacity: 0,
                                height: 0,
                            }}
                        >
                            {contentClosed}
                        </Box>
                    )}
                </AnimatePresence>
            </Stack>
        </Frame>
    );
}
