import React from 'react';
import { Dialog, Box, Divider } from '@mui/material';
import ListaDialogTitle from './ListaDialogTitle';
import ListaDialogFooter from './ListaDialogFooter';
import { motion } from 'framer-motion';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import sharedStyles from '../../../theme/sharedStyles';

export default function ListaDialog({
    open,
    onClose,
    children,
    title,
    actions,
    fullScreen = false,
    ...other
}) {
    const [windowWidth] = useWindowSize();

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullScreen={fullScreen}
            sx={{
                ...sharedStyles.getFixedContainerStyles(windowWidth),
            }}
            {...other}
        >
            <ListaDialogTitle onClose={onClose} fullScreen={fullScreen}>
                {title}
            </ListaDialogTitle>
            <Box sx={{ mt: 7, mb: actions ? 10 : 0 }}>
                {/* {!fullScreen && <Divider sx={{ mx: 2 }} />} */}
                {children}
            </Box>
            {actions && (
                <ListaDialogFooter fullScreen={fullScreen}>
                    {actions}
                </ListaDialogFooter>
            )}
        </Dialog>
    );
}
