import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export default function SelectedProductName({ color, size }) {
    if (!size || !color) return;

    return (
        <Box>
            <Typography variant="shopProductName" sx={{ lineHeight: '0.7' }}>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    W
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    A
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    Y
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    F
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    I
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder'].includes(size.title.toLowerCase())}
                >
                    N
                </AnimatedLetter>

                <AnimatedLetter
                    isVisible={['wayfinder', 'decor'].includes(
                        size.title.toLowerCase()
                    )}
                >
                    D
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['wayfinder', 'decor'].includes(
                        size.title.toLowerCase()
                    )}
                >
                    E
                </AnimatedLetter>

                <AnimatedLetter
                    isVisible={['decor'].includes(size.title.toLowerCase())}
                >
                    C
                </AnimatedLetter>
                <AnimatedLetter
                    isVisible={['decor'].includes(size.title.toLowerCase())}
                >
                    O
                </AnimatedLetter>

                <AnimatedLetter
                    isVisible={['wayfinder', 'decor'].includes(
                        size.title.toLowerCase()
                    )}
                >
                    R
                </AnimatedLetter>
            </Typography>
            <Stack direction="row" alignItems="baseline" sx={{ mt: -0.5 }}>
                <Typography variant="h5" color="neutral.500">
                    in
                </Typography>
                &nbsp;
                <Typography
                    variant="h4"
                    sx={{
                        color: color.rgb,
                        textShadow:
                            color.title === 'Pearl White'
                                ? '0px 0px 2px rgba(0, 0, 0, 0.4)'
                                : 'none',
                    }}
                >
                    {color.title}
                </Typography>
            </Stack>
        </Box>
    );
}

function AnimatedLetter({ children, isVisible }) {
    return (
        <motion.span
            initial={{ width: 0 }}
            animate={{
                // transform: isVisible ? 'scaleX(1)' : 'scaleX(0)',
                width: isVisible ? 'auto' : 0,
                opacity: isVisible ? 1 : 0,
            }}
            exit={{ width: 0 }}
            style={{
                display: 'inline-block',
                // overflowX: 'hidden',
            }}
            layout
        >
            {children}
        </motion.span>
    );
}
