import { Box, Fab } from '@mui/material';
import { motion } from 'framer-motion';
import useLottieAnimation from '../../../lib/hooks/useLottieAnimation.js';
import { useEffect, useRef, useState } from 'react';
import { duration } from '../../../theme/motionConfig.js';
import useWindowSize from '../../../lib/hooks/useWindowSize.js';

export default function AnimatedFab({
    animationData,
    fabColor = 'info', 
    repeatAnimation,
    repeatAnimationData,
    sx = {},
    children
}) {
    const ref = useRef(null);
    const [currentAnimationData, setCurrentAnimationData] = useState(animationData);
    const [windowWidth] = useWindowSize();
    const {
        setAnimationShouldPlay,
        lottieAnimationGoToAndPlay,
        lottieAnimation
    } = useLottieAnimation(ref, currentAnimationData);

    useEffect(() => {
        if (lottieAnimation && lottieAnimation.isPaused) {
            setCurrentAnimationData(repeatAnimationData);
            lottieAnimationGoToAndPlay(0);
        }
    }, [repeatAnimation]);

    return (
        <Box
            component={motion.div}
            whileHover={{ scale: 1.2 }}
            whileFocus={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            initial={{ y: '140%' }}
            animate={{
                y: '0%',
                transition: {
                    duration: duration.long,
                },
            }}
            onAnimationComplete={() => setAnimationShouldPlay(true)}
            sx={{
                position: 'fixed',
                bottom: 16,
                left: '50%',
                maxWidth: windowWidth,
                marginLeft: `${(windowWidth/3.1)}px`,
                ...sx,
            }}
        >
            <Fab color={fabColor}>
                <Box
                    ref={ref}
                    sx={{
                        width: '64.3%',
                        height: '64.3%',
                    }}
                />
                {children}
            </Fab>
        </Box>
    );
}
