import React, { useState } from 'react';
import ListaTextField from './ListaTextField';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { Stack, Box, Button } from '@mui/material';
import StripeElementWrapper from './StripeElementWrapper';
import ListaButton from './ListaButton';

export default function StripeForm({ onGetTokenSuccess, onCancel }) {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;

        setLoading(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        const { error, token } = await stripe.createToken(cardNumberElement);

        if (error) {
            console.log('[stripe.createToken error]', error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        console.log('[stripe.createToken success]', token);

        await onGetTokenSuccess(token.id);

        setLoading(false);
    };

    return (
        <Stack spacing={2}>
            <StripeElementWrapper
                Component={CardNumberElement}
                label="Card number"
            />

            {/* <ListaTextField label="Cardholder name" /> */}

            <Stack direction="row" spacing={1}>
                <Box flex={1}>
                    <StripeElementWrapper
                        Component={CardExpiryElement}
                        label="Exp. (MM.YY)"
                    />
                </Box>
                <Box flex={1}>
                    <StripeElementWrapper
                        Component={CardCvcElement}
                        label="CVV"
                    />
                </Box>
            </Stack>

            <Stack direction="row" spacing={1}>
                <Box sx={{ flex: 1, width: '100%' }}>
                    <Button onClick={onCancel} color="neutral" fullWidth>
                        Cancel
                    </Button>
                </Box>

                <Box sx={{ flex: 1, width: '100%' }}>
                    <ListaButton
                        variant="contained"
                        onClick={handleSubmit}
                        loading={loading}
                        fullWidth
                    >
                        Save
                    </ListaButton>
                </Box>
            </Stack>
        </Stack>
    );
}
