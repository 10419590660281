import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import ImageStack from '../shared/ImageStack';
import Thumbnail from '../shared/Thumbnail';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lightbox from '../shared/Lightbox';

export default function ProductGallery({ images }) {
    const media = images.map((item, index) => ({
        url: item,
        is_image: 1,
    }));

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [startAt, setStartAt] = useState(0);

    const handleOpenLightbox = (index) => {
        setStartAt(index);
        setLightboxOpen(true);
    };

    return (
        <Box>
            <ImageStack>
                {media.map((item, index) => (
                    <Button
                        key={index}
                        sx={{ flexShrink: 0, p: 0, color: 'inherit' }}
                        onClick={() => handleOpenLightbox(index)} // needs index + 1 cause lightbox will start with cover, and concat other
                    >
                        <Thumbnail src={item.url} caption={item.caption} />
                    </Button>
                ))}
            </ImageStack>

            <Lightbox
                media={media}
                open={lightboxOpen}
                onClose={() => setLightboxOpen(false)}
                startAt={startAt}
            />
        </Box>
    );
}
