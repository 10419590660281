import { Grid, Box, darken } from '@mui/material';
import TagDetails from "./TagDetails";
import TagIcons from './TagIcons';
import { Link } from 'react-router-dom';
import { forwardRef } from 'react';

const Tag = forwardRef(({ data }, ref) => {
  return (
      <Grid
          item
          xs={6}
      >
          <Box
              ref={ref}
              component={Link}
              to={`/post/${data?.hash}`}
              sx={{
                  position: 'relative',
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.72) 100%),
                  url(${data?.cover_thumbnail_url})
                  `,
                  backgroundColor: darken(data?.css_color, 0.1),
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-reapeat',
                  backgroundPosition: 'center',
                  height: 166,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textDecoration: 'none',
                  py: 1,
                  pr: 1,
              }}
          >
              <TagIcons
                hasImage={data?.has_image}
                hasPdf={data?.has_pdf}
                hasVideo={data?.has_video}
              />
              <TagDetails 
                  backgroundColor={data?.css_color}
                  title={data?.title}
                  subtitle={data?.subtitle}
              />
          </Box>
      </Grid>
  );
});
Tag.displayName = "Tag";

export default Tag;
