import React from 'react';
import { DialogTitle, Typography, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { ReactComponent as CloseCircledIcon } from '../../../assets/icons/CloseCircledIcon.svg';
import sharedStyles from '../../../theme/sharedStyles';

export default function ListaDialogTitle({
    onClose,
    children,
    fullScreen,
    ...other
}) {
    return (
        <DialogTitle
            sx={{
                m: 0,
                p: 2,
                borderBottom: fullScreen ? '1px solid' : 'none',
                borderColor: 'neutral.300',
                position: fullScreen ? 'fixed' : 'absolute',
                top: 0,
                width: '100%',
                zIndex: 10,
                ...sharedStyles.container,
                ...sharedStyles.glassmorphism,
            }}
            {...other}
        >
            <Typography variant={fullScreen ? 'h3' : 'h4'} component="span">
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 8,
                        color: 'secondary.main',
                        width: 36,
                        height: 36,
                    }}
                >
                    {fullScreen ? (
                        <CloseIcon width="32" height="32" />
                    ) : (
                        <CloseCircledIcon width="32" height="32" />
                    )}
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
