import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useAppState } from '../store/store';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '../assets/translations/en.json';
import esTranslations from '../assets/translations/es.json';
import ptTranslations from '../assets/translations/pt.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: enTranslations,
            },

            es: {
                translation: esTranslations,
            },

            pt: {
                translation: ptTranslations,
            },
        },
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

const TranslationContext = React.createContext();

function TranslationContextProvider({ children }) {
    const { state } = useAppState();

    useEffect(() => {
        i18n.changeLanguage(state.lang);
    }, [state.lang]);

    return (
        <TranslationContext.Provider value={{}}>
            {children}
        </TranslationContext.Provider>
    );
}

const useTranslationContext = () => {
    return useContext(TranslationContext);
};

export { TranslationContextProvider, useTranslationContext };
