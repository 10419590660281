import React from 'react';
import { Box, Button, OutlinedInput, Stack } from '@mui/material';
import { connectSearchBox } from 'react-instantsearch-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import LottieAnimation from '../shared/LottieAnimation';
import SearchAnimation from '../../../assets/lottie/search-outline.json';

export default function Search({ onFocus, onCancel, hitsShowing }) {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={1}>
            <Box component={motion.div} sx={{ flex: 1 }} layout>
                <CustomSearchBox onFocus={onFocus} />
            </Box>

            {hitsShowing && (
                <Button
                    component={motion.div}
                    layout
                    onClick={onCancel}
                    initial={{
                        width: 0,
                        opacity: 0,
                    }}
                    animate={{
                        width: 'auto',
                        opacity: 1,
                    }}
                    exit={{ width: 0, opacity: 0 }}
                >
                    {t('cancel')}
                </Button>
            )}
        </Stack>
    );
}

const SearchBox = ({ currentRefinement, refine, onFocus }) => {
    const { t } = useTranslation();

    return (
        <form noValidate action="" role="search">
            <OutlinedInput
                fullWidth
                type="search"
                value={currentRefinement}
                onChange={(event) => refine(event.currentTarget.value)}
                onFocus={onFocus}
                startAdornment={
                    <LottieAnimation
                        animationData={SearchAnimation}
                        width={24}
                        height={24}
                    />
                }
                placeholder={t('searchBoxPlaceholder')}
            />
        </form>
    );
};

const CustomSearchBox = connectSearchBox(SearchBox);
