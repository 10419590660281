import React from 'react';
import { Stack, Box } from '@mui/material';
import ListaTextField from '../shared/ListaTextField';
import PhoneInput from '../shared/PhoneInput';
import { useTranslation } from 'react-i18next';

export default function CompaniesForm({ fields, setFields, errors }) {
    const { t } = useTranslation();

    const updateField = (key, value) => {
        const newVal = { ...fields };
        newVal[key] = value;
        delete newVal.address_id;
        setFields(newVal);
    };

    return (
        <Stack spacing={2}>
            <ListaTextField
                label="Name"
                value={fields.name || ''}
                onChange={(e) => updateField('name', e.target.value)}
                error={errors && errors.name ? errors.name : false}
                helperText={errors && errors.name ? errors.name : ''}
            />
            <PhoneInput
                value={fields.phone}
                onChange={(value) => updateField('phone', value)}
                placeholder={t('loginPhonePlaceholder')}
                error={errors && errors.phone ? errors.phone : false}
                helperText={errors && errors.phone ? errors.phone : ''}
            />
            <ListaTextField
                label="Email"
                type="email"
                value={fields.email || ''}
                onChange={(e) => updateField('email', e.target.value)}
                error={errors && errors.email ? errors.email : false}
                helperText={errors && errors.email ? errors.email : ''}
            />
            <ListaTextField
                label="Website"
                value={fields.website || ''}
                onChange={(e) => updateField('website', e.target.value)}
                error={errors && errors.website ? errors.website : false}
                helperText={errors && errors.website ? errors.website : ''}
            />
        </Stack>
    );
}
