import TagsFeed from './TagsFeed';
import FoldersFeed from './FoldersFeed.js';
import { useAppState } from '../../../store/store';
import Login from '../login/Login';
import Frame from '../shared/Frame';

export default function Feed({ currentTab, tabs }) {
    const { state } = useAppState();

    if (!state.user.token) {
        return (
            <Frame>
                <Login />
            </Frame>
        );
    }

    if (currentTab === tabs.FOLDERS) {
        return (
            <FoldersFeed
                currentTab={currentTab}
                tabs={tabs}
            />
        );
    }

    return (
        <TagsFeed
            currentTab={currentTab}
            tabs={tabs}
        />
    );
}
