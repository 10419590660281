const MuiDialog = {
    styleOverrides: {
        paper: {
            margin: 16,
            minWidth: 'calc(100% - 32px)',
        },

        paperFullScreen: {
            margin: 0,
            minWidth: '100%',
        },
    },
};

export default MuiDialog;
