import { Box, Button, Stack, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/AddIcon.svg';
import ListaDialog from '../shared/ListaDialog';
import { ReactComponent as CameraIcon } from '../../../assets/icons/CameraIcon.svg';
import ListaSwitch from '../shared/ListaSwitch';
import { useSnackbar } from 'notistack';
import S3UploadController from '../../../lib/S3UploadController';
import DataController from '../../../lib/DataController';
import { useAppState } from '../../../store/store';
import { useRef } from 'react';
import { generateVideoThumb, getMediaThumb } from '../../../lib/functions';
import Thumbnail from '../shared/Thumbnail';
import ThumbnailWithProgress from '../shared/ThumbnailWithProgress';
import ImageStack from '../shared/ImageStack';
import ListaTextButton from '../shared/ListaTextButton';
import { ReactComponent as TrashIcon } from '../../../assets/icons/TrashIcon.svg';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import { useTranslation } from 'react-i18next';

const blankFile = {
    src: '',
    temp_thumb: '',
    caption: '',
    is_cover: false,
    file: null,
    progress: 0,
    path: '',
};

export default function AddImages() {
    const { t } = useTranslation();
    const { dispatch, actions, state } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const { setConfirmationPrompt } = useConfirmationPrompt();

    const [newFile, setNewFile] = useState({ ...blankFile });
    const [editMode, setEditMode] = useState(false);
    const [selectedFile, setSelectedFile] = useState({ ...blankFile });
    const [mediaType, setMediaType] = useState(false);

    const [imageModal, setImageModal] = useState(false);
    const [uploading, setUploading] = useState([]);

    const fileInputRef = useRef();
    const modalFileInputRef = useRef();

    const post = state.post.data || null;
    const media = post ? post.media : [];

    // TODO:
    // Loader while async generating video thumbnail

    const handleUploadFileSelected = async (e) => {
        const [inputFile] = e.target.files;

        if (inputFile) {
            const fileToAdd = {
                ...blankFile,
                file: inputFile,
                src: URL.createObjectURL(inputFile),
                path: `/${post.id}/${new Date().getTime()}/${inputFile.name}`,
            };

            if (inputFile.type.includes('image/')) {
                fileToAdd.temp_thumb = fileToAdd.src;
            }

            if (inputFile.type.includes('video/')) {
                fileToAdd.temp_thumb = await generateVideoThumb(inputFile);
            }

            setNewFile(fileToAdd);
            setMediaType(
              inputFile.type.includes('video/') ? t('video') : 
                inputFile.type.includes('image/') ? t('photo') : t('file')
            )
            setImageModal(true);
        }
    };

    const handleCloseModal = () => {
        URL.revokeObjectURL(newFile.src);
        setNewFile({ ...blankFile });
        setEditMode(false);
        setSelectedFile({ ...blankFile });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (modalFileInputRef.current) {
            modalFileInputRef.current.value = '';
        }

        setImageModal(false);
    };

    const pushToUploading = (fileData) => {
        setUploading((val) => {
            const newArray = [fileData, ...val];
            console.log('[pushToUploading] ', newArray);
            return newArray;
        });
    };

    const removeFromUploading = (path) => {
        setUploading((val) => {
            const newArray = [...val];
            const index = newArray.findIndex((el) => el.path === path);

            if (index > -1) {
                newArray.splice(index, 1);
            }

            return newArray;
        });
    };

    const updateProgress = (path, progress) => {
        setUploading((val) => {
            const newArray = [...val];
            const index = newArray.findIndex((el) => el.path === path);
            if (index > -1) {
                newArray[index].progress = progress;
            }

            console.log('[updateProgress] ', newArray);
            return newArray;
        });
    };

    const handleSubmit = async () => {
        setImageModal(false);

        const errorCallback = (message) => {
            if (message)
                enqueueSnackbar(message, {
                    variant: 'error',
                });
        };

        if (!newFile) {
            enqueueSnackbar(t('addImagesMustSelect'), {
                variant: 'error',
            });
            return;
        }

        // push file to uploading array to display the upload progress
        pushToUploading(newFile);

        // start file upload with progress update callback
        const fileUploadLocation = await S3UploadController.putUploadFile({
            file: newFile.file,
            path: newFile.path,
            onProgress: (perc) => {
                updateProgress(newFile.path, perc);
            },
        });

        console.log('upload success: ', fileUploadLocation);

        const JSONData = {
            filename: newFile.path,
            caption: newFile.caption,
            is_cover: newFile.cover,
        };

        const res = await DataController.media.postAssociateFiles(
            post.id,
            JSONData
        );
        if (!res.success) {
            errorCallback(res.message);
            return;
        }

        console.log(
            'FINISHED. Removing file from upload array: ',
            newFile.path
        );
        removeFromUploading(newFile.path);

        console.log('[postAssociateFiles] res: ', res);
        dispatch({
            type: actions.ADD_POST_MEDIA,
            payload: { ...res.data, temp_thumb: newFile.temp_thumb },
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (modalFileInputRef.current) {
            modalFileInputRef.current.value = '';
        }
    };

    const openEditMediaDialog = (el) => {
        setEditMode(true);
        setSelectedFile(el);        
        setMediaType(
          el.is_video ? t('video') : 
            el.is_image ? t('photo') : t('file')
        )
        setImageModal(true);
        console.log(el);
    };

    const saveMediaEdits = () => {
        DataController.media
            .edit({
                id: selectedFile.id,
                is_cover: selectedFile.is_cover,
                caption: selectedFile.caption,
            })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    console.log('[media.edit] res: ', data);
                    dispatch({
                        type: actions.EDIT_POST_MEDIA,
                        payload: data,
                    });
                    handleCloseModal();
                    enqueueSnackbar(message);
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const handleDeleteMedia = () => {
        setConfirmationPrompt({
            title: t('addImagesDeletePromptTitle'),
            body: t('addImagesDeletePromptBody'),
            confirmText: t('addImagesDeletePromptConfirmText'),
        }).then(
            () => {
                DataController.media
                    .delete({ id: selectedFile.id })
                    .then(({ success, data, errors, message }) => {
                        if (success) {
                            dispatch({
                                type: actions.REMOVE_POST_MEDIA,
                                payload: selectedFile.id,
                            });
                            enqueueSnackbar(message);
                            handleCloseModal();
                        } else {
                            enqueueSnackbar(message, {
                                variant: 'error',
                            });
                        }
                    });
            },
            () => {
                console.warn('delete action canceled');
            }
        );
    };

    return (
        <>
            <ImageStack>
                <Button
                    component="label"
                    htmlFor="image-input"
                    sx={{
                        height: 166,
                        width: 166,
                        flexShrink: 0,
                        backgroundColor: 'neutral.200',
                        border: '2px solid',
                        borderColor: 'secondary.main',
                        borderRadius: 1,
                        color: 'secondary.main',

                        '&:hover': {
                            backgroundColor: 'neutral.200',
                        },
                    }}
                >
                    <input
                        type="file"
                        accept="image/*,video/*"
                        id="image-input"
                        style={{ display: 'none' }}
                        onChange={(e) => handleUploadFileSelected(e)}
                        ref={fileInputRef}
                    />
                    <Stack alignItems="center" spacing={1}>
                        <AddIcon width="25" height="25" />
                        <Typography variant="caption">
                            {t('addImagesAddPhotoOrVideoButton')}
                        </Typography>
                    </Stack>
                </Button>

                {uploading && (
                    <>
                        {uploading.map((el) => {
                            return (
                                <ThumbnailWithProgress
                                    key={el.path}
                                    src={el.temp_thumb}
                                    progress={el.progress}
                                />
                            );
                        })}
                    </>
                )}

                {media && media.length ? (
                    <>
                        {media.map((el) => {
                            return (
                                <Thumbnail
                                    key={el.id}
                                    src={getMediaThumb(el)}
                                    caption={el.caption}
                                    onClick={() => openEditMediaDialog(el)}
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        <Thumbnail placeholder />
                        <Thumbnail placeholder />
                    </>
                )}
            </ImageStack>

            {/* UPLOAD DIALOG */}
            <ListaDialog
                open={imageModal}
                onClose={handleCloseModal}
                fullScreen={true}
                title={t(mediaType)}
                actions={
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={editMode ? saveMediaEdits : handleSubmit}
                    >
                        {editMode
                            ? t('save')
                            : t('addImagesDialogAddToGalleryButton')}
                    </Button>
                }
            >
                <Box
                    sx={{
                        mt: 2,
                        mx: 2,

                        '& img': {
                            borderRadius: 1,
                        },

                        '& video': {
                            maxWidth: '100%',
                            height: 'auto',
                            display: 'block',
                        },
                    }}
                >
                    {!editMode && (
                        <>
                            {newFile.file &&
                                newFile.file.type.includes('image/') && (
                                    <img
                                        src={newFile.src}
                                        width="100%"
                                        height="auto"
                                    />
                                )}
                            {newFile.file &&
                                newFile.file.type.includes('video/') && (
                                    <video
                                        controls
                                        playsInline
                                        src={newFile.src}
                                        loop
                                    />
                                )}
                        </>
                    )}
                    {editMode && (
                        <>
                            {selectedFile &&
                                selectedFile.mimetype.includes('image/') && (
                                    <img
                                        src={selectedFile.url}
                                        width="100%"
                                        height="auto"
                                    />
                                )}
                            {selectedFile &&
                                selectedFile.mimetype.includes('video/') && (
                                    <video
                                        controls
                                        playsInline
                                        src={selectedFile.url}
                                        loop
                                    />
                                )}
                        </>
                    )}
                </Box>

                {/* TODO: use <stack> with spacing={2} */}
                {/* TODO: extract and style switch for reuse */}
                {/* TODO: Style button */}
                <Box sx={{ mx: 2, mt: 2 }}>
                    {!editMode && (
                        <TextField
                            fullWidth
                            placeholder={t('addImagesDialogCaptionPlaceholder')}
                            value={newFile.caption}
                            onChange={(e) =>
                                setNewFile((old) => ({
                                    ...old,
                                    caption: e.target.value,
                                }))
                            }
                        ></TextField>
                    )}
                    {editMode && (
                        <TextField
                            fullWidth
                            placeholder={t('addImagesDialogCaptionPlaceholder')}
                            value={selectedFile.caption || ''}
                            onChange={(e) =>
                                setSelectedFile((old) => ({
                                    ...old,
                                    caption: e.target.value,
                                }))
                            }
                        ></TextField>
                    )}
                </Box>
                <Box sx={{ mx: 2, mt: 2 }}>
                    {!editMode && (
                        <ListaSwitch
                            label={t('addImagesDialogCoverSwitch')}
                            checked={newFile.cover}
                            onChange={(e) =>
                                setNewFile((old) => ({
                                    ...old,
                                    cover: e.target.checked,
                                }))
                            }
                        />
                    )}
                    {editMode && (
                        <ListaSwitch
                            label={t('addImagesDialogCoverSwitch')}
                            checked={!!selectedFile.is_cover}
                            onChange={(e) =>
                                setSelectedFile((old) => ({
                                    ...old,
                                    is_cover: e.target.checked,
                                }))
                            }
                        />
                    )}
                </Box>
                {!editMode && (
                    <Box sx={{ mx: 2, mt: 2 }}>
                        <Button
                            color="secondary"
                            sx={{ pl: 0 }}
                            component="label"
                            htmlFor="image-input-modal"
                        >
                            <input
                                type="file"
                                accept="image/*,video/*"
                                id="image-input-modal"
                                style={{ display: 'none' }}
                                onChange={(e) => handleUploadFileSelected(e)}
                                ref={modalFileInputRef}
                            />
                            <CameraIcon width="32" />
                            <Box component="span" sx={{ ml: 1.5 }}>
                                { [t('retake'), t(mediaType)].join(' ') }
                            </Box>
                        </Button>
                    </Box>
                )}
                {editMode && (
                    <Box
                        mx={2}
                        mt={2}
                        sx={{
                            borderTop: '1px solid',
                            borderColor: 'neutral.300',
                        }}
                    >
                        <ListaTextButton
                            color="error"
                            caption={ [t('delete'), t(mediaType)].join(' ') }
                            icon={<TrashIcon />}
                            onClick={handleDeleteMedia}
                        ></ListaTextButton>
                    </Box>
                )}
            </ListaDialog>
        </>
    );
}
