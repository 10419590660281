import { Box } from '@mui/material';
import React from 'react';
import sharedStyles from '../../theme/sharedStyles';

export default function Shell({ children }) {
    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                borderRadius: 0,
                position: 'relative',
                // overflowX: 'hidden',
                ...sharedStyles.container,
            }}
        >
            {children}
        </Box>
    );
}
