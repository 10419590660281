import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

export default function OrderCustomerInfo({ order }) {
    return (
        <Stack spacing={2}>
            <Typography variant="subtitle1">Customer information</Typography>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ fontWeight: 600 }}
                    color="neutral.700"
                >
                    Contact information
                </Typography>
                <Typography variant="body2">
                    {order.contact.firstname} {order.contact.lastname}
                </Typography>
            </Box>

            <Box>
                <Typography
                    variant="body2"
                    sx={{ fontWeight: 600 }}
                    color="neutral.700"
                >
                    Shipping address
                </Typography>
                {order.address.multiline &&
                    order.address.multiline.split('\n').map((line, index) => (
                        <Typography variant="body2" key={index}>
                            {line}
                        </Typography>
                    ))}
            </Box>

            {order.shipment.has_tracking && (
                <Box>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: 600 }}
                        color="neutral.700"
                    >
                        Shipping method
                    </Typography>
                    <Typography variant="body2">
                        {order.shipment.carrier_name}
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
