import {
    CircularProgress,
    Box,
    Typography,
    Stack,
    Dialog,
    Avatar,
    Divider,
} from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataController from '../../../lib/DataController';
import PageWrapModal from '../../layout/PageWrapModal';
import Header from '../../elements/post/Header';
import Gallery from '../../elements/post/Gallery';
import PinInput from '../../elements/shared/PinInput';
import { useSnackbar } from 'notistack';
import TagDetails from '../../elements/post/TagDetails';
import { useAppState } from '../../../store/store';
import LocationMap from '../../elements/post/LocationMap';
import DocumentsList from '../../elements/post/DocumentsList';
import TranslateToLangSwitch from '../../elements/post/TranslateToLangSwitch';
import { getPostPinFromPersistentStorage } from '../../../lib/functions';
import { useTranslation } from 'react-i18next';
import LoginToEdit from '../../elements/post/LoginToEdit';
import editHover from '../../../assets/lottie/edit-hover.json';
import AnimatedFab from '../../elements/shared/AnimatedFab';
import CenteredCircularProgress from '../../elements/shared/CenteredCircularProgress';
import LinksCarousel from '../../elements/post/LinksCarousel';

export default function ViewPost() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { state, dispatch, actions } = useAppState();
    const post = state.post.data;
    const userToken = useRef(state?.user?.token);
    const [postFetchMessage, setPostFetchMessage] = useState('');

    const { hashId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [pinDialogOpen, setPinDialogOpen] = useState(false);
    const [pinDialogAvatar, setPinDialogAvatar] = useState(null);
    const [pinDialogInfo, setPinDialogInfo] = useState(null);
    const [pin, setPin] = useState('');

    const closePinDialog = () => {
        setPinDialogOpen(false);
        setPinDialogAvatar(null);
        setPinDialogInfo(null);
    };

    useEffect(() => {
        // TODO: cache - don't fetch if already in store
        setLoading(true);

        const pin = getPostPinFromPersistentStorage(hashId);

        DataController.post
            .get({ hashId, pin })
            .then(({ success, data, errors, message }) => {
                setPostFetchMessage(message);

                if (success) {
                    console.log('[post.get] data: ', data);

                    if (data.is_editable && data.status === 'draft') {
                        navigate(`/post/edit/${data.hash}`);
                    } else {
                        dispatch({
                            type: actions.SET_POST,
                            payload: data,
                        });
                        setLoading(false);
                    }
                } else {
                    // TODO: use snackbar
                    if (!!errors.is_private) {
                        setPinDialogAvatar(
                            errors.avatar ? errors.avatar : null
                        );
                        setPinDialogInfo(
                            errors.information ? errors.information : null
                        );
                        setPinDialogOpen(true);
                    }
                    console.error('[post.get] error: ', message);
                }
            });
    }, [hashId, state?.user?.token]);

    useEffect(() => {
        if (state?.user?.token !== userToken.current && !post.is_editable) {
            enqueueSnackbar(postFetchMessage, { variant: 'error' });
            userToken.current = state?.user?.token;
        }
    }, [post]);

    useEffect(() => {
        if (pin.length === 4) {
            DataController.post
                .get({ hashId, pin })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        console.log('[post.get] data: ', data);
                        closePinDialog();

                        if (data.is_editable && data.status === 'draft') {
                            navigate(`/post/edit/${data.hash}`);
                        } else {
                            dispatch({
                                type: actions.SET_POST,
                                payload: { ...data, lastPin: pin },
                            });
                            setLoading(false);
                        }
                    } else {
                        // wrong PIN scenario
                        if (!!errors.is_private) {
                            enqueueSnackbar(message, { variant: 'error' });
                            setPin('');
                        }
                        console.error('[post.get] error: ', message);
                    }
                });
        }
    }, [pin]);

    return (
        <PageWrapModal>
            <Dialog open={pinDialogOpen}>
                <Box p={2} sx={{ textAlign: 'center' }}>
                    {!!pinDialogAvatar && (
                        <Stack alignItems="center" mb={2}>
                            <Avatar src={pinDialogAvatar} />
                        </Stack>
                    )}
                    <Typography variant="subtitle1">
                        {t('postPrivateTagTitle')}
                    </Typography>
                    <Typography variant="body2" mb={2}>
                        {t('postPrivateTagBody')}
                    </Typography>
                    <PinInput
                        length={4}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                    />

                    {!!pinDialogInfo && (
                        <>
                            <Divider
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                }}
                            />
                            <Stack
                                sx={{
                                    flexDirection: 'column',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                }}
                            >
                                {pinDialogInfo.map((company, index) => {
                                    let flex = 'auto';
                                    let margin = 0;

                                    if (
                                        pinDialogInfo.length % 2 === 1 &&
                                        index === pinDialogInfo.length - 1
                                    ) {
                                        flex = '0 0 calc(50% - 8px)';
                                        margin = '0 auto';
                                    }

                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                flex: flex,
                                                margin: margin,
                                            }}
                                        >
                                            {company.name && (
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        pb: 1,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {company.name}
                                                </Typography>
                                            )}
                                            {company.phone && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        pb: 1,
                                                    }}
                                                >
                                                    Tel:{' '}
                                                    <a
                                                        href={`tel:${company.phone}`}
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                    >
                                                        {company.phone}
                                                    </a>
                                                </Typography>
                                            )}
                                            {company.email && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        pb: 1,
                                                    }}
                                                >
                                                    Email:{' '}
                                                    <a
                                                        href={`mailto:${company.email}`}
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                    >
                                                        {company.email}
                                                    </a>
                                                </Typography>
                                            )}
                                            {company.website && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        pb: 1,
                                                    }}
                                                >
                                                    Web:{' '}
                                                    <a
                                                        href={`mailto:${company.website}`}
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                    >
                                                        {company.website}
                                                    </a>
                                                </Typography>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </>
                    )}
                </Box>
            </Dialog>
            {loading ? (
                <CenteredCircularProgress />
            ) : (
                <Box>
                    {post && (
                        <>
                            <Header
                                title={[post.tag?.sku.colorway, t('tag')].join(
                                    ' '
                                )}
                                colorway={post.tag?.sku.colorway || ''}
                                cssColor={post.tag?.css_color || ''}
                            />
                            <Stack sx={{ mt: 8, mb: 10, p: 2 }} spacing={5}>
                                <Typography variant="postTitle" color="initial">
                                    {post.title}
                                </Typography>

                                <Gallery
                                    media={post.media || []}
                                    contact={post.contact}
                                    updatedOn={post.date_formatted}
                                />

                                {post.lang_switch?.visible && (
                                    <TranslateToLangSwitch />
                                )}

                                {!!post.documents && post.documents.length > 0 && (
                                    <Box>
                                        <Typography variant="h4" mb={2}>
                                            {t('postDocuments')}
                                        </Typography>
                                        <DocumentsList
                                            documents={post.documents}
                                        />
                                    </Box>
                                )}

                                {!!post.links && post.links.length > 0 && (
                                    <Box>
                                        <Typography variant="h4" mb={2}>
                                            {t('postLinks')}
                                        </Typography>
                                        <LinksCarousel links={post.links} />
                                    </Box>
                                )}

                                {post.body && (
                                    <Box>
                                        <Typography variant="h4" mb={2}>
                                            {t('postDescription')}
                                        </Typography>
                                        <Box
                                            dangerouslySetInnerHTML={{
                                                __html: post.body,
                                            }}
                                            sx={{
                                                h3: {
                                                    fontSize: 18,
                                                    lineHeight: '110%',
                                                    mt: 4,
                                                    mb: 2,

                                                    '&:first-of-type': {
                                                        mt: 0,
                                                    },
                                                },

                                                ul: {
                                                    paddingLeft: 3,
                                                },

                                                ol: {
                                                    paddingLeft: 3,
                                                },
                                            }}
                                        ></Box>
                                    </Box>
                                )}

                                {!!post.is_geocoded && (
                                    <Box>
                                        <Typography variant="h4" mb={2}>
                                            {t('postLocation')}
                                        </Typography>
                                        <LocationMap mapUrl={post.map_url} />
                                    </Box>
                                )}

                                <TagDetails />

                                {!post.is_editable && !state?.user?.token && (
                                    <LoginToEdit />
                                )}
                            </Stack>

                            {post.is_editable && (
                                <Link to={`/post/edit/${hashId}`}>
                                    <AnimatedFab animationData={editHover} />
                                </Link>
                            )}
                        </>
                    )}
                </Box>
            )}
        </PageWrapModal>
    );
}
