import { Typography, Box, Stack } from '@mui/material';
import React from 'react';
import palette from '../../../theme/palette';
import { motion } from 'framer-motion';

export default function UsageMeter({ storage }) {
    const {
        usage_formatted,
        plan_formatted,
        usage_bytes,
        plan_bytes,
        usage_percentage_other,
        usage_percentage_photo,
        usage_percentage_video,
    } = storage;

    // const usage_percentage_other = 3;
    // const usage_percentage_photo = 30;
    // const usage_percentage_video = 20;

    return (
        <Box width="100%">
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: '100%', mb: 1 }}
            >
                <Typography variant="body2">Lista File Storage</Typography>
                <Typography variant="body2">
                    {usage_formatted} of {plan_formatted} used
                </Typography>
            </Stack>
            <Box
                sx={{
                    mt: 1,
                    width: '100%',
                    height: 32,
                    backgroundColor: 'neutral.200',
                    overflow: 'hidden',
                    display: 'flex',
                    borderRadius: 1,
                }}
            >
                {usage_percentage_video ? (
                    <Bar
                        percentage={usage_percentage_video}
                        color={palette.primary[400]}
                    />
                ) : (
                    ''
                )}
                {usage_percentage_photo ? (
                    <Bar
                        percentage={usage_percentage_photo}
                        color={palette.secondary[400]}
                    />
                ) : (
                    ''
                )}
                {usage_percentage_other ? (
                    <Bar
                        percentage={usage_percentage_other}
                        color={palette.signalRed[400]}
                    />
                ) : (
                    ''
                )}
            </Box>

            <Stack direction="row" spacing={2} mt={1} mb={1}>
                <LegendItem color={palette.primary[400]} title="Videos" />
                <LegendItem color={palette.secondary[400]} title="Photos" />
                <LegendItem color={palette.signalRed[400]} title="Other" />
            </Stack>

            {usage_bytes > plan_bytes ? (
                <Box>
                    <Typography
                        variant="body2"
                        color="signalRed.main"
                        align="center"
                    >
                        You&apos;re over your current plan limit. We&apos;ll
                        automatically upgrade you shortly to avoid any
                        interruption.
                    </Typography>
                </Box>
            ) : (
                ''
            )}
        </Box>
    );
}

function Bar({ percentage, color }) {
    return (
        <Box
            component={motion.div}
            title={percentage + '%'}
            sx={{
                height: '100%',
                width: 0,
                background: color,
                flexShrink: 0,
                flexGrow: 0,
            }}
            initial={{
                width: 0,
            }}
            animate={{
                width: `max(${percentage + '%'}, 3px)`,
            }}
            transition={{
                delay: 0.2,
                duration: 1,
                type: 'spring',
            }}
        ></Box>
    );
}

function LegendItem({ color, title }) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box
                width={20}
                height={20}
                sx={{ backgroundColor: color, borderRadius: 1 }}
            ></Box>
            <Typography variant="body2">{title}</Typography>
        </Stack>
    );
}
