import { Html5QrcodeSupportedFormats, Html5Qrcode } from 'html5-qrcode';
import 'context-filter-polyfill';


Html5Qrcode.prototype.foreverScan = function (
    internalConfig,
    qrCodeSuccessCallback,
    qrCodeErrorCallback
) {
    var _this = this;
    if (!this.shouldScan) {
        return;
    }
    if (!this.localMediaStream) {
        return;
    }
    var videoElement = this.videoElement;
    var widthRatio = videoElement.videoWidth / videoElement.clientWidth;
    var heightRatio = videoElement.videoHeight / videoElement.clientHeight;
    if (!this.qrRegion) {
        throw 'qrRegion undefined when localMediaStream is ready.';
    }
    var sWidthOffset = this.qrRegion.width * widthRatio;
    var sHeightOffset = this.qrRegion.height * heightRatio;
    var sxOffset = this.qrRegion.x * widthRatio;
    var syOffset = this.qrRegion.y * heightRatio;
    this.context.drawImage(
        videoElement,
        sxOffset,
        syOffset,
        sWidthOffset,
        sHeightOffset,
        0,
        0,
        this.qrRegion.width,
        this.qrRegion.height
    );
    this.context.filter = 'invert(100%)'; // <-- Thx to [thk-root](https://github.com/thk-root)
    var triggerNextScan = function () {
        _this.foreverScanTimeout = setTimeout(function () {
            _this.foreverScan(
                internalConfig,
                qrCodeSuccessCallback,
                qrCodeErrorCallback
            );
        }, _this.getTimeoutFps(internalConfig.fps));
    };
    this.scanContext(qrCodeSuccessCallback, qrCodeErrorCallback)
        .then(function (isSuccessfull) {
            if (!isSuccessfull) {
                _this.invertQR = !_this.invertQR;
                 if (_this.invertQR) {
                     _this.context.filter = 'invert(100%)';
                 }
                 else {
                     _this.context.filter = 'none';
                 }

                 _this.context.drawImage(videoElement, sxOffset, syOffset, sWidthOffset, sHeightOffset, 0, 0, _this.qrRegion.width, _this.qrRegion.height);

                 _this.scanContext(qrCodeSuccessCallback, qrCodeErrorCallback).finally(function () {
                     triggerNextScan();
                 });
             } else {
                 triggerNextScan();
             }
        })
        .catch(function (error) {
            _this.logger.logError(
                'Error happend while scanning context',
                error
            );
            triggerNextScan();
        });
};

export { Html5QrcodeSupportedFormats, Html5Qrcode };