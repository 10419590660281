import {
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import React from 'react';

export default function SizeOptionsWidget({ sizes, onChange, selected }) {
    return (
        <Box>
            <Typography
                variant="body2"
                color="neutral.500"
                sx={{ lineHeight: '1em' }}
            >
                Tag size
            </Typography>
            <Typography variant="caption">{selected.title} ({selected.label})</Typography>

            <Box mt={1}>
                <ToggleButtonGroup value={selected.value} fullWidth exclusive>
                    {sizes.map((size, index) => (
                        <ToggleButton
                            key={index}
                            value={size.value}
                            onClick={(e, value) => {
                                if (value === selected.value) return;
                                onChange(
                                    sizes.find((size) => size.value === value)
                                );
                            }}
                        >
                            {size.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}
