import { Button, Stack, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useCart } from '../../../contexts/CartContext';
import DataController from '../../../lib/DataController';
import { useAppState } from '../../../store/store';
import CartActionBar from '../cart/CartActionBar';
import AddressPicker from '../shared/AddressPicker';
import Frame from '../shared/Frame';
import PaymentPicker from '../shared/PaymentPicker';
import ListaButton from '../shared/ListaButton';
import { Link } from 'react-router-dom';
import OrderSummary from '../order/OrderSummary';

export default function CheckoutConfirm({ onOrderCompleted }) {
    const { cart } = useCart();
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleAddressChange = async (address_id) => {
        await DataController.checkout
            .setShipping({
                uuid: cart.uuid,
                fields: { address_id },
            })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.SET_CART, payload: data });
                    enqueueSnackbar(message);
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const handlePaymentChange = async (paymentmethod_id) => {
        await DataController.checkout
            .setPayment({
                uuid: cart.uuid,
                paymentmethod_id,
            })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.SET_CART, payload: data });
                    enqueueSnackbar(message);
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const transact = () => {
        setLoading(true);

        DataController.checkout
            .transact({ uuid: cart.uuid })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    console.log('TRANSACTION SUCCESSFULL, data: ', data);
                    dispatch({ type: actions.SET_CART, payload: data });
                    if (window && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'checkout-complete',
                            total: data.total,
                            qty: data.qty,
                        });
                    }
                    onOrderCompleted(cart.uuid);
                } else {
                    console.error(message);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Stack spacing={2}>
            <AddressPicker
                title="Shipping Address"
                selectedAddressId={cart.address_id}
                onChange={handleAddressChange}
            />

            <Frame>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">Delivery</Typography>
                    <Box
                        sx={{
                            backgroundColor: 'primary.main',
                            py: 0.5,
                            px: 1,
                            borderRadius: 1,
                            textTransform: 'uppercase',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="caption" sx={{ fontSize: 11 }}>
                            Free 2-day
                        </Typography>
                    </Box>
                </Stack>
                <Typography variant="body2" mt={2}>
                    Free 2-day shipping with every order
                </Typography>
            </Frame>

            <PaymentPicker
                onChange={handlePaymentChange}
                selectedPaymentMethodId={cart.paymentmethod_id}
            />

            <OrderSummary order={cart} />

            <CartActionBar>
                <ListaButton
                    variant="contained"
                    disabled={!cart.address_id || !cart.paymentmethod_id}
                    onClick={transact}
                    loading={loading}
                >
                    Complete order
                </ListaButton>
                {/* <Link to={`/cart/${cart.uuid}`}>
                    <Button color="secondary" size="small">
                        back
                    </Button>
                </Link> */}
            </CartActionBar>
        </Stack>
    );
}
