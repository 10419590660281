import { Box, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { duration } from "../../../theme/motionConfig";
import { motion } from "framer-motion";

export default function AppMenuItem({ text, link, onClick }) {
    return (
        <Box
            onClick={onClick}
            component={motion.div}
            initial={{ opacity: 0 }}
            pr={1}
            animate={{
                opacity: 1,
                transition: {
                    duration: duration.medium,
                },
            }}
            exit={{
                opacity: 0,
                transition: {
                    duration: duration.short,
                },
            }}
            sx={{
                '& + &': {
                    mt: 4,
                },
            }}
        >
            <Box component={Link} to={link}>
                <Typography variant="body1">{text}</Typography>
            </Box>
        </Box>
    );
}
