import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

export default function Thumbnail({
    caption = '',
    src,
    placeholder = false,
    ...otherProps
}) {
    return (
        <Stack
            sx={{
                width: 166,
                flexShrink: 0,

                '& img': {
                    width: 166,
                    height: 166,
                    flexShrink: 0,
                    borderRadius: 1,
                    overflow: 'hidden',
                    objectFit: 'cover',
                    objectPosition: 'center',
                },
            }}
            {...otherProps}
        >
            {placeholder ? (
                <Box
                    sx={{
                        width: 166,
                        height: 166,
                        bgcolor: 'neutral.300',
                        borderRadius: 1,
                    }}
                ></Box>
            ) : (
                <img src={src} alt={caption} />
            )}

            {caption && <Typography variant="caption">{caption}</Typography>}
        </Stack>
    );
}
