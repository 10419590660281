import React from 'react';
import PageWrapDefault from '../layout/PageWrapDefault';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../store/store';
import Login from '../elements/login/Login';

export default function LoginPage() {
    const { state } = useAppState();
    const navigate = useNavigate();
    
    /*
    if (state.user && state.user.token) {
        navigate(`/scan`);
    }
    */
    
    return (
        <PageWrapDefault>
            <Login />
        </PageWrapDefault>
    );
    
}
