import React from 'react';
import { FormControl, Select, InputLabel } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/ArrowDownIcon.svg';

export default function ListaSelect({
    id,
    labelId,
    label,
    sx,
    children,
    ...other
}) {
    return (
        <FormControl
            fullWidth
            sx={{
                '& .MuiSelect-select': { pt: 3, pb: 1 },

                '& .MuiInputBase-root': {
                    height: 52,
                },

                '& legend': {
                    maxWidth: '0.01px !important', // block width to show the full border
                },

                '& .MuiInputLabel-shrink': {
                    transform: 'scale(0.65) translate(22px, 11px)',
                },

                '& .MuiSelect-icon': {
                    right: 12,
                    top: 'calc(50%)',
                },

                ...sx,
            }}
        >
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                label={label}
                IconComponent={ArrowDownIcon}
                {...other}
            >
                {children}
            </Select>
        </FormControl>
    );
}
