import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Box,
    CircularProgress,
    TextField,
    Stack,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Typography,
} from '@mui/material';
import { ReactComponent as CaretRightIcon } from '../../../assets/icons/CaretRightIcon.svg';
import ListaDialog from '../shared/ListaDialog';
import { useAppState } from '../../../store/store';
import useAppData from '../../../store/useAppData';
import DataController from '../../../lib/DataController';
import { useSnackbar } from 'notistack';
import ListaCheckbox from '../shared/ListaCheckbox';
import ListaTextButton from '../shared/ListaTextButton';
import { ReactComponent as AddIcon } from '../../../assets/icons/AddIcon.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/FolderIcon.svg';
import { LayoutGroup, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ListaButton from '../shared/ListaButton';

export default function FolderPicker({ onChange }) {
    const { t } = useTranslation();
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [displayNewFolderField, setDisplayNewFolderField] = useState(true);
    const [loading, setLoading] = useState(null); // null | folderId  -> to track which one is being set as folder in the dialog list

    const folders = state.folders.data;
    const loaded = state.folders.loaded;

    const nameInputRef = useRef();

    useAppData({
        queries: ['getFolders'],
    });

    useEffect(() => {
        if (!dialogOpen) {
            setDisplayNewFolderField(false);
        }
    }, [dialogOpen, folders]);

    const handleCreateAndAddToFolder = () => {
        if (!nameInputRef.current) return;
        if (!nameInputRef.current.value) return;

        setLoading(true);

        DataController.folders
            .create({ title: nameInputRef.current.value })
            .then(({ success, data, message }) => {
                if (success) {
                    const folderId = data.id;
                    updateFolder(folderId).then(() => {
                        dispatch({
                            type: actions.ADD_NEW_FOLDER,
                            payload: data,
                        });
                    });
                } else {
                    enqueueSnackbar(message);
                }
            });
    };

    const updateFolder = async (folderId) => {
        setLoading(folderId);
        await onChange({ folder_id: folderId });
        setDialogOpen(false);
        setLoading(null);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                sx={{ justifyContent: 'space-between', pl:1.5 }}
                onClick={() => setDialogOpen(true)}
            >
                
                <Stack
                    direction="row"
                    spacing={1.5}
                    alignItems="center"
                >
                    <FolderIcon style={{
                      width: 26,
                      height: 26
                    }} />
                    <Typography>
                      {state.post.data?.folder_id
                        ? state.post.data.folder.title
                        : t('folderPickerSelectPlaceholder')}
                    </Typography>
                </Stack>

                <CaretRightIcon />
            </Button>

            <ListaDialog
                title={t('folderPickerDialogTitle')}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                {!loaded ? (
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box p={2} pt={0}>
                            {!!folders.length && (
                                <>
                                    <List
                                        sx={{
                                            width: '100%',
                                            px: 0,
                                            py: 2,
                                            overflow: 'auto',
                                            maxHeight: 300,
                                        }}
                                    >
                                        {folders.map((folder) => {
                                            const labelId = `checkbox-list-label-${folder.id}`;

                                            return (
                                                <ListItem
                                                    key={folder.id}
                                                    disablePadding
                                                >
                                                    <ListItemButton
                                                        role={undefined}
                                                        onClick={() =>
                                                            updateFolder(
                                                                folder.id
                                                            )
                                                        }
                                                        sx={{
                                                            py: 0,
                                                            px: 0,
                                                            minHeight: 56,
                                                        }}
                                                    >
                                                        <ListItemText
                                                            id={labelId}
                                                            primary={
                                                                folder.title
                                                            }
                                                            primaryTypographyProps={{
                                                                variant:
                                                                    'body1',
                                                                color: 'neutral.600',
                                                            }}
                                                        />
                                                        <ListItemIcon
                                                            sx={{
                                                                justifyContent:
                                                                    'flex-end',
                                                            }}
                                                        >
                                                            <ListaCheckbox
                                                                checked={
                                                                    folder.id ===
                                                                    state.post
                                                                        .data
                                                                        ?.folder_id
                                                                }
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{
                                                                    'aria-labelledby':
                                                                        labelId,
                                                                }}
                                                                loading={
                                                                    folder.id ===
                                                                    loading
                                                                }
                                                            />
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                    <Divider />
                                </>
                            )}

                            <Box pt={2} component={motion.div} layout>
                                <LayoutGroup>
                                    {displayNewFolderField ||
                                    folders.length < 1 ? (
                                        <Stack
                                            spacing={2}
                                            component={motion.div}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            <TextField
                                                placeholder={t(
                                                    'folderPickerNewFolderPlaceholder'
                                                )}
                                                inputRef={nameInputRef}
                                            />
                                            <ListaButton
                                                variant="contained"
                                                fullWidth
                                                onClick={
                                                    handleCreateAndAddToFolder
                                                }
                                                loading={loading !== null}
                                            >
                                                {t(
                                                    'folderPickerNewFolderCreateButton'
                                                )}
                                            </ListaButton>
                                        </Stack>
                                    ) : (
                                        <ListaTextButton
                                            large
                                            caption={t(
                                                'folderPickerNewFolderButton'
                                            )}
                                            icon={<AddIcon />}
                                            onClick={() =>
                                                setDisplayNewFolderField(true)
                                            }
                                            component={motion.button}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        />
                                    )}
                                </LayoutGroup>
                            </Box>
                        </Box>
                    </>
                )}
            </ListaDialog>
        </>
    );
}
