import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import NeutrifStudioRegularTtf from '../../../assets/fonts/Neutrif-Studio-Regular.ttf';
import PoppinsRegular from '../../../assets/fonts/Poppins-Regular.woff';
import { stripeKey } from '../../../lib/config.js';

const stripePromise = loadStripe(stripeKey);

function StripeProvider({ children }) {
    return (
        <Elements
            stripe={stripePromise}
            options={{
                fonts: [
                    {
                        src: `url(${PoppinsRegular})`,
                        family: 'Poppins, sans-serif',
                        style: 'normal',
                    },
                ],
            }}
        >
            {children}
        </Elements>
    );
}

export default StripeProvider;
