import { ToggleButtonGroup, ToggleButton, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Footer from '../post/Footer.js';
import palette from '../../../theme/palette.js';
import { motion } from 'framer-motion';
import { useCallback } from 'react';

export default function HomeTabPanel({ currentTab, setCurrentTab, tabs }) {
    const navigate = useNavigate();

    const onClick = useCallback((e, value) => {
        if (value === currentTab) {
            return;
        }

        if (value === tabs.SHOP) {
            navigate('/shop');
            return;
        }

        setCurrentTab(value);
    }, [currentTab]); 

    return (
        <Footer>
            <ToggleButtonGroup
                exclusive
                fullWidth
                value={currentTab}
                sx={{
                    mb: 2.25,
                }}
            >
                {Object.keys(tabs).map((tab, i) => (
                    <ToggleButton
                        key={i}
                        value={tab}
                        sx={{
                            position: 'relative',
                            color: palette.secondary[800],
                            border: `2px solid ${palette.secondary.main} !important`,
                            '&:first-of-type': {
                                borderRadius: '8px 0px 0px 8px',
                            },

                            '&:last-of-type': {
                                borderRadius: '0px 8px 8px 0px',
                            },
                        }}
                        onClick={onClick}
                    >
                        {tab === currentTab ?
                            <Box
                                component={motion.div}
                                layoutId="tab"
                                sx={{
                                    backgroundColor: palette.secondary.main,
                                    color: palette.neutral[100],
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="h4">{tab}</Typography>
                            </Box> :
                            <Typography variant="h4">{tab}</Typography>
                        }
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Footer>
    );
}
