import { Box, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import sharedStyles from '../../theme/sharedStyles';
import { ReactComponent as ListaLogo } from '../../assets/ListaLogo.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/BackIcon.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/MenuIcon.svg';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../lib/hooks/useWindowSize';
import AppMenu from '../elements/AppMenu/AppMenu';
import { AnimatePresence } from 'framer-motion';
import HamburgerButton from '../elements/shared/HamburgerButton';

export default function AppHeader() {
    const location = useLocation();
    const hasBackButton = location.pathname == '/shop' || location.pathname == '/account' || location.pathname == '/orders';
    const [windowWidth] = useWindowSize();
    const [isAppMenuShown, setIsAppMenuShown] = useState(false);

    return (
        <Stack
            component="header"
            px={2}
            py={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                position: 'fixed',
                minHeight: 48,
                width: '100%',
                top: 0,
                left: 0,
                color: 'secondary.main',
                zIndex: 10,
                ...sharedStyles.getFixedContainerStyles(windowWidth),
                ...sharedStyles.glassmorphism,
            }}
        >
            <Stack direction="row" alignItems="center">
                {/* TODO: animate appearance */}
                {hasBackButton && (
                    <Link to="/">
                        <IconButton
                            size="small"
                            sx={{ mr: 1, color: 'inherit' }}
                        >
                            <BackIcon />
                        </IconButton>
                    </Link>
                )}
                <ListaLogo width={88} height={18} />
            </Stack>
            <Box
                onClick={() => setIsAppMenuShown((prev) => !prev)}
            >
                <HamburgerButton active={isAppMenuShown} />
            </Box>
            <AnimatePresence>
                {isAppMenuShown &&
                    <AppMenu
                        setIsAppMenuShown={setIsAppMenuShown}
                    />
                }
            </AnimatePresence>
        </Stack>
    );
}
