import { Box, Stack, Typography } from '@mui/material';
import ListaDialog from '../shared/ListaDialog';
import ListaButton from '../shared/ListaButton';
import PhoneInput from '../shared/PhoneInput';
import palette from '../../../theme/palette';
import { useState, useCallback } from 'react';
import { useAppState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import DataController from '../../../lib/DataController';

export default function PhoneEditDialog({ open, onClose }) {
    const { state } = useAppState();
    const [phone, setPhone] = useState(state?.user?.phone_formatted || '');
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const editPhone = useCallback(async () => {
        const response = await DataController.profile.editPhone(phone);

        if (response.success) {
            console.log('[Edit Phone]: ', response);
            setError();
            setIsEmailDialogOpen(false);
        } else {
            console.log('[Edit Phone]: ', response);
            setError(response?.errors?.phone);
        }
    });

    return (
        <ListaDialog
            open={open}
            onClose={onClose}
            title={'Edit Phone'}
            actions={
                <ListaButton
                    variant="contained"
                    fullWidth
                    onClick={editPhone}
                    loading={false}
                >
                    Save
                </ListaButton>
            }
        >
            <Box p={2}>
                <Stack spacing={2}>
                    <PhoneInput
                      value={phone}
                      onChange={(value) => setPhone(value)}
                      placeholder={t('loginPhonePlaceholder')}
                    />
                    
                    {error && (
                        <Box mt={1}>
                            <Typography
                                variant="caption"
                                sx={{ color: palette.signalRed.main }}
                            >
                                {error}
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </Box>
        </ListaDialog>
    );
}