const sharedStyles = {
    glassmorphism: {
        background: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(20px)',
    },
    container: {
        maxWidth: 428,
        margin: '0 auto',
    },
    getFixedContainerStyles: (windowWidth) => ({
        left: '50%',
        maxWidth: windowWidth,
        marginLeft: `${-(windowWidth/2)}px!important`,
    }),
};

export default sharedStyles;
