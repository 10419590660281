import TestPage from './components/pages/TestPage';
import { StateProvider } from './store/store';
import ListaThemeProvider from './theme/theme';
import { BrowserRouter, Route } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import CreatePost from './components/pages/post/CreatePost';
import ViewPost from './components/pages/post/ViewPost';
import EditPost from './components/pages/post/EditPost';
import ScanPage from './components/pages/ScanPage';
import AppWrapper from './components/layout/AppWrapper';
import Shell from './components/layout/Shell';
import { CssBaseline } from '@mui/material';
import DesignSystem from './components/pages/DesignSystem';
import AnimatedRoutes from './components/layout/AnimatedRoutes';
import StyledSnackbarProvider from './components/layout/StyledSnackbarProvider';
import { ConfirmationPromptProvider } from './contexts/ConfirmationPrompt';
import { TranslationContextProvider } from './contexts/TranslationContext';
import LoginPage from './components/pages/LoginPage';
import ShopPage from './components/pages/ShopPage';
import { CartProvider } from './contexts/CartContext';
import CartPage from './components/pages/CartPage';
import CheckoutPage from './components/pages/CheckoutPage';
import ViewFolder from './components/pages/folder/ViewFolder';
import TrackPage from './components/pages/TrackPage';
import AccountPage from './components/pages/AccountPage';
import OrdersPage from './components/pages/OrdersPage';
import ScrollToTop from './components/layout/ScrollToTop';
import { TagsProvider } from './contexts/TagsContext';
import InvitePage from './components/pages/InvitePage';
import { FiltersProvider } from './contexts/TagsFilterContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

function App() {
    return (
        <StateProvider>
            <TranslationContextProvider>
                <ListaThemeProvider>
                    <CssBaseline />
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <StyledSnackbarProvider>
                            <ConfirmationPromptProvider>
                                <CartProvider>
                                    <BrowserRouter>
                                        <FiltersProvider>
                                            <TagsProvider>
                                                <ScrollToTop />
                                                <AppWrapper>
                                                    <Shell>
                                                        <AnimatedRoutes>
                                                            <Route
                                                                path="/"
                                                                element={
                                                                    <HomePage />
                                                                }
                                                            />

                                                            <Route path="/folder">
                                                                <Route
                                                                    path=":folderId"
                                                                    element={
                                                                        <ViewFolder />
                                                                    }
                                                                />
                                                            </Route>

                                                            <Route
                                                                path="/login"
                                                                element={
                                                                    <LoginPage />
                                                                }
                                                            />

                                                            <Route path="/post">
                                                                <Route
                                                                    path="create/:tagId"
                                                                    element={
                                                                        <CreatePost />
                                                                    }
                                                                />
                                                                <Route
                                                                    path=":hashId"
                                                                    element={
                                                                        <ViewPost />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="edit/:hashId"
                                                                    element={
                                                                        <EditPost />
                                                                    }
                                                                />
                                                            </Route>

                                                            <Route
                                                                path="/shop"
                                                                element={
                                                                    <ShopPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/cart/:uuid"
                                                                element={
                                                                    <CartPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/checkout/:uuid"
                                                                element={
                                                                    <CheckoutPage />
                                                                }
                                                            />
                                                            <Route
                                                                path="/track/:uuid"
                                                                element={
                                                                    <TrackPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/account"
                                                                element={
                                                                    <AccountPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/invite/:inviteCode"
                                                                element={
                                                                    <InvitePage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/orders"
                                                                element={
                                                                    <OrdersPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/scan"
                                                                element={
                                                                    <ScanPage />
                                                                }
                                                            />

                                                            <Route
                                                                path="/test"
                                                                element={
                                                                    <TestPage />
                                                                }
                                                            />
                                                            <Route
                                                                path="/design-system"
                                                                element={
                                                                    <DesignSystem />
                                                                }
                                                            />
                                                        </AnimatedRoutes>
                                                    </Shell>
                                                </AppWrapper>
                                            </TagsProvider>
                                        </FiltersProvider>
                                    </BrowserRouter>
                                </CartProvider>
                            </ConfirmationPromptProvider>
                        </StyledSnackbarProvider>
                    </LocalizationProvider>
                </ListaThemeProvider>
            </TranslationContextProvider>
        </StateProvider>
    );
}

export default App;
