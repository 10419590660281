import React, { useEffect, useState } from 'react';
import PageWrapModal from '../../layout/PageWrapModal';
import { Dialog } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppState } from '../../../store/store';
import Login from '../../elements/login/Login';
import DataController from '../../../lib/DataController';
import CenteredCircularProgress from '../../elements/shared/CenteredCircularProgress';

export default function CreatePost() {
    const { tagId } = useParams();
    const { state, dispatch, actions } = useAppState();
    const navigate = useNavigate();

    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!state.user.token) {
            setShowLoginDialog(true);
        } else {
            setShowLoginDialog(false);
            setLoading(true);

            DataController.post
                .create({
                    tagId,
                })
                .then(({ success, errors, data, message }) => {
                    console.log({ success, errors, data, message });

                    if (success) {
                        console.log(
                            '[CreatePost] post initialized successfully',
                            data
                        );
                        console.log('[CreatePost] redirecting to edit');

                        dispatch({
                            type: actions.SET_POST,
                            payload: data,
                        });

                        // TODO: if success - save response to state.post

                        navigate(`/post/edit/${data.hash}`);

                        return;
                    } else {
                        console.error(
                            '[CreatePost] postCreate error: ',
                            errors,
                            message
                        );
                    }

                    setLoading(false);
                });
        }
    }, [state.user]);

    const handleCloseLoginDialog = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            console.log(`Prevented "${reason}" close`);
        } else {
            setShowLoginDialog(false);
        }
    };

    return (
        <>
            {loading ? (
                <CenteredCircularProgress />
            ) : (
                <PageWrapModal>
                    <Dialog
                        open={showLoginDialog}
                        onClose={handleCloseLoginDialog}
                    >
                        <Login />
                    </Dialog>
                </PageWrapModal>
            )}
        </>
    );
}
