import { Stack, Typography, Chip, Avatar, Divider, Box } from '@mui/material';
import { React, useCallback, useState } from 'react';
import { useAppState } from '../../store/store';
import Login from '../elements/login/Login';
import AddressPicker from '../elements/shared/AddressPicker';
import PaymentPicker from '../elements/shared/PaymentPicker';
import ListaAccordion from '../elements/shared/ListaAccordion';
import Settings from '../elements/settings/Settings.js';
import PageWrapDefault from '../layout/PageWrapDefault';
import UsageMeter from '../elements/account/UsageMeter';
import CompaniesPicker from '../elements/account/CompaniesPicker.js';

export default function AccountPage() {
    const { state } = useAppState();
    const [isPlanExpanded, setIsPlanExpanded] = useState(false);
    const toggleIsPlanExpanded = useCallback(() => {
        setIsPlanExpanded((prev) => !prev);
    }, []);

    return (
        <PageWrapDefault>
            <Stack spacing={2} p={2} mt={4}>
                <Typography variant="h2">Account</Typography>

                {!state.user.token ? (
                    <Login />
                ) : (
                    <>
                        <Stack spacing={2} alignItems="center">
                            <Avatar
                                src={state.user.avatar}
                                alt={state.user.name}
                                sx={{ width: 66, height: 66 }}
                            />
                            <Stack>
                                <Typography variant="subtitle1">
                                    {state.user.firstname} {state.user.lastname}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="neutral.700"
                                >
                                    {state.user.phone_formatted}
                                </Typography>
                            </Stack>
                            {state.user && state.user.storage && (
                                <UsageMeter storage={state.user.storage} />
                            )}
                        </Stack>

                        {state?.user?.subscription && (
                            <ListaAccordion
                                expanded={isPlanExpanded}
                                onToggle={toggleIsPlanExpanded}
                                title="Plan"
                                contentExpanded={
                                    <>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt={2.25}
                                        >
                                            <Stack direction="column">
                                                <Typography variant="caption">
                                                    {
                                                        state?.user
                                                            ?.subscription?.plan
                                                            ?.plan_title
                                                    }
                                                </Typography>
                                                <Typography variant="body2">
                                                    {
                                                        state?.user
                                                            ?.subscription?.plan
                                                            ?.plan_subtitle
                                                    }
                                                </Typography>
                                                {state?.user?.subscription
                                                    ?.is_trial && (
                                                    <Typography variant="body2">
                                                        Trial ends on{' '}
                                                        {
                                                            state?.user
                                                                ?.subscription
                                                                ?.begins_at_formatted
                                                        }
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                        <Divider sx={{ mt: 2 }} />
                                        <Box sx={{ overflow: 'hidden' }} mt={1}>
                                            <Typography variant="body2">
                                                Billed to{' '}
                                                {
                                                    state?.user?.subscription
                                                        ?.paymentmethod?.brand
                                                }{' '}
                                                {
                                                    state?.user?.subscription
                                                        ?.paymentmethod?.number
                                                }
                                            </Typography>
                                        </Box>
                                    </>
                                }
                            />
                        )}

                        <AddressPicker
                            title={
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    flex={1}
                                >
                                    <Typography variant="subtitle1">
                                        Addresses
                                    </Typography>
                                    <Chip
                                        label={
                                            <Typography
                                                variant="subtitle1"
                                                color="secondary"
                                            >
                                                {state.user.addresses.length}
                                            </Typography>
                                        }
                                        size="small"
                                        sx={{ backgroundColor: 'neutral.200' }}
                                    />
                                </Stack>
                            }
                            mode="account"
                        />

                        <CompaniesPicker />

                        <PaymentPicker
                            mode="account"
                            title={
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    flex={1}
                                >
                                    <Typography variant="subtitle1">
                                        Cards
                                    </Typography>
                                    <Chip
                                        label={
                                            <Typography
                                                variant="subtitle1"
                                                color="secondary"
                                            >
                                                {
                                                    state.user.payment_methods
                                                        .length
                                                }
                                            </Typography>
                                        }
                                        size="small"
                                        sx={{ backgroundColor: 'neutral.200' }}
                                    />
                                </Stack>
                            }
                        />
                        <Settings />
                    </>
                )}
            </Stack>
        </PageWrapDefault>
    );
}
