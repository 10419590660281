import React from 'react';
import { Box, darken, Typography } from '@mui/material';
import { ReactComponent as TagDynamicColor } from '../../../assets/graphics/TagDynamicColor.svg';
import { ReactComponent as QRGraphic } from '../../../assets/graphics/QRGraphic.svg';
import { ReactComponent as LogoGraphic } from '../../../assets/graphics/LogoGraphic.svg';

export default function DynamicTag({ color, size }) {
    const rgb = color ? color.rgb : '';
    const rgbText = color ? color.rgb_text : '';
    const rgbBorder = color ? darken(color.rgb, 0.1) : 'transparent';
    const scale = size && size.value === 'Wayfinder' ? 1 : 0.7;
    const width = size && size.dimensions[0];
    const height = size && size.dimensions[1];

    const isSmall = size && size.value === 'Decor';

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                '--tagBgColor': rgb,
                '--tagQrColor': rgbText,
                '--tagBorderColor': rgbBorder,
                transform: 'scale(0.8)',

                '& svg path, & svg rect': {
                    transition: 'all 0.3s ease-in-out',
                },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    // top: 0,
                    // left: 0,
                    top: isSmall ? 50 : 0,
                    left: isSmall ? 20 : 0,
                    height: isSmall ? 140 : 250,
                    width: isSmall ? 110 : 153,
                    transform: `matrix(0.97, -0.14, 0.28, 0.99, 0, 0)`,
                    transition: 'all 0.6s cubic-bezier(.41,1.72,.67,.84)',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '1px',
                        height: '100%',
                        right: '-36px',
                        top: 0,
                        zIndex: -1,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'neutral.500',
                            transition:
                                'all 0.6s cubic-bezier(.41,1.72,.67,.84)',
                        }}
                    ></Box>
                    <DimensionChip>{height}&quot;</DimensionChip>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '1px',
                        bottom: '-36px',
                        left: 0,
                        zIndex: -1,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'neutral.500',
                            transition:
                                'all 0.6s cubic-bezier(.41,1.72,.67,.84)',
                        }}
                    ></Box>
                    <DimensionChip>{width}&quot;</DimensionChip>
                </Box>

                <Box
                    sx={{
                        background: 'var(--tagBgColor)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        zIndex: 10,
                        height: isSmall ? 140 : 250,
                        width: isSmall ? 110 : 153,
                        borderRadius: isSmall ? 3 : 0,
                        border: `1px solid ${rgbBorder}`,
                        transition: 'all 0.6s cubic-bezier(.41,1.72,.67,.84)',
                    }}
                >
                    <QRGraphic width="100%" />
                    <LogoGraphic width="100%" />
                </Box>
            </Box>
        </Box>
    );
}

function DimensionChip({ children }) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'neutral.100',
                p: 2,
                zIndex: 0,
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'signalRed.main',
                    py: 0.5,
                    px: 1.5,
                    borderRadius: 100,
                }}
            >
                <Typography variant="caption" color="neutral.100">
                    {children}
                </Typography>
            </Box>
        </Box>
    );
}
