import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListaDialog from '../shared/ListaDialog';
import ListaSwitch from '../shared/ListaSwitch';
import ListaButton from '../shared/ListaButton';
import PinInput from '../shared/PinInput';

export default function PrivatePostSwitch({ onChange, checked }) {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState('');

    const handleChange = async (e) => {
        if (e.target.checked) {
            setModalOpen(true);
        } else {
            setLoading(true);
            await onChange({ is_private: 0 });
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSavePIN = async () => {
        if (!pin) return;

        setLoading(true);
        await onChange({ is_private: 1, pin: pin });
        setLoading(false);
        handleModalClose();
    };

    return (
        <>
            <ListaSwitch
                label={t('privatePost')}
                onChange={handleChange}
                checked={checked}
                loading={loading}
            />

            <ListaDialog
                open={modalOpen}
                onClose={handleModalClose}
                title={t('privatePost')}
                actions={
                    <ListaButton
                        variant="contained"
                        fullWidth
                        onClick={handleSavePIN}
                        loading={loading}
                    >
                        {t('privatePostDialogConfirm')}
                    </ListaButton>
                }
            >
                <Box p={2} pb={0}>
                    <Typography pb={2}>
                        {t('privatePostDialogTitle')}
                    </Typography>
                    <PinInput
                        length={4}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                    />
                </Box>
            </ListaDialog>
        </>
    );
}
