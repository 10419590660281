import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import PageWrapDefault from '../layout/PageWrapDefault';
import { useNavigate } from 'react-router-dom';
import DataController from '../../lib/DataController';
import useWindowSize from '../../lib/hooks/useWindowSize.js';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';
import palette from '../../theme/palette';
import { Html5QrcodeSupportedFormats, Html5Qrcode } from '../../lib/PatchedHtml5Qrcode';

const SCAN_STATUS = {
    NOT_LOADED: 0,
    SCANNING: 1,
    UI_LOADED: 2,
};

export default function ScanPage() {
    const navigate = useNavigate();

    const [html5QrCode, setHtmlQrCode] = useState({});
    const [camera, setCamera] = useState('');
    const qrRef = useRef(null);
    const scanStatus = useRef(0);
    const [windowWidth, windowHeight, realWindowWidth] = useWindowSize();

    const handleScan = useCallback((url) => {
        if (!url) {
            return;
        }

        if (url.indexOf('lista.codes') === -1) {
            return;
        }

        const link = document.createElement('a');
        link.href = url;
        const splitUrl = link.pathname.split('/');

        if (!splitUrl.length > 2) {
            return;
        }

        const tagId = splitUrl[2];

        DataController.tag
            .validate({ tagId, isScan: true })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    console.log('[tag.validate] data: ', data);

                    if (data.has_post) {
                        navigate(`../post/${data.post_hash}`);
                    } else {
                        navigate(`../post/create/${tagId}`);
                    }
                } else {
                    // TODO: Handle error - snackbar
                    console.error('[tag.validate] error: ', message, errors);
                }
            });
    }, []);

    useEffect(() => {
        const getCamera = async () => {
            const devices = await Html5Qrcode.getCameras();
            if (devices?.length) {
                console.log(
                    '[Html5Qrcode] detected camera device: ',
                    devices[devices.length - 1]?.id
                );
                setCamera(devices[devices.length - 1]);
                const newHtmlQrCode = new Html5Qrcode('reader');
                setHtmlQrCode(newHtmlQrCode);
            } else {
                console.warn(
                    '[Html5Qrcode] user did not grant permission to use camera'
                );
            }
        };
        if (qrRef.current) {
            getCamera();
        }
    }, [qrRef.current]);

    useEffect(() => {
        if (typeof html5QrCode?.start !== 'function') {
            return;
        }

        if (scanStatus.current !== SCAN_STATUS.NOT_LOADED) {
            return;
        }

        setTimeout(() => {
            const aspectRatio = windowWidth / windowHeight;
            const reversedAspectRatio = windowHeight / windowWidth;
            html5QrCode
                .start(
                    camera?.id,
                    {
                        fps: 1,
                        qrbox: { width: 250, height: 250 },
                        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
                        aspectRatio:
                            realWindowWidth < 600
                                ? reversedAspectRatio
                                : aspectRatio,
                        rememberLastUsedCamera: true,
                        disableFlip: true,
                    },
                    async (decodedText, decodedResult) => {
                        await html5QrCode.stop();
                        scanStatus.current = SCAN_STATUS.NOT_LOADED;
                        handleScan(decodedResult.decodedText);
                    },
                    (errorMessage) => {
                        // console.log('[errorMessage] ', errorMessage);
                    }
                )
                .catch((err) => {
                    console.log('[Html5Qrcode] error starting scanner: ', err);
                });
        }, 1000);
        const statusInt = setInterval(() => {
            const qrRegion = document.querySelector('#qr-shaded-region');
            if (qrRegion instanceof HTMLElement) {
                clearInterval(statusInt);
                scanStatus.current = SCAN_STATUS.UI_LOADED;
                window.html5QrCode = html5QrCode;
            }
        }, 100);

        scanStatus.current = SCAN_STATUS.SCANNING;
    }, [html5QrCode]);

    useEffect(() => {
        return () => {
            try {
                window.html5QrCode
                    .stop()
                    .then(() => {
                        console.log('[html5QrCode] stopped QR code scanner');
                    })
                    .catch((err) => {
                        console.log(
                            '[html5QrCode] error stopping QR code scanner: ',
                            err
                        );
                    });
            } catch (err) {
                console.log(
                    '[html5QrCode] error stopping QR code scanner: ',
                    err
                );
            }
        };
    }, []);

    return (
        <PageWrapDefault>
            {scanStatus.current !== SCAN_STATUS.UI_LOADED && (
                <CenteredCircularProgress />
            )}
            <Box
                sx={{
                    height: windowHeight - 48,
                    overflowY: 'hidden',
                }}
            >
                <Box
                    ref={qrRef}
                    id="reader"
                    sx={{
                        '& video': {
                            overflow: 'hidden!important',
                        },
                        '& #qr-shaded-region div': {
                            display: 'none!important',
                        },
                        '& #qr-shaded-region': {
                            border: `10px solid ${palette.neutral[100]}!important`,
                            borderRadius: '15px',
                            width: '250px!important',
                            height: '250px!important',
                            left: '50%!important',
                            top: '50%!important',
                            transform: 'translate(-50%, -50%)!important',
                            boxShadow: `0px ${
                                1000 - windowHeight
                            }px 0px 1000px rgba(0,0,0,0.66)`,
                            boxSizing: 'border-box!important',
                        },
                    }}
                />
            </Box>
        </PageWrapDefault>
    );
}
