import React from 'react';
import { Stack } from '@mui/material';
import sharedStyles from '../../../theme/sharedStyles';
import useWindowSize from '../../../lib/hooks/useWindowSize';

export default function CartActionBar({ children }) {
    const [windowWidth] = useWindowSize();

    return (
        <Stack
            textAlign="center"
            spacing={1}
            p={2}
            pb={1}
            sx={{
                position: 'fixed',
                width: '100%',
                bottom: 0,
                left: 0,
                ...sharedStyles.getFixedContainerStyles(windowWidth),
                ...sharedStyles.glassmorphism,
                zIndex: 1,
            }}
        >
            {children}
        </Stack>
    );
}
