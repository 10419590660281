import React, { useRef, useState } from 'react';
import { useAppState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import {
    Box,
    Button,
    Stack,
    TextField,
    Typography,
    InputAdornment,
    Divider,
} from '@mui/material';
import DataController from '../../../lib/DataController';
import ListaDialog from '../shared/ListaDialog';
import ListaTextButton from '../shared/ListaTextButton';
import LinksCarousel from './LinksCarousel';
import LottieAnimation from '../shared/LottieAnimation';

import { ReactComponent as AddBookmarkIcon } from '../../../assets/icons/AddBookmark.svg';
import { ReactComponent as BookmarksIcon } from '../../../assets/logos/bookmarks-sharp.svg';
import { ReactComponent as AppleIcon } from '../../../assets/logos/logo-apple-ar.svg';
import { ReactComponent as DropboxIcon } from '../../../assets/logos/logo-dropbox.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/logos/logo-google.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/logos/logo-youtube.svg';
import BookmarkAnimation from '../../../assets/lottie/bookmark.json';

import palette from '../../../theme/palette';

export default function AddLinks() {
    const { dispatch, actions, state } = useAppState();
    const post = state.post.data || null;

    const { enqueueSnackbar } = useSnackbar();

    const [currentUploads, setCurrentUploads] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [goToFirstSlide, setGoToFirstSlide] = useState(false);

    const [showLinkDialog, setShowLinkDialog] = useState(false);
    const linkInput = useRef();

    const pushToCurrentUploads = (url) => {
        setCurrentUploads((val) => [{ url, isSkeleton: true }, ...val]);
        setGoToFirstSlide((val) => !val);
    };

    const removeFromCurrentUploads = (url) => {
        setCurrentUploads((val) => {
            const newArray = [...val];
            const index = newArray.findIndex((el) => el.url === url);

            if (index > -1) {
                newArray.splice(index, 1);
            }

            return newArray;
        });
    };

    const handleAddLink = () => {
        const url = linkInput.current ? linkInput.current.value : '';

        if (!url) return;

        pushToCurrentUploads(url);
        setShowLinkDialog(false);

        DataController.link
            .create({ postId: post.id, url })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({
                        type: actions.ADD_POST_BOOKMARK,
                        payload: { ...data },
                    });
                    enqueueSnackbar(message);
                } else {
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
            })
            .finally(() => {
                removeFromCurrentUploads(url);
            });
    };

    return (
        <Box>
            {post && (
                <>
                    <LinksCarousel
                        goToFirstSlide={goToFirstSlide}
                        links={[...currentUploads, ...post.links]}
                        isEdit={true}
                    />

                    <Box>
                        <Box>
                            <ListaTextButton
                                caption={t('addBookmarksButton')}
                                icon={<AddBookmarkIcon />}
                                onClick={() => setShowLinkDialog(true)}
                            ></ListaTextButton>
                        </Box>

                        <ListaDialog
                            title={t('addBookmarksDialogTitle')}
                            open={showLinkDialog}
                            onClose={() => setShowLinkDialog(false)}
                            actions={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddLink}
                                    fullWidth
                                >
                                    {t('addBookmarksDialogButton')}
                                </Button>
                            }
                        >
                            <Stack spacing={3} pt={0} pb={2} px={2}>
                                <Typography variant="body1" color="neutral.700">
                                    {t('addBookmarksDialogBody')}
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-flex',
                                            gap: '4px',
                                            marginLeft: 0.75,
                                            position: 'relative',
                                            top: '3px',
                                        }}
                                    >
                                        <GoogleIcon
                                            width={16}
                                            height={16}
                                            fill={palette.neutral[400]}
                                        />
                                        <DropboxIcon
                                            width={16}
                                            height={16}
                                            fill={palette.neutral[400]}
                                        />
                                        <YoutubeIcon
                                            width={16}
                                            height={16}
                                            fill={palette.neutral[400]}
                                        />
                                        <BookmarksIcon
                                            width={16}
                                            height={16}
                                            fill={palette.neutral[400]}
                                        />
                                    </Box>
                                </Typography>
                                <TextField
                                    inputRef={linkInput}
                                    placeholder={t(
                                        'addBookmarksDialogPlaceholder'
                                    )}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LottieAnimation
                                                    animationData={
                                                        BookmarkAnimation
                                                    }
                                                    loop={2000}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </ListaDialog>
                    </Box>
                </>
            )}
        </Box>
    );
}
