import palette from '../palette';

const MuiSwitch = {
    styleOverrides: {
        root: {
            width: 64,
            height: 32,
            padding: 0,

            '& .Mui-disabled': {
                '& .MuiSwitch-thumb': {
                    backgroundColor: palette.neutral[300],
                },
                '&+.MuiSwitch-track': {
                    opacity: 1,
                },
            },

            '&.Lista-loading': {
                '& .MuiSwitch-thumb': {
                    display: 'none',
                },
            },
        },

        track: {
            borderRadius: 24,
            background: 'transparent',
            border: '2px solid',
            borderColor: palette.neutral[300],
            opacity: 1,
        },

        thumb: {
            width: 16,
            height: 16,
            boxShadow: 'none',
            background: palette.primary.main,
        },

        switchBase: {
            padding: 8,

            '&.Mui-checked': {
                transform: 'translateX(32px)',

                '&+.MuiSwitch-track': {
                    opacity: 1,
                },
            },
        },

        colorPrimary: {
            '& .MuiSwitch-thumb': {
                backgroundColor: palette.primary.main,
            },

            '&.Mui-checked': {
                backgroundColor: palette.primary.main,

                '&+.MuiSwitch-track': {
                    borderColor: palette.primary.main,
                },

                '& .MuiSwitch-thumb': {
                    backgroundColor: palette.neutral[100],
                },
            },
        },
    },
};

export default MuiSwitch;
