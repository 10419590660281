import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataController from '../../lib/DataController';
import { useAppState } from '../../store/store';

export default function InvitePage() {
    const { inviteCode } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { dispatch, actions } = useAppState();

    useEffect(() => {
        setLoading(true);
        DataController.verifyInvite({ inviteCode }).then(
            ({ success, errors, data, message }) => {
                if (success) {
                    dispatch({
                        type: actions.SET_USER,
                        payload: data,
                    });
                    navigate(data.redirect);
                } else {
                    console.error(
                        '[InvitePage] verifyInvite error: ',
                        errors,
                        message
                    );
                }
                setLoading(false);
            }
        );
    }, []);

    return <div>InvitePage</div>;
}
