import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import useLottieAnimation from '../../../lib/hooks/useLottieAnimation';

export default function LottieAnimation({
    animationData,
    width = 24,
    height = 24,
    loop = false,
}) {
    const animRef = useRef(null);

    const { setAnimationShouldPlay } = useLottieAnimation(
        animRef,
        animationData,
        true,
        loop
    );

    useEffect(() => {
        setTimeout(() => {
            setAnimationShouldPlay(true);
        }, 200);
    }, []);

    return (
        <Box
            ref={animRef}
            sx={{
                width,
                height,
            }}
        ></Box>
    );
}
