import { Stack, Typography, Button } from '@mui/material';
import palette from '../../../theme/palette';

export default function SettingsItem({ title, value, onChangeClick }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2.25}
        >
            <Stack direction="column">
                <Typography variant="caption">{title}</Typography>
                <Typography variant="body2">{value}</Typography>
            </Stack>
            <Button variant="text" onClick={onChangeClick}>
                <Typography
                    variant="caption"
                    sx={{ color: palette.secondary.main }}
                >
                    Change
                </Typography>
            </Button>
        </Stack>
    );
}
