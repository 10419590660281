import React from 'react';
import { Box } from '@mui/material';
import ListaTextButton from '../shared/ListaTextButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../../assets/icons/TrashIcon.svg';
import DataController from '../../../lib/DataController';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTags } from '../../../contexts/TagsContext';

export default function DeletePostButton({ post }) {
    const { t } = useTranslation();
    const { setConfirmationPrompt } = useConfirmationPrompt();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidate } = useTags();

    const handleDelete = () => {
        if (!post || !post.hash) return;

        setConfirmationPrompt({
            title: t('editPostDeleteTagPromptTitle'),
            body: t('editPostDeleteTagPromptBody'),
            confirmText: t('editPostDeleteTagPromptConfirmText'),
        }).then(
            () => {
                DataController.post
                    .delete({ hashId: post.hash })
                    .then(({ success, data, errors, message }) => {
                        if (success) {
                            enqueueSnackbar(message);

                            // clear post from posts array - ie. re-fetch posts
                            invalidate();
                            // redirect to HP
                            navigate('/');
                        } else {
                            enqueueSnackbar(message, {
                                variant: 'error',
                            });
                        }
                    });
            },
            // on reject - do nothing
            () => {}
        );
    };

    return (
        <Box
            direction="row"
            display="flex"
            justifyContent="center"
            sx={{
                borderTop: '1px solid',
                borderColor: 'neutral.300',
                textAlign: 'center',
            }}
        >
            <ListaTextButton
                justifyContent="center"
                color="error"
                caption={t('editPostDeleteTag')}
                icon={<TrashIcon />}
                component="label"
                onClick={handleDelete}
            ></ListaTextButton>
        </Box>
    );
}
