import { Stack, Typography } from '@mui/material';
import React from 'react';
import PageWrapDefault from '../layout/PageWrapDefault';
import Login from '../elements/login/Login';
import { useAppState } from '../../store/store';
import useAppData from '../../store/useAppData';
import OrderSummaryAccordion from '../elements/order/OrderSummaryAccordion';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';

export default function OrdersPage() {
    const { state } = useAppState();
    const loaded = state.orders.loaded;

    useAppData({
        queries: ['getOrders'],
    });

    return (
        <PageWrapDefault>
            <Stack spacing={2} p={2} mt={4}>
                <Typography variant="h2">Order History</Typography>

                {!state.user.token ? (
                    <Login />
                ) : (
                    <>
                        {!loaded ? (
                            <CenteredCircularProgress />
                        ) : (
                            <>
                                {state.orders.data.map((order) => (
                                    <OrderSummaryAccordion
                                        order={order}
                                        key={order.order_number}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </Stack>
        </PageWrapDefault>
    );
}
