const _easing = {
    easeOutExpo: [0.16, 1, 0.3, 1],
    easeInExpo: [0.7, 0, 0.84, 0],
};

/**
 * @property {object} easing - available easings for framer motion
 * @property {array} easing.decelerate Ease out transition - used for ENTERING elements.
 * @property {array} easing.accelerate Ease in transition - used for EXITING elements.
 */

export const easing = {
    ..._easing,
    decelerate: _easing.easeOutExpo,
    accelerate: _easing.easeInExpo,
};

/**
 * @property {object} duration - available durations for framer motion
 * @property {array} duration.short - use for switches, links, hover states, etc...
 * @property {array} duration.medium - use for small surfaces, drawers, etc...
 * @property {array} duration.long - use for full pages, big surfaces, etc...
 */

export const duration = {
    short: 0.1,

    medium: 0.33,
    mediumOut: 0.24,

    long: 0.66,
    longOut: 0.24,
};
