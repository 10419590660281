import { Box } from '@mui/material';
import React from 'react';

export default function Frame({ children, condensed = false, sx }) {
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: 'neutral.300',
                borderRadius: 1,
                p: condensed ? 1 : 2,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}
