import React from 'react';
import { Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { duration } from '../../theme/motionConfig';
import AppHeader from './AppHeader';

export default function PageWrapDefault({ children }) {
    return (
        <Paper
            elevation={0}
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                minHeight: '100vh',
                pt: 6,
            }}
            component={motion.div}
            initial={{
                opacity: 1,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 1,
                transition: { duration: duration.long }, // long transition needed to keep the page in the viewport while modal page "slides" over it
            }}
        >
            {children}
        </Paper>
    );
}
