import { Stack } from '@mui/material';
import React from 'react';
import sharedStyles from '../../../theme/sharedStyles';
import useWindowSize from '../../../lib/hooks/useWindowSize';

export default function Footer({ children }) {
    const [windowWidth] = useWindowSize();

    return (
        <Stack
            py={1}
            px={2}
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                borderTop: `1px solid`,
                borderColor: 'neutral.300',
                ...sharedStyles.getFixedContainerStyles(windowWidth),
                ...sharedStyles.glassmorphism,
            }}
            direction="row"
            justifyContent="flex-end"
        >
            {children}
        </Stack>
    );
}
