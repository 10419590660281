import { Divider, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import Frame from '../shared/Frame';
import ListaButton from '../shared/ListaButton';

export default function TrackingWidget({ shipment }) {
    return (
        <Frame>
            <Stack spacing={2}>
                <Typography variant="subtitle1">{shipment.title}</Typography>
                <Typography variant="body2">{shipment.subhead}</Typography>

                {shipment.has_tracking && (
                    <>
                        <a
                            href={shipment.tracking_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ListaButton variant="contained" fullWidth>
                                Track order
                            </ListaButton>
                        </a>

                        <Divider />
                        <Box>
                            <Typography variant="caption">
                                {shipment.carrier_name} tracking number:
                            </Typography>
                            <Typography variant="body2">
                                {shipment.tracking_number}
                            </Typography>
                        </Box>
                    </>
                )}
            </Stack>
        </Frame>
    );
}
