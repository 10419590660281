import { ThemeProvider, createTheme } from '@mui/material/styles';
import palette from './palette';
import components from './components';
import typography from './typography';

const theme = createTheme({
    palette: palette,
    typography: typography,
    components: components,
});

function ListaThemeProvider({ children }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ListaThemeProvider;
