const initialState = {
    user: {},

    post: {
        data: null,
        loaded: false,
    },
    lang: navigator.language || navigator.userLanguage,

    folders: {
        data: [],
        loaded: false,
    },

    team: {
        data: [],
        loaded: false,
    },

    products: {
        data: {},
        loaded: false,
    },

    cart: {
        data: {},
        loaded: false,
    },

    orders: {
        data: [],
        loaded: false,
    },

    scrollOffset: {
        tags: 0,
    },

    currentTab: 'TAGS',
    tagsGridContext: {
        grid: 'tags', // tags | folder
        folderId: null,
    },
};

export default initialState;
