import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageWrapModal from '../../components/layout/PageWrapModal';
import { useCart } from '../../contexts/CartContext';
import { useAppState } from '../../store/store';
import CartHeader from '../elements/cart/CartHeader';
import Login from '../elements/login/Login';
import Frame from '../elements/shared/Frame';
import ListaNumberInput from '../elements/shared/ListaNumberInput';
import { ReactComponent as TrashIcon } from '../../assets/icons/TrashIcon.svg';
import { debounce } from '../../lib/functions';
import CartActionBar from '../elements/cart/CartActionBar';
import ListaButton from '../elements/shared/ListaButton';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';

export default function CartPage() {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const {
        getCart,
        cart,
        loaded,
        loading,
        editCartItem,
        currentEditingItem,
        deleteCartItem,
        currentDeletingItem,
        claimCart,
    } = useCart();
    const { state } = useAppState();

    const [qtys, setQtys] = useState({});

    // if different cart is in app store, fetch the correct cart
    useEffect(() => {
        async function getCurrentCart() {
            await getCart({ uuid });
        }

        if (uuid != cart.uuid) {
            getCurrentCart();
        }
    }, []);

    useEffect(() => {
        if (cart.contents && cart.contents.items) {
            const mapped = {};
            cart.contents.items.forEach((item) => {
                mapped[item.id] = item.qty;
            });
            setQtys(mapped);
        }
    }, [cart]);

    const updateQty = (itemId, val) => {
        const newObj = { ...qtys };
        newObj[itemId] = val;

        editCartItemDebounced({ itemId, qty: val, uuid });
        setQtys(newObj);
    };

    const editCartItemDebounced = useCallback(debounce(editCartItem, 500), []);

    const handleNext = async () => {
        await claimCart({ uuid });
        navigate(`/checkout/${uuid}`);
    };

    return (
        <PageWrapModal>
            {!loaded ? (
                <CenteredCircularProgress />
            ) : (
                <Stack mt={10} spacing={2} px={2} pb={20}>
                    <CartHeader title="Checkout" />

                    {!state.user.token && (
                        <Frame>
                            <Login />
                        </Frame>
                    )}

                    {cart.contents &&
                        cart.contents.items &&
                        cart.contents.items.length > 0 && (
                            <Stack spacing={2}>
                                <Typography variant="h4">
                                    Order Summary
                                </Typography>
                                {cart.contents.items.map((item, index) => (
                                    <Frame condensed key={index}>
                                        <Stack spacing={1}>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                <Box
                                                    sx={{
                                                        width: 60,
                                                        height: 60,

                                                        '& img': {
                                                            display: 'block',
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            objectPosition:
                                                                'center center',
                                                        },
                                                    }}
                                                >
                                                    <img src={item.image} />
                                                </Box>
                                                <Stack>
                                                    <Typography variant="caption">
                                                        {item.summary.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="neutral.700"
                                                    >
                                                        {item.summary.subtitle}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Box>
                                                    <ListaNumberInput
                                                        value={qtys[item.id]}
                                                        onChange={(val) =>
                                                            updateQty(
                                                                item.id,
                                                                val
                                                            )
                                                        }
                                                    />
                                                </Box>
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Typography variant="caption">
                                                        {currentEditingItem ===
                                                        item.id ? (
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                        ) : (
                                                            item.price_formatted
                                                        )}
                                                    </Typography>
                                                    <IconButton
                                                        sx={{
                                                            color: 'secondary.main',
                                                        }}
                                                        onClick={() =>
                                                            deleteCartItem({
                                                                uuid,
                                                                itemId: item.id,
                                                            })
                                                        }
                                                        disabled={
                                                            currentDeletingItem ===
                                                            item.id
                                                        }
                                                    >
                                                        {currentDeletingItem ===
                                                        item.id ? (
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <TrashIcon
                                                                width="20"
                                                                height="20"
                                                            />
                                                        )}
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Frame>
                                ))}
                            </Stack>
                        )}
                    <Stack spacing={2}>
                        {cart.receipt_lines.map((row, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant={row.is_total ? 'caption' : 'body2'}
                                    color={
                                        row.is_total
                                            ? 'neutral.700'
                                            : 'neutral.500'
                                    }
                                >
                                    {row.description}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="neutral.800"
                                >
                                    {row.is_total && !!currentEditingItem ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        row.value
                                    )}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            )}
            <CartActionBar>
                <ListaButton
                    variant="contained"
                    disabled={!state.user.token}
                    // component={Link}
                    // to={`/checkout/${uuid}`}
                    onClick={handleNext}
                    loading={
                        loading || !!currentEditingItem || !!currentDeletingItem
                    }
                >
                    Continue
                </ListaButton>
                {/* <Link to="/shop">
                    <Button color="secondary" size="small">
                        back
                    </Button>
                </Link> */}
            </CartActionBar>
        </PageWrapModal>
    );
}
