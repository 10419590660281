import { Stack } from '@mui/material';
import { motion } from 'framer-motion';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import palette from '../../../theme/palette';
import sharedStyles from '../../../theme/sharedStyles';
import AppMenuItem from './AppMenuItem.js';
import { duration } from '../../../theme/motionConfig';
import { useCallback } from 'react';

export default function AppMenu({ setIsAppMenuShown }) {
    const [windowWidth] = useWindowSize();

    const closeAppMenu = useCallback(() => setIsAppMenuShown(false), []);

    return (
        <Stack
            direction="column"
            alignItems="flex-end"
            py={5.125}
            px={2}
            component={motion.div}
            initial={{
                paddingTop: '0px',
                height: '0px',
                paddingBottom: '0px',
            }}
            animate={{
                paddingTop: '70px',
                height: '290px',
                paddingBottom: '41px',
                transition: {
                    duration: duration.medium,
                },
            }}
            exit={{
                paddingTop: '0px',
                height: '0px',
                paddingBottom: '0px',
                transition: {
                    duration: duration.short,
                },
            }}
            sx={{
                width: '100%',
                position: 'fixed',
                top: 0,
                zIndex: -1,
                backgroundColor: palette.neutral[100],
                ...sharedStyles.getFixedContainerStyles(windowWidth),
            }}
        >
            <AppMenuItem
                text="Tags"
                link="/"
                onClick={closeAppMenu}
            />
            <AppMenuItem
                text="Shop"
                link="/shop"
                onClick={closeAppMenu}
            />
            <AppMenuItem
                text="Account"
                link="/account"
                onClick={closeAppMenu}
            />
            <AppMenuItem
                text="Order History"
                link="/orders"
                onClick={closeAppMenu}
            />
        </Stack>
    );
}
