import { useEffect, useState } from 'react';
import DataController from '../lib/DataController';
import { useAppState } from './store.js';
import { compareMixed } from '../lib/functions';
import useMemoCompare from '../lib/hooks/useMemoCompare';
import useIsWindowVisible from '../lib/hooks/useIsWindowVisible';

let cachedQueryId = 0;
let suppressLoadingState = false;

/**
 * Create unique query id
 *
 * @param query
 * @returns {string}
 */
const getQueryId = (query) => {
    return JSON.stringify(query);
};

/**
 * Use data hook
 *
 * @param queries array of 'query names', or ['query name', [params...]]
 * @param cbBefore callback before
 * @param cbAfter callback after
 * @param cbError callback error
 * @param runOnce run once, or always on re-render
 * @param setLoadingState set the isLoading state flag
 * @param pollingTimeout set a polling timeout for these queries
 * @param prevent prevent a call
 * @returns {*}
 */
const useAppData = ({
    queries,
    cbBefore,
    cbAfter,
    cbError,
    runOnce = true,
    setLoadingState = false,
    pollingTimeout,
    prevent = false,
}) => {
    const isWindowVisible = useIsWindowVisible();
    const [queriesDone, setQueriesDone] = useState({});
    const [localQueryId, setLocalQueryId] = useState(0);
    const [pollingTimeoutId, setPollingTimeoutId] = useState(0);
    const { dispatch, state, actions } = useAppState();

    const queriesCached = useMemoCompare(queries, (prevQueries) => {
        return prevQueries && queries && compareMixed(queries, prevQueries);
    });

    const supportedQueries = [
        // TODO: add all queries that should be supported by useAppData
        /*
            name: '', name used in the queries parameter when calling useData
            fetchFuncName: '', name/path of the promise in DataController - path example: "folders.get"
            onDone: (data, success, message, meta, queryParams) => {} processing when the query runs
            runOnceCondition: (state) => {} when true, the data has been fetched
         */

        /**
         * Get folders
         */
        {
            name: 'getFolders',
            fetchFuncName: 'folders.get',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_FOLDERS,
                        payload: data,
                    });
                }
            },
            runOnceCondition: (state) => state.folders && state.folders.loaded,
        },

        /**
         * Get team
         */
        {
            name: 'getTeam',
            fetchFuncName: 'team.get',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_TEAM,
                        payload: data,
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                console.log('[runOnceCondition]', state.team.data, queryParams);
                if (state.team && state.team.data && queryParams) {
                    if (state.team.data.post_id === queryParams.post_id) {
                        return true;
                    }
                }
                return false;
            },
        },

        /**
         * Get products
         */
        {
            name: 'getProducts',
            fetchFuncName: 'products.get',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_PRODUCTS,
                        payload: data,
                    });
                }
            },
            runOnceCondition: (state) =>
                state.products && state.products.loaded,
        },

        /**
         * Get post by hashId
         */
        {
            name: 'getPost',
            fetchFuncName: 'post.get',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_POST,
                        payload: data,
                    });
                }
            },
            runOnceCondition: (state, queryParams) => {
                console.log('[runOnceCondition]', state.post.data, queryParams);
                if (state.post && state.post.data && queryParams) {
                    if (state.post.data.hash === queryParams.hashId) {
                        return true;
                    }
                }
                return false;
            },
        },

        /**
         * Get orders
         */
        {
            name: 'getOrders',
            fetchFuncName: 'orders.get',
            onDone: (data, success, message) => {
                if (success) {
                    dispatch({
                        type: actions.SET_ORDERS,
                        payload: data,
                    });
                }
            },
            runOnceCondition: (state) => state.orders && state.orders.loaded,
        },
    ];

    useEffect(() => {
        if (prevent) {
            return;
        }

        let didCancel = false;

        suppressLoadingState = false;
        if (cachedQueryId !== localQueryId) {
            cachedQueryId = localQueryId;
            suppressLoadingState = true;
        }

        setQueriesDone({});

        if (typeof cbBefore === 'function') cbBefore();
        if (setLoadingState && !suppressLoadingState) {
            dispatch({
                type: actions.SET_IS_LOADING,
                payload: true,
            });
        }

        console.log('[useData] re-rendering');

        if (queriesCached && queriesCached.length > 0) {
            for (let i = 0; i < queriesCached.length; i++) {
                let queryName = queriesCached[i];
                let queryParams = [];
                if (Array.isArray(queriesCached[i])) {
                    queryName = queriesCached[i][0];
                    queryParams = queriesCached[i][1]; // comes in as object
                }
                const queryId = getQueryId(queriesCached[i]);

                const setDone = () => {
                    queriesDone[queryId] = true;
                    setQueriesDone({ ...queriesDone });
                };

                const supportedQuery = supportedQueries.find(
                    (query) => query.name === queryName
                );
                if (!supportedQuery) {
                    setDone();
                    console.error('[useData] query not found', queryName);
                    continue;
                }

                if (
                    runOnce &&
                    typeof supportedQuery.runOnceCondition === 'function' &&
                    supportedQuery.runOnceCondition(state, queryParams)
                ) {
                    setDone();
                    console.log(
                        '[useData] run once condition is true',
                        queryName
                    );
                    continue;
                }

                const fetchFunctionPath =
                    supportedQuery.fetchFuncName.split('.');
                let fetchFunction = DataController;

                for (let i = 0; i < fetchFunctionPath.length; i++) {
                    fetchFunction = fetchFunction[fetchFunctionPath[i]];
                }

                fetchFunction(queryParams).then(
                    ({ data, success, message, meta }) => {
                        if (didCancel) return;
                        console.log(
                            '[useData] DataController',
                            supportedQuery.fetchFuncName,
                            data,
                            queriesDone
                        );
                        setDone();
                        if (!success && typeof cbError === 'function') {
                            cbError({
                                data,
                                success,
                                message,
                                query: supportedQuery.name,
                            });
                        }
                        if (typeof supportedQuery.onDone === 'function') {
                            supportedQuery.onDone(
                                data,
                                success,
                                message,
                                meta,
                                queryParams
                            );
                        }
                    }
                );
            }
        }

        return () => {
            didCancel = true;
        };
    }, [queriesCached, state.queriesId, localQueryId]);

    // clear polling timeout
    useEffect(() => {
        return () => {
            if (pollingTimeoutId) window.clearTimeout(pollingTimeoutId);
        };
    }, []);

    useEffect(() => {
        if (pollingTimeout && !isNaN(pollingTimeout) && !pollingTimeoutId) {
            if (!isWindowVisible) {
                console.log('[useData] polling stopped');
                return;
            }
            const newTimeoutId = window.setTimeout(() => {
                setQueriesDone({});
                setLocalQueryId(localQueryId + 1);
                setPollingTimeoutId(0);
            }, pollingTimeout);
            console.log('[useData] polling starting', newTimeoutId);
            setPollingTimeoutId(newTimeoutId);
        }
    }, [isWindowVisible, queriesDone]);

    // check if all queries done
    useEffect(() => {
        let allDone = true;
        for (let i = 0; i < queriesCached.length; i++) {
            const queryId = getQueryId(queriesCached[i]);
            if (!queriesDone[queryId]) {
                allDone = false;
                break;
            }
        }

        if (allDone) {
            console.log('[useData] queries complete', queriesDone);
            if (typeof cbAfter === 'function') cbAfter();
            if (setLoadingState && !suppressLoadingState) {
                dispatch({
                    type: actions.SET_IS_LOADING,
                    payload: false,
                });
            }
        }
    }, [queriesDone, queriesCached.length]);

    return state;
};

export default useAppData;
