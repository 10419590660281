import { Stack, Box, Button, Avatar, Typography } from '@mui/material';
import React, { useState } from 'react';
import ImageStack from '../shared/ImageStack';
import Lightbox from '../shared/Lightbox';
import Thumbnail from '../shared/Thumbnail';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMediaThumb } from '../../../lib/functions';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import palette from '../../../theme/palette';

export default function Gallery({
    media,
    contact,
    updatedOn,
    showMeta = true,
}) {
    // TODO:
    // ultimately, for reusability, this component should not handle post media array
    // should just accept cover src and an array of stack image src's
    // probably as an object - to get both thumb and actual media obj to display in lightbox ->
    // [ { thumb: <url>, url: <url> }, ... ]

    const cover = media.find((item) => item.is_cover);
    const other = media.filter((item) => !item.is_cover);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [startAt, setStartAt] = useState(0);

    const handleOpenLightbox = (index) => {
        setStartAt(index);
        setLightboxOpen(true);
    };

    return (
        <>
            <Stack spacing={2} sx={{ mt: '16px !important' }}>
                {cover && (
                    <Box>
                        <Box
                            sx={{
                                paddingBottom: '100%',
                                position: 'relative',
                            }}
                        >
                            <Button
                                fullWidth
                                sx={{
                                    p: 0,
                                    display: 'block',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                }}
                                onClick={() => handleOpenLightbox(0)}
                            >
                                {cover.is_video &&
                                    <Box component={PlayIcon} sx={{
                                        position: 'relative',
                                        zIndex: 2,
                                        fill: palette.neutral[100],
                                        width: 50,
                                        height: 50,
                                    }} />
                                }
                                <img
                                    src={getMediaThumb(cover)}
                                    alt={cover.caption}
                                    style={{
                                        display: 'block',
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                />

                                {contact && showMeta && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            width: '100%',
                                            bottom: 0,
                                            left: 0,
                                            background:
                                                'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.72) 100%);',
                                            p: 2,
                                            pt: 6,
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <Box>
                                                <Avatar
                                                    src={contact.avatar}
                                                    alt={contact.name}
                                                />
                                            </Box>
                                            <Stack>
                                                <Typography
                                                    variant="caption"
                                                    color="neutral.100"
                                                >
                                                    By {contact.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="neutral.300"
                                                >
                                                    Updated {updatedOn}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )}
                            </Button>
                        </Box>
                    </Box>
                )}

                <ImageStack>
                    {other.map((item, index) => (
                        <Button
                            key={item.id}
                            sx={{ flexShrink: 0, p: 0, color: 'inherit' }}
                            onClick={() =>
                                handleOpenLightbox(cover ? index + 1 : index)
                            } // needs index + 1 cause lightbox will start with cover, and concat other
                        >
                            <Thumbnail
                                src={getMediaThumb(item)}
                                caption={item.caption}
                            />
                            {item.is_video &&
                                <Box component={PlayIcon} sx={{
                                    position: 'absolute',
                                    zIndex: 2,
                                    fill: palette.neutral[100],
                                    width: 30,
                                    height: 30,
                                }} />
                            }
                        </Button>
                    ))}
                </ImageStack>
            </Stack>

            <Lightbox
                media={media}
                open={lightboxOpen}
                onClose={() => setLightboxOpen(false)}
                startAt={startAt}
            />
        </>
    );
}
