import React from 'react';
import { Box } from '@mui/material';

export default function LocationMap({ mapUrl }) {
    return (
        <Box
            sx={{
                border: '2px solid',
                borderColor: 'neutral.main',
                borderRadius: 2,
                overflow: 'hidden',
                width: '100%',
                maxWidth: 390,
                margin: '0 auto',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    paddingBottom: '100%',
                    position: 'relative',

                    '& img': {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                    },
                }}
            >
                <img src={mapUrl} />
            </Box>
        </Box>
    );
}
