import React, { useState } from 'react';
import DataController from '../../../lib/DataController';
import { getPostPinFromPersistentStorage } from '../../../lib/functions';
import { useAppState } from '../../../store/store';
import ListaSwitch from '../shared/ListaSwitch';

export default function TranslateToLangSwitch() {
    const { state, dispatch, actions } = useAppState();
    const post = state.post.data;

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const checked = e.target.checked;

        const pin = getPostPinFromPersistentStorage(post.hash);

        if (checked) {
            setLoading(true);
            // TODO: Test this on private post
            //      - check the mechanism for getting the post with/without pin
            //      - check the mechanism for storing post pin
            DataController.post
                .get({
                    hashId: post.hash,
                    lang: post.lang_switch.value,
                    pin,
                })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        dispatch({
                            type: actions.SET_POST,
                            payload: data,
                        });
                        dispatch({
                            type: actions.SET_LANG,
                            payload: post.lang_switch.value,
                        });
                    }
                })
                .finally(() => setLoading(false));
        } else {
            DataController.post
                .get({ hashId: post.hash, lang: post.lang_source, pin })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        dispatch({
                            type: actions.SET_POST,
                            payload: data,
                        });
                        dispatch({
                            type: actions.SET_LANG,
                            payload: post.lang_source,
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <ListaSwitch
            label={post.lang_switch?.label || ''}
            checked={post.lang_switch?.checked}
            onChange={handleChange}
            loading={loading}
        />
    );
}
