import palette from '../palette';

const MuiAvatar = {
    styleOverrides: {
        root: {
            width: 57,
            height: 57,
            border: '3px solid',
            borderColor: palette.neutral[100],
        },
    },
};

export default MuiAvatar;
