import React, { useEffect, useRef, useState } from 'react';
import PageWrapModal from '../../layout/PageWrapModal';
import {
    Box,
    Typography,
    Stack,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Button,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppState } from '../../../store/store';
import Header from '../../elements/post/Header';
import AnimatedListaTag from '../../elements/post/AnimatedListaTag';
import AddImages from '../../elements/post/AddImages';
import AddDocuments from '../../elements/post/AddDocuments';
import Footer from '../../elements/post/Footer';
import { motion } from 'framer-motion';
import { duration, easing } from '../../../theme/motionConfig';
import { ReactComponent as ClearFieldIcon } from '../../../assets/icons/ClearFieldIcon.svg';
import DataController from '../../../lib/DataController';
import RichTextField from '../../elements/shared/RichTextField';
import { useSnackbar } from 'notistack';
import FolderPicker from '../../elements/post/FolderPicker';
import TeamPicker from '../../elements/post/TeamPicker';
import PrivatePostSwitch from '../../elements/post/PrivatePostSwitch';
import PostLocationSwitch from '../../elements/post/PostLocationSwitch';
import { useTranslation } from 'react-i18next';
import useAppData from '../../../store/useAppData';
import ListaSwitch from '../../elements/shared/ListaSwitch';
import CenteredCircularProgress from '../../elements/shared/CenteredCircularProgress';
import { useTags } from '../../../contexts/TagsContext';
import DeletePostButton from '../../elements/post/DeletePostButton';
import AddLinks from '../../elements/post/AddLinks';

export default function EditPost() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { hashId } = useParams();
    const { refetchSummary } = useTags();

    useAppData({
        queries: [['getPost', { hashId }]],
    });
    const { state, dispatch, actions } = useAppState();
    const post = state.post.data;
    const loaded = state.post.loaded;

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [preventSaving, setPreventSaving] = useState(false);

    const titleInputRef = useRef();

    useEffect(() => {
        if (titleInputRef.current && loaded) {
            if (!state.post.data.is_editable) {
                navigate(`/post/${hashId}`);
            }

            // set initial field values on post load
            setTitle(state.post.data.title);
            setBody(state.post.data.body);

            if (post.status === 'draft') {
                titleInputRef.current.focus();
                titleInputRef.current.setSelectionRange(0, 9999);
            } else {
                // titleInputRef.current.focus();
            }

            // this prevents the page from autoscrolling on title input autofocus
            setTimeout(() => {
                window.scroll(0, 0);
            }, 50);
        }
    }, [titleInputRef.current, loaded]);

    // TODO - extract motion stuff for reusability
    // idea: check how to pass all as a bunch of props to stack el.
    const motionContainer = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const motionItem = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: duration.long,
                ease: easing.decelerate,
            },
        },
    };

    const updatePost = async (changes) => {
        if (!preventSaving) {
            setLoading(true);

            return DataController.post
                .edit({ hashId, data: changes })
                .then(({ success, data, errors, message }) => {
                    if (success) {
                        console.log('[post.edit] updated data: ', data);
                        dispatch({
                            type: actions.SET_POST,
                            payload: data,
                        });
                        enqueueSnackbar(message);
                    } else {
                        console.error('[post.edit] error: ', message);
                    }
                    setLoading(false);
                });
        }
    };

    const onBlur = (field) => {
        if (field === 'title') {
            if (title != state.post.data.title) {
                return updatePost({ title });
            }
        }

        if (field === 'body') {
            if (body != state.post.data.body) {
                return updatePost({ body });
            }
        }

        return Promise.resolve();
    };

    const publishPost = async () => {
        setLoading(true);
        DataController.post
            .publish({ hashId })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    console.log(
                        '[publishPost] post published successfully. Data: ',
                        data
                    );
                    navigate(`/post/${hashId}`);
                } else {
                    console.error('[publishPost] error: ', message);
                }
                setLoading(false);
            });
    };

    const gotoViewPost = async () => {
        // first push any changes in fields if blur event didn't trigger - ie. user saves post with field still focused
        await onBlur('title');
        await onBlur('body');
        await publishPost();
    };

    const clearTitle = () => {
        setPreventSaving(true);
        setTitle('');
        titleInputRef.current.focus();
        setPreventSaving(false);
    };

    useEffect(() => {
        return () => {
            refetchSummary(hashId);
        };
    }, []);

    return (
        <>
            {!state.post.loaded ? (
                <CenteredCircularProgress />
            ) : (
                <PageWrapModal>
                    <Header
                        title={
                            post.status == 'draft'
                                ? [
                                      t('new'),
                                      post.tag?.sku.colorway,
                                      t('tag'),
                                  ].join(' ')
                                : t('editTag')
                        }
                        colorway={post.tag?.sku.colorway || ''}
                        cssColor={post.tag?.css_color || ''}
                    />

                    <Stack
                        spacing={7}
                        p={2}
                        mt={6}
                        mb={10}
                        component={motion.div}
                        variants={motionContainer}
                        initial="hidden"
                        animate="show"
                    >
                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <FormControl variant="outlined">
                                <OutlinedInput
                                    sx={{
                                        fontSize: '1.2em',
                                        height: '56px',
                                    }}
                                    placeholder={t('editPostTitlePlaceholder')}
                                    inputRef={titleInputRef}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    onBlur={() => onBlur('title')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={clearTitle}
                                            >
                                                <ClearFieldIcon
                                                    width="24"
                                                    height="24"
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Stack>

                        {/* marginTop fix needed because AnimatedListaTag has spacing around it */}
                        {/* AnimatedListaTag must have spacing within to be able to cut overflow created by 3D transform environment */}
                        <Box sx={{ marginTop: '0 !important' }}>
                            <AnimatedListaTag
                                colorway={post.tag?.sku.colorway}
                            />
                        </Box>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                            sx={{ marginTop: '0 !important' }}
                        >
                            <Typography variant="h4">
                                {t('postGallery')}
                            </Typography>
                            <AddImages post={post} />
                        </Stack>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <Typography variant="h4">
                                {t('postDocuments')}
                            </Typography>
                            <AddDocuments />
                        </Stack>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <Typography variant="h4">
                                {t('postLinks')}
                            </Typography>
                            <AddLinks />
                        </Stack>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <Typography variant="h4">
                                {t('postDescription')}
                            </Typography>

                            <RichTextField
                                value={body}
                                onChange={(val) => setBody(val)}
                                onBlur={() => onBlur('body')}
                            />
                        </Stack>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <Typography variant="h4">
                                {t('editPostAddToFolder')}
                            </Typography>
                            <FolderPicker
                                onChange={(data) => updatePost(data)}
                            />
                        </Stack>

                        {post.is_manager ? (
                            <Stack
                                spacing={2}
                                component={motion.div}
                                variants={motionItem}
                            >
                                <Typography variant="h4">
                                    {t('teamPickerTitle')}
                                </Typography>
                                <TeamPicker
                                    onChange={(data) => updatePost(data)}
                                />
                            </Stack>
                        ) : (
                            ''
                        )}

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <PostLocationSwitch
                                checked={!!post.is_geocoded}
                                mapUrl={post.map_url || null}
                                onChange={(data) => updatePost(data)}
                            />
                        </Stack>

                        <Stack
                            spacing={2}
                            component={motion.div}
                            variants={motionItem}
                        >
                            <Typography variant="h4">
                                {t('editPostSettings')}
                            </Typography>
                            <Stack spacing={0}>
                                <PrivatePostSwitch
                                    post={post}
                                    checked={!!post.is_private}
                                    onChange={(data) => updatePost(data)}
                                />
                                <ListaSwitch
                                    checked={!!post.is_translated}
                                    onChange={(e) =>
                                        updatePost({
                                            is_translated: e.target.checked
                                                ? 1
                                                : 0,
                                        })
                                    }
                                    label={t(
                                        'editPostAutomaticTranslationSwitchLabel'
                                    )}
                                />
                            </Stack>
                        </Stack>

                        <Stack>
                            <DeletePostButton post={post} />
                        </Stack>
                    </Stack>

                    <Footer>
                        <Button
                            variant="containedFullWidth"
                            disabled={loading}
                            onMouseDown={gotoViewPost} // need to listen to these events so they fire before the onBlur event
                            onTouchStart={gotoViewPost}
                        >
                            {post.status === 'draft'
                                ? t('publishTag')
                                : t('save')}
                        </Button>
                    </Footer>
                </PageWrapModal>
            )}
        </>
    );
}
