import React from 'react';
import { Typography } from '@mui/material';
import MoreMenu from './MoreMenu';
import { useAppState } from '../../../store/store';
import DataController from '../../../lib/DataController';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';

export default function PaymentMethodMoreMenu({ paymentmethod }) {
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const { setConfirmationPrompt } = useConfirmationPrompt();

    const [loading, setLoading] = useState(false);

    const onMakeDefault = () => {
        setLoading(true);
        DataController.paymentmethods
            .makeDefault({ id: paymentmethod.id })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({
                        type: actions.UPDATE_DEFAULT_PAYMENTMETHOD,
                        payload: data,
                    });
                    enqueueSnackbar(message);
                } else {
                    console.error(message);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            })
            .finally(() => setLoading(false));
    };

    const onDelete = () => {
        setConfirmationPrompt({
            title: 'Are you sure?',
            body: "This action can't be undone.",
            confirmText: 'Yes, delete',
        })
            .then(() => {
                setLoading(true);

                DataController.paymentmethods
                    .delete({ id: paymentmethod.id })
                    .then(({ success, data, errors, message }) => {
                        if (success) {
                            dispatch({
                                type: actions.DELETE_PAYMENTMETHOD,
                                payload: paymentmethod.id,
                            });
                            enqueueSnackbar(message);
                        } else {
                            console.error(message);
                            enqueueSnackbar(message, {
                                variant: 'error',
                            });
                        }
                    })
                    .finally(() => setLoading(false));
            })
            .catch(() => {
                console.log('canceled');
                setLoading(false);
            });
    };

    const options = [
        {
            label: 'Make default',
            disabled: !!paymentmethod.is_default,
            onClick: onMakeDefault,
        },
        {
            label: <Typography color="error">Delete</Typography>,
            onClick: onDelete,
        },
    ];

    return <MoreMenu options={options} loading={loading} />;
}
