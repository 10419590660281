import { Box, Button, CircularProgress, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../../store/store';
import Login from '../elements/login/Login';
import PageWrapDefault from '../layout/PageWrapModal';
import DataController from '../../lib/DataController';
import ListaButton from '../elements/shared/ListaButton';
import ListaSwitch from '../elements/shared/ListaSwitch';
import PinInput from '../elements/shared/PinInput';
import AnimatedListaTag from '../elements/post/AnimatedListaTag';
import AddressPicker from '../elements/shared/AddressPicker';

export default function TestPage() {
    const { state, dispatch, actions } = useAppState();

    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch({ type: actions.SET_USER, payload: { name: 'John' } });
    //     }, 3000);
    // }, []);

    const fetchTestLang = () => {
        DataController.fetchTestLang().then(
            ({ success, data, errors, message }) => {
                if (success) {
                    console.log(data);
                } else {
                    console.error(message);
                }
            }
        );
    };

    const [checked, setChecked] = useState(false);

    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');

    const onPinUpdate = (pin, value) => {
        if (pin === 1) setPin1(value);
        if (pin === 2) setPin2(value);
    };

    const [selectedAddress, setSelectedAddress] = useState(1);

    return (
        <PageWrapDefault>
            <Box p={2}>
                <AddressPicker
                    selectedAddressId={selectedAddress}
                    onChange={setSelectedAddress}
                />
            </Box>
            <Box p={2}>
                <AnimatedListaTag colorway={'Signal Red'} />
            </Box>
            <Box>
                <Login />
            </Box>

            <Box p={2}>
                <PinInput
                    value={pin1}
                    onChange={(e) => onPinUpdate(1, e.target.value)}
                />
            </Box>
            <Box p={2}>
                <PinInput
                    value={pin2}
                    onChange={(e) => onPinUpdate(2, e.target.value)}
                    length={4}
                />
            </Box>

            <Box p={2}>
                <ListaButton
                    variant="contained"
                    loading={true}
                    fullWidth
                    // color="secondary"
                >
                    Text
                </ListaButton>
            </Box>

            <Box p={2}>
                <CircularProgress />
            </Box>

            <Box p={2}>
                <ListaSwitch
                    loading={true}
                    label="Some text"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
                <ListaSwitch label="Some text" disabled />
            </Box>

            <LinearProgress
                variant="determinate"
                value={45}
                color="signalRed"
            />

            <Box>
                <Button onClick={fetchTestLang}>Fetch test lang</Button>
            </Box>
        </PageWrapDefault>
    );
}
