import {
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import Frame from './Frame';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/ArrowDownIcon.svg';
import { useState } from 'react';
import { useAppState } from '../../../store/store';
import { Box, Button } from '@mui/material';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/CheckboxIcon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/CheckboxCheckedIcon.svg';
import { motion } from 'framer-motion';
import { scrollIntoView } from '../../../lib/functions';
import StripeForm from './StripeForm';
import StripeProvider from './StripeProvider';
import { ReactComponent as CardFlagAmex } from '../../../assets/graphics/CardFlagAmex.svg';
import { ReactComponent as CardFlagMaster } from '../../../assets/graphics/CardFlagMaster.svg';
import { ReactComponent as CardFlagVisa } from '../../../assets/graphics/CardFlagVisa.svg';
import DataController from '../../../lib/DataController';
import { useCart } from '../../../contexts/CartContext';
import ListaAccordion from './ListaAccordion';
import PaymentMethodMoreMenu from './PaymentMethodMoreMenu';
import { useSnackbar } from 'notistack';

export default function PaymentPicker({
    selectedPaymentMethodId,
    onChange,
    title = 'Payment',
    mode = 'checkout', // checkout | account
}) {
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const { cart } = useCart();
    const [expanded, setExpanded] = useState(
        mode === 'checkout' ? !selectedPaymentMethodId : false
    );
    const [settingPayment, setSettingPayment] = useState(false);
    const [displayForm, setDisplayForm] = useState(
        state.user.payment_methods.length === 0
    );
    const dividerRef = useRef();

    const selectedPaymentMethod = state.user.payment_methods.find(
        (item) => item.id === selectedPaymentMethodId
    );

    const toggle = () => {
        if (expanded) {
            setDisplayForm(false);
        }

        setExpanded(!expanded);
    };

    const handleChange = async (id) => {
        if (id === selectedPaymentMethodId) {
            return;
        }
        setSettingPayment(id);
        await onChange(id);
        setSettingPayment(false);
        toggle();
    };

    const hideForm = () => {
        scrollIntoView(dividerRef.current);
        setDisplayForm(false);
    };

    const createCartPaymentMethod = async (token) => {
        console.log('[createCartPaymentMethod] token: ', token);

        DataController.checkout
            .setPayment({ uuid: cart.uuid, token })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.SET_CART, payload: data });
                    dispatch({ type: actions.SET_USER, payload: data.contact });
                    toggle();
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const createPaymentMethod = async (token) => {
        console.log('[createPaymentMethod] token: ', token);

        DataController.paymentmethods
            .createCard({ token })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    console.log(data);
                    // dispatch({ type: actions.SET_USER, payload: data.contact });
                    dispatch({
                        type: actions.ADD_PAYMENTMETHOD,
                        payload: data,
                    });
                    hideForm();
                } else {
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    const PaymentMethodItem = ({ paymentmethod }) => {
        const isSelected = paymentmethod.id === selectedPaymentMethodId;

        return (
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    textAlign: 'left',
                    mt: 2,
                    minHeight: 36,
                    width: '100%',
                }}
            >
                <Stack direction="row" spacing={1}>
                    <Typography variant="caption">
                        {paymentmethod.brand}
                    </Typography>
                    <Typography variant="caption">
                        {paymentmethod.number}
                    </Typography>
                </Stack>

                {mode === 'checkout' && (
                    <IconButton
                        sx={{
                            minWidth: 32,
                            position: 'relative',
                            right: -2,
                        }}
                        onClick={() => handleChange(paymentmethod.id)}
                    >
                        {settingPayment === paymentmethod.id ? (
                            <>
                                <CircularProgress size={20} />
                            </>
                        ) : (
                            <>
                                {isSelected ? (
                                    <CheckboxCheckedIcon />
                                ) : (
                                    <CheckboxIcon />
                                )}
                            </>
                        )}
                    </IconButton>
                )}

                {mode === 'account' && (
                    <PaymentMethodMoreMenu paymentmethod={paymentmethod} />
                )}
            </Stack>
        );
    };

    return (
        <ListaAccordion
            title={title}
            onToggle={toggle}
            expanded={expanded}
            contentExpanded={
                <>
                    {state.user.payment_methods.map((paymentmethod) => (
                        <PaymentMethodItem
                            key={paymentmethod.id}
                            paymentmethod={paymentmethod}
                        />
                    ))}
                    <Box>
                        <Divider sx={{ mt: 2 }} ref={dividerRef} />
                        <Box
                            key="addbutton"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 0 : 'auto',
                                opacity: displayForm ? 0 : 1,
                                marginBottom: displayForm ? 0 : -16,
                            }}
                            sx={{ overflow: 'hidden' }}
                        >
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => setDisplayForm(true)}
                            >
                                + Add card
                            </Button>
                        </Box>
                        <Stack
                            spacing={2}
                            key="form"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 'auto' : 0,
                                opacity: displayForm ? 1 : 0,
                                marginTop: displayForm ? 16 : 0,
                            }}
                            sx={{ overflow: 'hidden', height: 0 }}
                        >
                            <Typography variant="body2" color="neutral.700">
                                All transactions are secure and encypted
                            </Typography>

                            <Stack direction="row" spacing={1}>
                                <CardFlagVisa />
                                <CardFlagMaster />
                                <CardFlagAmex />
                            </Stack>
                            <StripeProvider>
                                <StripeForm
                                    onCancel={hideForm}
                                    onGetTokenSuccess={
                                        mode === 'checkout'
                                            ? createCartPaymentMethod
                                            : createPaymentMethod
                                    }
                                />
                            </StripeProvider>
                        </Stack>
                    </Box>
                </>
            }
            contentClosed={
                selectedPaymentMethod && (
                    <PaymentMethodItem paymentmethod={selectedPaymentMethod} />
                )
            }
        />
    );
}
