import React from 'react';
import { useState } from 'react';
import { Divider, Stack } from '@mui/material';
import ListaAccordion from '../shared/ListaAccordion';
import OrderSummary from './OrderSummary';
import OrderCustomerInfo from './OrderCustomerInfo';

export default function OrderSummaryAccordion({
    order,
    title = null,
    expandedInitial = false,
}) {
    const [expanded, setExpanded] = useState(expandedInitial);

    return (
        <ListaAccordion
            title={title ? title : `Order ${order.order_number}`}
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
            contentExpanded={
                <Stack spacing={2} pt={2}>
                    <OrderSummary order={order} />
                    <Divider />
                    <OrderCustomerInfo order={order} />
                </Stack>
            }
        />
    );
}
