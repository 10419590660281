import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CartHeader from '../elements/cart/CartHeader';
import { useParams } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import PageWrapModal from '../layout/PageWrapModal';
import SuccessWidget from '../elements/tracking/SuccessWidget';
import TrackingWidget from '../elements/tracking/TrackingWidget';
import OrderSummaryAccordion from '../elements/order/OrderSummaryAccordion';
import { ReactComponent as CartIcon } from '../../assets/icons/CartIcon.svg';
import CenteredCircularProgress from '../elements/shared/CenteredCircularProgress';

export default function TrackPage() {
    const { uuid } = useParams();
    const { cart, getCart, loaded, clearCartData } = useCart();

    useEffect(() => {
        async function getCurrentCart() {
            await getCart({ uuid });
        }

        if (uuid != cart.uuid) {
            getCurrentCart();
        }

        return () => {
            clearCartData();
        };
    }, []);

    return (
        <PageWrapModal>
            {!loaded ? (
                <CenteredCircularProgress />
            ) : (
                <>
                    <CartHeader title="Checkout" />
                    <Stack spacing={2} mt={10} mb={8} p={2}>
                        <SuccessWidget
                            orderNumber={cart.order_number}
                            firstname={cart.contact.firstname}
                        />

                        <TrackingWidget shipment={cart.shipment} />

                        <OrderSummaryAccordion
                            order={cart}
                            expandedInitial={true}
                            title={
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    component="button"
                                    flex={1}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <CartIcon />
                                        <Typography variant="bodyMid">
                                            Show order summary
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Typography variant="subtitle1">
                                            {cart.total_formatted}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }
                        />
                    </Stack>
                </>
            )}
        </PageWrapModal>
    );
}
