import { connectHits, Highlight } from 'react-instantsearch-dom';
import { Box, Card, darken, Divider, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useAppState } from '../../../store/store';
import { useTranslation } from 'react-i18next';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import pt from 'javascript-time-ago/locale/pt';

TimeAgo.addLocale(es);
TimeAgo.addLocale(pt);
TimeAgo.addDefaultLocale(en);

const Hits = ({ hits }) => {
    const { state } = useAppState();
    console.log('HITS: ', hits);

    const { t } = useTranslation();

    const timeAgo = new TimeAgo(state.lang);

    const generateHitSubhead = (hit) => {
        const isOwner = state.user.id === hit.author_id;
        const colorway = hit.post_colorway;
        let author = hit.author_name;

        if (author && state.lang.slice(0, 2) === 'en') {
            // check for the first two since it could be en-US en-UK
            const lastLetter = author.slice(-1);
            if (lastLetter.toLowerCase() != 's') {
                author = `${author}'s`;
            } else {
                author = `${author}'`;
            }
        }

        // console.log(author);

        if (hit.type === 'post') {
            if (isOwner) {
                return t('searchBoxYourColorTag', {
                    colorway,
                });
            } else {
                if (author) {
                    return t('searchBoxAuthorColorTag', {
                        colorway,
                        author,
                    });
                } else {
                    return t('searchBoxNullAuthorColorTag', {
                        colorway,
                    });
                }
            }
        } else {
            // hit.type === link | media
            // media has media_type to differentiate diff media types - pdf, video, image, ...
            // for link, we'll use link as media type and reuse the same translation key

            let mediaType;
            if (hit.type === 'link') {
                mediaType = t('searchBoxMediaTypeLink');
            } else {
                // hit.type === media
                mediaType = t(`searchBoxMediaType${hit.media_type}`);
            }

            if (isOwner) {
                return t('searchBoxMediaTypeOnYourColorTag', {
                    mediaType,
                    colorway,
                });
            } else {
                if (author) {
                    return t('searchBoxMediaTypeOnAuthorColorTag', {
                        mediaType,
                        author,
                    });
                } else {
                    return t('searchBoxMediaTypeOnNullAuthorColorTag', {
                        mediaType,
                        colorway,
                    });
                }
            }
        }
    };

    return (
        <Stack>
            <ul>
                <AnimatePresence>
                    {hits.map((hit) => {
                        const lastScanned =
                            hit.type === 'post'
                                ? timeAgo.format(hit.lastscan_ts * 1000)
                                : null;

                        const subhead = generateHitSubhead(hit);

                        return (
                            <motion.li
                                layout
                                key={hit.objectID}
                                style={{
                                    display: 'block',
                                    overflow: 'hidden',
                                }}
                                init={{
                                    height: 0,
                                }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}
                            >
                                <Card elevation={0}>
                                    <Link to={`/post/${hit.post_hash}`}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                            m={1}
                                        >
                                            <Box
                                                sx={{
                                                    width: 60,
                                                    height: 60,
                                                    position: 'relative',
                                                    overflow: 'hidden',

                                                    '& img': {
                                                        display: 'block',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectPosition:
                                                            'center',
                                                        objectFit: 'cover',
                                                    },
                                                }}
                                            >
                                                <img src={hit.thumbnail_url} />
                                            </Box>
                                            <Stack sx={{ flex: 1 }}>
                                                <Box
                                                    sx={{
                                                        '& .ais-Highlight-highlighted':
                                                            {
                                                                fontWeight: 700,
                                                            },
                                                    }}
                                                >
                                                    <Highlight
                                                        attribute="text_en"
                                                        hit={hit}
                                                        tagName="mark"
                                                    />
                                                </Box>
                                                <Stack
                                                    mt={0.5}
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'block',
                                                            width: 13,
                                                            height: 17,
                                                            borderRadius: '2px',
                                                            backgroundColor:
                                                                hit.post_color,
                                                            border: '1px solid',
                                                            borderColor: darken(
                                                                hit.post_color,
                                                                0.1
                                                            ),
                                                            left: -3,
                                                            rotate: '-6deg',
                                                            top: 1,
                                                        }}
                                                    ></Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="neutral"
                                                    >
                                                        {subhead}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            {hit.type === 'post' && (
                                                <Box sx={{ flexShrink: 0 }}>
                                                    <Typography
                                                        variant="body2"
                                                        color="neutral"
                                                        textAlign="right"
                                                    >
                                                        {t(
                                                            'searchBoxLastScanned'
                                                        )}
                                                        <br />
                                                        {lastScanned}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Stack>
                                    </Link>
                                    <Divider />
                                </Card>
                            </motion.li>
                        );
                    })}
                </AnimatePresence>
            </ul>
        </Stack>
    );
};

export const CustomHits = connectHits(Hits);
