import React from 'react';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import {
    algoliaApiKey,
    algoliaApplicationId,
    algoliaIndexName,
} from './config';
import { useAppState } from '../store/store';

const searchClient = algoliasearch(algoliaApplicationId, algoliaApiKey);

export default function AlgoliaProvider({ children }) {
    const { state } = useAppState();

    const userId = state.user?.id ?? null;
    if (!userId) return <>{children}</>;

    const configureProps = {
        filters: `uids:${userId}`,
        hitsPerPage: 10,
        // restrictSearchableAttributes: ['text_en'],
    };

    // const lang = state.lang;
    // if (lang != 'en') {
    //     configureProps.restrictSearchableAttributes.push(`text_${lang}`);
    // }

    return (
        <InstantSearch searchClient={searchClient} indexName={algoliaIndexName}>
            <Configure {...configureProps} />
            {children}
        </InstantSearch>
    );
}
