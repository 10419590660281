import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { darken } from '@mui/material';
import { motion } from 'framer-motion';
import palette from '../../../theme/palette';

export default function ColorOptionsWidget({ colors, onChange, selected }) {
    return (
        <Box>
            <Typography
                variant="body2"
                color="neutral.500"
                sx={{ lineHeight: '1em' }}
            >
                Color
            </Typography>
            <Typography variant="caption">{selected.title}</Typography>

            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                mt={2}
                spacing={2}
                sx={{
                    '& :nth-of-type(3n+1)': {
                        ml: 0,
                    },
                }}
            >
                {colors.map((color, index) => {
                    const isSelected = selected.title === color.title;
                    const textColor =
                        color.title === 'Pearl White'
                            ? palette.neutral[800]
                            : palette.neutral[100];

                    return (
                        <Box
                            key={index}
                            component="button"
                            onClick={() => onChange(color)}
                            sx={{
                                p: 0, // fix for safari xD
                                mb: 2,
                                width: 28,
                                height: 28,
                                borderRadius: 28,
                                border: `2px solid ${darken(color.rgb, 0.1)}`,
                                backgroundColor: color.rgb,
                                position: 'relative',
                            }}
                        >
                            {isSelected && (
                                <Box
                                    component={motion.div}
                                    layoutId="dot"
                                    sx={{
                                        position: 'absolute',
                                        top: 5,
                                        left: 5,
                                        width: 14,
                                        height: 14,
                                        borderRadius: 14,
                                        backgroundColor: textColor,
                                        border: '2px solid',
                                        borderColor: darken(textColor, 0.1),
                                    }}
                                ></Box>
                            )}
                        </Box>
                    );
                })}
            </Stack>
        </Box>
    );
}
