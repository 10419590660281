import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import ListaSwitch from '../shared/ListaSwitch';
import LocationMap from './LocationMap';

export default function PostLocationSwitch({ onChange, checked, mapUrl }) {
    const { enqueueSnackbar } = useSnackbar();
    const { setConfirmationPrompt } = useConfirmationPrompt();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const tryGettingLocation = async () => {
        navigator.geolocation.getCurrentPosition((position) => {
          
            // alert('tryGettingLocation success');
            
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            onChange({
                is_geocoded: 1,
                lat,
                lng,
            }).finally(() => setLoading(false));
        }, () => {          
            // error getting location
            // alert('tryGettingLocation error');
            onLocationPermissionDenied();     
        });
    };

    const onLocationPermissionDenied = () => {
      
        // alert( navigator.userAgent );
      
        let isChrome = navigator.userAgent.indexOf("Chrome") > -1;
        let isSafari = navigator.userAgent.indexOf("Safari") > -1;
        if ( isChrome && isSafari) isSafari = false;
        
        if ( isSafari ) {
          enqueueSnackbar(t('postLocationPermissionDeniedSafari'));          
        } else {
          enqueueSnackbar(t('postLocationPermissionDenied'));
        }
        setLoading(false);
    };

    const handleChange = (e) => {
        const checked = e.target.checked;
        setLoading(true);
        
        // disabling location, switch gets unchecked
        if ( ! checked ) {
            setLoading(true);
            setConfirmationPrompt({
                title: t('postLocationPromptTitle'),
                body: t('postLocationPromptBody'),
                confirmText: t('postLocationPromptConfirmText'),
            }).then(
                () => {
                    onChange({
                        is_geocoded: 0,
                    }).finally(() => setLoading(false));
                },
                () => {
                    setLoading(false);
                }
            );
            return;
        }
        
        // enabling location, switch gets checked
        if (navigator.permissions) {
          
            // note only Safari v16+ (Sept 22 and later) 
            // support navigator.permissions
          
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((result) => {
                  
                    // alert('initial permission state: ' + result.state);
                    
                    if (result.state === 'denied') {
                        // permission already denied
                        onLocationPermissionDenied();
                        return;
                    }
                    
                    tryGettingLocation();

                    /*
                    if (
                        result.state === 'granted' ||
                        result.state === 'prompt'
                    ) {
                        tryGettingLocation();
                    }
                    
                    // this catches the user denying the permission prompt:
                    result.addEventListener('change', () => {
                        console.log('permission changed: ', result.state);

                        if (result.state === 'denied') {
                            onLocationPermissionDenied();
                        }
                    });
                    */

                });
                
        } else if (navigator.geolocation) {
            
            // Safari up to v15.6 (Sept 2022 and earlier)            
            // alert( 'navigator.geolocation tryGettingLocation' );
            
            tryGettingLocation();
            
        } else {
            // if none of the above
            // TODO: check the error reason, maybe?
            enqueueSnackbar(t('postLocationNotSupported'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }
        
    };

    return (
        <>
            <ListaSwitch
                disableTypography
                label={
                    <Typography variant="h4">{t('postLocation')}</Typography>
                }
                onChange={handleChange}
                checked={checked}
                loading={loading}
                sx={{
                    mb: 0,
                }}
            />

            {/* TODO: Animate appearance */}
            {checked && <LocationMap mapUrl={mapUrl} />}
        </>
    );
}
