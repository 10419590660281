import BTBrikRegularWoff from '../../assets/fonts/BTBrikRegular.woff';
import BTBrikRegularWoff2 from '../../assets/fonts/BTBrikRegular.woff2';

import BTBrikXxlObliqueWoff from '../../assets/fonts/BTBrik-XXLOblique.woff';
import BTBrikXxlObliqueWoff2 from '../../assets/fonts/BTBrik-XXLOblique.woff2';

import BTBrikXlCondensedObliqueWoff from '../../assets/fonts/BTBrik-XLCondensedOblique.woff';
import BTBrikXlCondensedObliqueWoff2 from '../../assets/fonts/BTBrik-XLCondensedOblique.woff2';

import BTBrikXlCondensedObliqueOtf from '../../assets/fonts/BTBrik-XLCondensedOblique.otf';
import BTBrikXXlCondensedObliqueOtf from '../../assets/fonts/BTBrik-XXLCondensedOblique.otf';

import PoppinsRegularWoff from '../../assets/fonts/Poppins-Regular.woff';
import PoppinsRegularWoff2 from '../../assets/fonts/Poppins-Regular.woff2';
import PoppinsItalicWoff from '../../assets/fonts/Poppins-Italic.woff';
import PoppinsItalicWoff2 from '../../assets/fonts/Poppins-Italic.woff2';
import PoppinsSemiBoldWoff from '../../assets/fonts/Poppins-SemiBold.woff';
import PoppinsSemiBoldWoff2 from '../../assets/fonts/Poppins-SemiBold.woff2';
import PoppinsSemiBoldItalicWoff from '../../assets/fonts/Poppins-SemiBoldItalic.woff';
import PoppinsSemiBoldItalicWoff2 from '../../assets/fonts/Poppins-SemiBoldItalic.woff2';
import PoppinsBoldWoff from '../../assets/fonts/Poppins-Bold.woff';
import PoppinsBoldWoff2 from '../../assets/fonts/Poppins-Bold.woff2';
import PoppinsBoldItalicWoff from '../../assets/fonts/Poppins-BoldItalic.woff';
import PoppinsBoldItalicWoff2 from '../../assets/fonts/Poppins-BoldItalic.woff2';

import SfproRegularOtf from '../../assets/fonts/SF-Pro-Display-Regular.otf';
import SfproItalicOtf from '../../assets/fonts/SF-Pro-Display-RegularItalic.otf';
import SfproSemiBoldOtf from '../../assets/fonts/SF-Pro-Display-Semibold.otf';
import SfproSemiBoldItalicOtf from '../../assets/fonts/SF-Pro-Display-SemiboldItalic.otf';
import SfproBoldOtf from '../../assets/fonts/SF-Pro-Display-Bold.otf';
import SfproBoldItalicOtf from '../../assets/fonts/SF-Pro-Display-BoldItalic.otf';

const MuiCssBaseline = {
    styleOverrides: `
        @font-face {
            font-family: 'BT Brik';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('BT Brik'), local('BT-Brik'), local('BT-Brik-Regular'), url(${BTBrikRegularWoff2}) format('woff2'), url(${BTBrikRegularWoff}) format('woff');
        }
        
        @font-face {
            font-family: 'BT Brik XXL Oblique';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('BT Brik XXL Oblique'), local('BT-Brik-XXL-Oblique'), url(${BTBrikXxlObliqueWoff2}) format('woff2'), url(${BTBrikXxlObliqueWoff}) format('woff');
        }
        
        @font-face {
            font-family: 'BT Brik XL Condensed Oblique';
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: local('BT Brik XL Condensed Oblique'), local('BT-Brik-XL-Condensed-Oblique'), url(${BTBrikXlCondensedObliqueOtf}) format('opentype'), url(${BTBrikXlCondensedObliqueWoff2}) format('woff2'), url(${BTBrikXlCondensedObliqueWoff}) format('woff');
        }
        
        @font-face {
            font-family: 'BT Brik XXL Condensed Oblique';
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: local('BT Brik XXL Condensed Oblique'), local('BT-Brik-XXL-Condensed-Oblique'), url(${BTBrikXXlCondensedObliqueOtf}) format('opentype');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsRegularWoff2}) format('woff2'), url(${PoppinsRegularWoff}) format('woff');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: local('Poppins'), local('Poppins-Italic'), url(${PoppinsItalicWoff2}) format('woff2'), url(${PoppinsItalicWoff}) format('woff');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('Poppins'), local('Poppins-SemiBold'), url(${PoppinsSemiBoldWoff2}) format('woff2'), url(${PoppinsSemiBoldWoff}) format('woff');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-display: swap;
            font-weight: 600;
            src: local('Poppins'), local('Poppins-SemiBoldItalic'), url(${PoppinsSemiBoldItalicWoff2}) format('woff2'), url(${PoppinsSemiBoldItalicWoff}) format('woff');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Poppins'), local('Poppins-Bold'), url(${PoppinsBoldWoff2}) format('woff2'), url(${PoppinsBoldWoff}) format('woff');
        }

        @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-display: swap;
            font-weight: 700;
            src: local('Poppins'), local('Poppins-BoldItalic'), url(${PoppinsBoldItalicWoff2}) format('woff2'), url(${PoppinsBoldItalicWoff}) format('woff');
        }
        
        @font-face {
            font-family: 'SF Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('SF Pro'), local('SF Pro Regular'), url(${SfproRegularOtf}) format('opentype');
        }

        @font-face {
            font-family: 'SF Pro';
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: local('SF Pro'), local('SF Pro Italic'),  url(${SfproItalicOtf}) format('opentype');
        }

        @font-face {
            font-family: 'SF Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('SF Pro'), local('SF Pro Semibold'),  url(${SfproSemiBoldOtf}) format('opentype');
        }

        @font-face {
            font-family: 'SF Pro';
            font-style: italic;
            font-display: swap;
            font-weight: 600;
            src: local('SF Pro'), local('SF Pro SemiboldItalic'), url(${SfproSemiBoldItalicOtf}) format('opentype');
        }

        @font-face {
            font-family: 'SF Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('SF Pro'), local('SF Pro Bold'),  url(${SfproBoldOtf}) format('opentype');
        }

        @font-face {
            font-family: 'SF Pro';
            font-style: italic;
            font-display: swap;
            font-weight: 700;
            src: local('SF Pro'), local('SF Pro BoldItalic'), url(${SfproBoldItalicOtf}) format('opentype');
        }
    `,
};

export default MuiCssBaseline;
