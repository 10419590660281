import React from 'react';
import Thumbnail from './Thumbnail';
import { Box, LinearProgress } from '@mui/material';

export default function ThumbnailWithProgress({ src, progress }) {
    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            <Thumbnail src={src} />
            <Box
                sx={{
                    position: 'absolute',
                    width: 'calc(100% - 16px)',
                    bottom: 8,
                    left: 8,
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    color="signalRed"
                />
            </Box>
        </Box>
    );
}
