import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import AddressForm from '../shared/AddressForm';
import CartActionBar from '../cart/CartActionBar';
import ListaButton from '../shared/ListaButton';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useAppState } from '../../../store/store';
import { useCart } from '../../../contexts/CartContext';
import DataController from '../../../lib/DataController';
import { useSnackbar } from 'notistack';

export default function ShippingForm({
    onContinue,
    inlineForm = false,
    onCancel,
}) {
    const { uuid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch, actions } = useAppState();
    const { cart, loaded } = useCart();

    const [fields, setFields] = useState({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        street: '',
        street2: '',
        street3: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        phone: state.user.phone,
        address_id: null,
    });

    const [buttonLoading, setButtonLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleNext = async () => {
        console.log(fields);
        setButtonLoading(true);
        DataController.checkout
            .setShipping({ uuid, fields })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.SET_CART, payload: data });
                    dispatch({ type: actions.SET_USER, payload: data.contact }); // addresses array might be updated here
                    enqueueSnackbar(message);
                    onContinue();
                } else {
                    console.error(message, errors);
                    enqueueSnackbar(message);
                    setErrors(errors);
                }
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    return (
        <Box>
            <AddressForm fields={fields} setFields={setFields} />

            {inlineForm && (
                <Stack direction="row" spacing={1} mt={2}>
                    <Button onClick={onCancel} sx={{ flex: 1 }} color="neutral">
                        Cancel
                    </Button>
                    <ListaButton
                        sx={{ flex: 1 }}
                        variant="contained"
                        disabled={!state.user.token}
                        onClick={handleNext}
                        loading={buttonLoading}
                    >
                        Add
                    </ListaButton>
                </Stack>
            )}

            {!inlineForm && (
                <CartActionBar>
                    <ListaButton
                        variant="contained"
                        disabled={!state.user.token}
                        onClick={handleNext}
                        loading={buttonLoading}
                    >
                        Continue
                    </ListaButton>
                    {/* <Link to={`/cart/${uuid}`}>
                        <Button color="secondary" size="small">
                            back
                        </Button>
                    </Link> */}
                </CartActionBar>
            )}
        </Box>
    );
}
