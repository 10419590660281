import { Button, CircularProgress } from '@mui/material';
import React from 'react';

export default function ListaButton({ children, loading, disabled, ...other }) {
    return (
        <Button disabled={disabled || loading} {...other}>
            {loading ? (
                <CircularProgress size={20} color="inherit" />
            ) : (
                <>{children}</>
            )}
        </Button>
    );
}
