import { Box, Checkbox, CircularProgress } from '@mui/material';
import React from 'react';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/CheckboxIcon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/CheckboxCheckedIcon.svg';

export default function ListaCheckbox({ loading, ...props }) {
    return (
        <Checkbox
            icon={loading ? <CircularProgress size={20} /> : <ListCheckboxIcon />}
            checkedIcon={
                loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <ListCheckboxIcon checked />
                )
            }
            {...props}
        />
    );
}

function ListCheckboxIcon({ checked }) {
    if (checked) {
        return (
            <Box
                component={CheckboxCheckedIcon}
                sx={{
                    marginRight: '-8px'
                }}
            />
        );
    }

    return (
        <Box
            component={CheckboxIcon}
            sx={{
                marginRight: '-8px'
            }}
        />
    );
}
