import React, { useEffect, useRef } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import useLottieAnimation from '../../../lib/hooks/useLottieAnimation';
import CheckAnimData from '../../../assets/lottie/check.json';

export default function SuccessWidget({ orderNumber, firstname }) {
    const animRef = useRef(null);

    const { setAnimationShouldPlay } = useLottieAnimation(
        animRef,
        CheckAnimData
    );

    useEffect(() => {
        setTimeout(() => {
            setAnimationShouldPlay(true);
        }, 200);
    }, []);

    return (
        <Stack direction="row" spacing={1}>
            <Box ref={animRef} width={40} height={40}></Box>
            <Stack>
                <Typography variant="body2" color="neutral.500">
                    Order {orderNumber}
                </Typography>
                <Typography variant="caption">
                    Thank you, {firstname}!
                </Typography>
            </Stack>
        </Stack>
    );
}
