import { Box, Divider, LinearProgress, Stack } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/AddAttachment.svg';
import { useAppState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import S3UploadController from '../../../lib/S3UploadController';
import DataController from '../../../lib/DataController';
import IconPlaceholder from '../../../assets/img/lista-thumb-placeholder.png';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import ListaTextButton from '../shared/ListaTextButton';
import DocumentsList from './DocumentsList';
import { t } from 'i18next';

const acceptedFormats = [
    // '.doc',
    // '.docx',
    // '.xml',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // '.pdf',
    // '.csv',
    // '.txt',
    // '.ods',
    // '.xml',
    // '.xls',
    // '.xltx',
    // 'application/pdf',
].join(',');

export default function AddDocuments() {
    const { dispatch, actions, state } = useAppState();
    const post = state.post.data || null;

    const { setConfirmationPrompt } = useConfirmationPrompt();
    const { enqueueSnackbar } = useSnackbar();

    const [currentUploads, setCurrentUploads] = useState([]);

    const pushToCurrentUploads = (fileData) => {
        fileData.progress = 0;
        setCurrentUploads((val) => [fileData, ...val]);
    };

    const removeFromCurrentUploads = (path) => {
        setCurrentUploads((val) => {
            const newArray = [...val];
            const index = newArray.findIndex((el) => el.filename === path);

            if (index > -1) {
                newArray.splice(index, 1);
            }

            return newArray;
        });
    };

    const updateProgress = (path, progress) => {
        setCurrentUploads((val) => {
            const newArray = [...val];
            const index = newArray.findIndex((el) => el.filename === path);
            if (index > -1) {
                newArray[index].progress = progress;
            }

            console.log('[updateProgress] ', newArray);
            return newArray;
        });
    };

    const handleFileUpload = async (e) => {
        const [newFile] = e.target.files;

        const errorCallback = (message) => {
            if (message)
                enqueueSnackbar(message, {
                    variant: 'error',
                });
        };

        if (!newFile) {
            console.error('You must select a document.');
            return;
        }
        if (!post) {
            console.error("Error. Post doesn't exist.");
            return;
        }

        const filePath = `/${post.id}/${new Date().getTime()}/${newFile.name}`;

        const JSONData = {
            filename: filePath,
        };

        pushToCurrentUploads(JSONData);

        const fileUploadLocation = await S3UploadController.putUploadFile({
            file: newFile,
            path: filePath,
            onProgress: (perc) => {
                updateProgress(filePath, perc);
            },
        });

        console.log('upload success: ', fileUploadLocation);

        const res = await DataController.media.postAssociateFiles(
            post.id,
            JSONData
        );
        if (!res.success) {
            errorCallback(res.message);
            return;
        }

        console.log('FINISHED. Removing file from currentUploads: ', filePath);
        removeFromCurrentUploads(filePath);

        console.warn('res', res);
        // TODO: ADD_POST_DOC
        dispatch({
            type: actions.ADD_POST_DOCUMENT,
            payload: { ...res.data },
        });
        enqueueSnackbar(res.message);

        e.target.value = '';
    };

    const handleDelete = (doc) => {
        setConfirmationPrompt({
            title: t('addDocumentsDeletePromptTitle'),
            body: t('addDocumentsDeletePromptBody'),
            confirmText: t('addDocumentsDeletePromptConfirmText'),
        }).then(
            // on confirmed
            () => {
                if (doc.document_type === 'link') {
                    DataController.link
                        .delete({ id: doc.id })
                        .then(({ success, data, errors, message }) => {
                            if (success) {
                                dispatch({
                                    type: actions.REMOVE_POST_DOCUMENT,
                                    payload: doc.id,
                                });
                                enqueueSnackbar(message);
                            } else {
                                enqueueSnackbar(message, {
                                    variant: 'error',
                                });
                            }
                        });
                } else {
                    DataController.media
                        .delete({ id: doc.id })
                        .then(({ success, data, errors, message }) => {
                            if (success) {
                                dispatch({
                                    type: actions.REMOVE_POST_DOCUMENT,
                                    payload: doc.id,
                                });
                                enqueueSnackbar(message);
                            } else {
                                enqueueSnackbar(message, {
                                    variant: 'error',
                                });
                            }
                        });
                }
            },
            // on rejected
            () => {}
        );
    };

    return (
        <Box>
            {post && (
                <>
                    <Box>
                        {currentUploads.map((doc, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                spacing={1.5}
                                alignItems="center"
                                sx={{
                                    width: '100%',
                                    mb: 2,
                                }}
                            >
                                <img
                                    src={IconPlaceholder}
                                    alt=""
                                    style={{
                                        width: 28,
                                        height: 30,
                                        objectFit: 'contain',
                                        objectPosition: 'cover',
                                    }}
                                />
                                <LinearProgress
                                    variant="determinate"
                                    value={doc.progress}
                                    color="signalRed"
                                    sx={{ minWidth: 200 }}
                                />
                            </Stack>
                        ))}
                    </Box>

                    {!!post.documents && post.documents.length > 0 && (
                        <DocumentsList
                            isEdit={true}
                            documents={post.documents}
                            onDelete={handleDelete}
                        />
                    )}

                    {(currentUploads.length > 0 ||
                        post.documents.length > 0) && <Divider sx={{}} />}

                    <Box>
                        <Box>
                            <ListaTextButton
                                caption={t('addDocumentsNewDocumentButton')}
                                icon={<AddIcon />}
                                component="label"
                            >
                                <input
                                    type="file"
                                    accept={acceptedFormats}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </ListaTextButton>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
}
