import React from 'react';
import { Button, Box, Typography } from '@mui/material';

export default function ListaTextButton({
    children,
    icon,
    caption,
    color = 'secondary',
    large = false,
    justifyContent = 'flex-start',
    ...other
}) {
    return (
        <Button
            variant="text"
            color={color}
            sx={{
                p: 0,
                width: '100%',
                justifyContent: justifyContent,
                textDecoration: 'none',
            }}
            {...other}
        >
            {children}
            <Box
                sx={{
                    height: 56,
                    display: 'flex',
                    alignItems: 'center',

                    svg: {
                        width: 26,
                        height: 26,
                        mr: 1.5,
                    },
                }}
            >
                {icon}
                <Typography
                    variant="caption"
                    component="span"
                    sx={{
                        fontSize: large ? 17 : 13,
                    }}
                >
                    {caption}
                </Typography>
            </Box>
        </Button>
    );
}
