import { Avatar, Divider, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { ReactComponent as RatingStarIcon } from '../../../assets/icons/RatingStarIcon.svg';
import { ReactComponent as RatingStarPartialIcon } from '../../../assets/icons/RatingStarPartialIcon.svg';
import { ReactComponent as RatingStarEmptyIcon } from '../../../assets/icons/RatingStarEmptyIcon.svg';
import ProductGallery from './ProductGallery';

export default function ProductDetails({ details, selectedSize }) {
    const isLarge = selectedSize.value === 'Wayfinder';
    return (
        <>
            <Typography variant="bodyMid">{details.description}</Typography>

            <Divider sx={{ my: 4, mx: 2 }}>
                <Stack>
                    <Typography
                        variant="caption"
                        color="neutral.900"
                        sx={{ fontSize: 11 }}
                    >
                        FREE 2-DAY SHIPPING WITH
                        <br />
                        EVERY ORDER
                    </Typography>
                </Stack>
            </Divider>

            <Stack spacing={1}>
                <Typography variant="h4">Applications</Typography>
                <Typography
                    variant="bodyMid"
                    dangerouslySetInnerHTML={{
                        __html: isLarge
                            ? details.description_large
                            : details.description_small,
                    }}
                ></Typography>
            </Stack>

            <Stack spacing={1}>
                <Typography variant="h4">product gallery</Typography>
                <ProductGallery images={details.gallery} />
            </Stack>

            <Stack spacing={1}>
                <Typography variant="h4">dimensions</Typography>
                <Typography
                    variant="bodyMid"
                    dangerouslySetInnerHTML={{
                        __html: isLarge
                            ? details.dimensions_large
                            : details.dimensions_small,
                    }}
                ></Typography>
            </Stack>

            {details && details.reviews && details.reviews.length > 0 && (
                <Stack spacing={3}>
                    <Typography variant="h4">product reviews</Typography>

                    <Stack spacing={1}>
                        {details.reviews.map((review, index) => {
                            const stars = review.rating;

                            const fullStars = Math.floor(stars);
                            const partialStars = stars % fullStars > 0 ? 1 : 0;
                            const emptyStars = 5 - fullStars - partialStars;

                            return (
                                <Stack
                                    key={index}
                                    spacing={2}
                                    p={2}
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Stack spacing={2} direction="row">
                                        <Box>
                                            <Avatar
                                                src={review.reviewer.avatar}
                                                sx={{ width: 48, height: 48 }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography variant="caption">
                                                {review.reviewer.first_name}
                                                &nbsp;
                                                {review.reviewer.last_name}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                            >
                                                {[
                                                    ...Array(fullStars).keys(),
                                                ].map((star, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            color: 'signalRed.main',
                                                        }}
                                                    >
                                                        <RatingStarIcon />
                                                    </Box>
                                                ))}

                                                {[
                                                    ...Array(
                                                        partialStars
                                                    ).keys(),
                                                ].map((star, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            color: 'signalRed.main',
                                                        }}
                                                    >
                                                        <RatingStarPartialIcon />
                                                    </Box>
                                                ))}

                                                {[
                                                    ...Array(emptyStars).keys(),
                                                ].map((star, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            color: 'signalRed.main',
                                                        }}
                                                    >
                                                        <RatingStarEmptyIcon />
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    <Typography
                                        variant="body2"
                                        dangerouslySetInnerHTML={{
                                            __html: review.review,
                                        }}
                                        color="neutral.800"
                                    ></Typography>
                                </Stack>
                            );
                        })}
                    </Stack>
                </Stack>
            )}
        </>
    );
}
