import { Stack } from '@mui/material';
import React from 'react';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import sharedStyles from '../../../theme/sharedStyles';

export default function ListaDialogFooter({ children, fullScreen = false }) {
    const [windowWidth] = useWindowSize();
    return (
        <Stack
            py={1}
            px={2}
            sx={{
                position: fullScreen ? 'fixed' : 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'neutral.100',
                borderTop: `1px solid`,
                borderColor: 'neutral.300',
                zIndex: 10,
                ...(fullScreen
                    ? sharedStyles.getFixedContainerStyles(windowWidth)
                    : sharedStyles.container),
                ...sharedStyles.glassmorphism,
            }}
            direction="row"
            justifyContent="flex-end"
        >
            {children}
        </Stack>
    );
}
