import React from 'react';
import { Box } from '@mui/material';

export default function PinInput({ length = 6, value = '', onChange }) {
    const width = 311;
    const fieldWidth = (width - 8 * (length - 1)) / length;
    const letterSpacing = fieldWidth - 6;

    return (
        <Box
            sx={{
                position: 'relative',
                width: width,
                height: 48,
                margin: '0 auto',
                zIndex: 10,

                '& input': {
                    '-webkit-font-smoothing': 'inherit',
                    '-webkit-appearance': 'none',
                    appearance: 'none',
                    background: 'transparent',
                    border: 'none',
                    caretColor: '#000',
                    caretColor: 'var(--background--text)',
                    color: 'transparent',
                    fontSize: '25px',
                    height: '48px',
                    // letterSpacing: '38px',
                    letterSpacing: letterSpacing,
                    lineHeight: 'normal',
                    margin: 0,
                    outline: 'none',
                    // paddingLeft: '22px',
                    paddingLeft: `${fieldWidth / 2}px`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                },
            }}
        >
            <input
                type="text"
                inputMode="numeric"
                id="identity-verification-code"
                aria-label="Verification code"
                aria-describedby="modal-content"
                autoComplete="one-time-code"
                maxLength={length}
                value={value}
                onChange={onChange}
            />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                }}
            >
                {Array.from(Array(length).keys()).map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: fieldWidth,
                            border: '2px solid',
                            borderColor: 'neutral.400',
                            borderRadius: 2,
                            height: '100%',
                            color: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value[index]}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
