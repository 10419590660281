import React from 'react';
import { Stack, IconButton, Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../assets/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/MinusIcon.svg';

export default function ListaNumberInput({
    value,
    onChange,
    min = false,
    max = false,
}) {
    const handleSubtract = () => {
        if (typeof min === 'number' && value === min) {
            return;
        }

        onChange(value - 1);
    };

    const handleAdd = () => {
        if (typeof max === 'number' && value === max) {
            return;
        }

        onChange(value + 1);
    };

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            px={2}
            sx={{
                border: '2px solid',
                borderColor: 'neutral.400',
                borderRadius: 2,
                minHeight: 48,
                backgroundColor: 'neutral.100',
            }}
        >
            <IconButton onClick={handleSubtract}>
                <MinusIcon />
            </IconButton>
            <Typography
                variant="subtitle1"
                color="neutral.900"
                sx={{ minWidth: 16, textAlign: 'center' }}
            >
                {value}
            </Typography>
            <IconButton onClick={handleAdd}>
                <PlusIcon />
            </IconButton>
        </Stack>
    );
}
