import { Box, Divider, Grid } from '@mui/material';
import { useRef, useCallback, useEffect, useState } from 'react';
import { useTags } from '../../../contexts/TagsContext.js';
import Tag from './tag/Tag.js';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';
import { useAppState } from '../../../store/store.js';
import FilterDrawer from './FilterDrawer.js';
import AlgoliaProvider from '../../../lib/AlgoliaProvider.js';
import { Hits } from 'react-instantsearch-dom';
import Search from './Search.js';
import { AnimatePresence, motion } from 'framer-motion';
import { CustomHits } from './CustomHits.js';

export default function TagsFeed() {
    const { isLoading, getTags, tags } = useTags();
    const { dispatch, actions } = useAppState();

    const [displayHits, setDisplayHits] = useState(false);

    const intObserver = useRef();

    const lastTagRef = useCallback(
        (tag) => {
            if (isLoading) {
                return;
            }

            if (intObserver.current) {
                intObserver.current.disconnect();
            }

            intObserver.current = new IntersectionObserver((tags) => {
                if (tags[0].isIntersecting) {
                    getTags();
                }
            });

            if (tag) {
                intObserver.current.observe(tag);
            }
        },
        [isLoading]
    );

    useEffect(() => {
        console.log('[TagsFeed] initial fetch fired: ', tags.length);
        if (tags.length < 1) {
            getTags();
        }

        dispatch({
            type: actions.SET_TAGS_GRID_CONTEXT,
            payload: {
                grid: 'tags',
                folderId: null,
            },
        });
    }, []);

    const showHits = () => {
        setDisplayHits(true);
    };

    const hideHits = () => {
        setDisplayHits(false);
    };

    return (
        <AlgoliaProvider>
            <Box sx={{ mt: 1.5, mb: 18, px: 1 }}>
                {isLoading && tags.length === 0 ? (
                    <CenteredCircularProgress />
                ) : (
                    <>
                        <Box mb={2}>
                            <Search
                                onFocus={showHits}
                                onCancel={hideHits}
                                hitsShowing={displayHits}
                            />
                        </Box>

                        <AnimatePresence mode="wait">
                            <motion.div
                                key={displayHits ? 'hits' : 'grid'}
                                initial={{
                                    y: 30,
                                    opacity: 0,
                                }}
                                animate={{
                                    y: 0,
                                    opacity: 1,
                                }}
                                exit={{
                                    y: 30,
                                    opacity: 0,
                                }}
                            >
                                {displayHits ? (
                                    <CustomHits />
                                ) : (
                                    <Grid container spacing={1}>
                                        {tags.map((tag, i) => {
                                            if (tags.length === i + 1) {
                                                return (
                                                    <Tag
                                                        key={i}
                                                        ref={lastTagRef}
                                                        data={tag}
                                                    />
                                                );
                                            }

                                            return <Tag key={i} data={tag} />;
                                        })}
                                    </Grid>
                                )}
                            </motion.div>
                        </AnimatePresence>
                    </>
                )}

                {/* <FilterDrawer /> */}
            </Box>
        </AlgoliaProvider>
    );
}
