import { Box, Grid } from '@mui/material';
import { useRef, useCallback, useState, useEffect } from 'react';
import useFolders from '../../../lib/hooks/useFolders';
import { useSnackbar } from 'notistack';
import Folder from './folder/Folder';
import CenteredCircularProgress from '../shared/CenteredCircularProgress';

export default function FoldersFeed({ currentTab, tabs }) {
    const { enqueueSnackbar } = useSnackbar();
    const [pageNum, setPageNum] = useState(1);
    const { isLoading, folders, error, hasNextPage } = useFolders(
        currentTab,
        tabs,
        pageNum,
    );
    const intObserver = useRef();
    const lastFoldertRef = useCallback((folder) => {
        if (isLoading) {
            return;
        }

        if (intObserver.current) {
            intObserver.current.disconnect();
        }

        intObserver.current = new IntersectionObserver(folders => {
            if (folders[0].isIntersecting && hasNextPage) {
                setPageNum(prev => prev + 1);
            }
        });

        if (folder) {
            intObserver.current.observe(folder);
        }
    }, [isLoading, hasNextPage]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [error]);

    return (
        <Box sx={{ mt: 1.5, mb: 18, px: 1 }}>
            {isLoading && pageNum === 1 ? (
                <CenteredCircularProgress />
            ) : (
                <Grid container spacing={1}>
                    {folders.map((folder, i) => {
                        if (folder.length === i + 1) {
                            return <Folder key={i} ref={lastFoldertRef} data={folder} />;
                        } 
                        return <Folder key={i} data={folder} />;
                    })}
                </Grid>
            )}
        </Box>
    );
}
