import React from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../assets/icons/TrashIcon.svg';
import { ReactComponent as FileIconGoogle } from '../../../assets/icons/FileIconGoogle.svg';
import { ReactComponent as FileIconPDF } from '../../../assets/icons/FileIconPDF.svg';
import { ReactComponent as FileIconFile } from '../../../assets/icons/FileIconFile.svg';

const icons = {
    google: <FileIconGoogle />,
    pdf: <FileIconPDF />,
    file: <FileIconFile />,
};

export default function DocumentsList({ documents, isEdit, onDelete }) {
    return (
        <Stack>
            {documents.map((doc, index) => {
                const icon = doc.icon in icons ? icons[doc.icon] : icons.file;

                return (
                    <Stack
                        key={index}
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                        sx={{
                            width: '100%',
                            mb: 2,
                        }}
                    >
                        <a
                            href={doc.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                flexGrow: 1,
                                width: '70%',
                                overflow: 'hidden',
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1.5}
                                alignItems="center"
                            >
                                {icon}
                                <Typography variant="caption" sx={{
                                    width: '70%',
                                }}>
                                    {doc.caption ?? doc.filename}
                                </Typography>
                            </Stack>
                        </a>

                        <Typography variant="body2" color="neutral.500">
                            {doc.link_description ?? doc.filesize_formatted}
                        </Typography>

                        {isEdit && (
                            <IconButton
                                color="secondary"
                                onClick={() => onDelete(doc)}
                            >
                                <TrashIcon width="16" />
                            </IconButton>
                        )}
                    </Stack>
                );
            })}
        </Stack>
    );
}
