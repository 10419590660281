import { useSnackbar } from 'notistack';
import React, { useState, useContext, createContext, useEffect } from 'react';
import DataController from '../lib/DataController';
import { useAppState } from '../store/store';

const CartContext = createContext();

function CartProvider({ children }) {
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [currentEditingItem, setCurrentEditingItem] = useState(false);
    const [currentDeletingItem, setCurrentDeletingItem] = useState(false);

    useEffect(() => {
        // TODO:
        // console.log(
        //     '[CartContext] Run on init, check if user has abandoned cart (via localStorage).'
        // );
        // console.log(
        //     '[CartContext] Fetch the abandond cart to continue shopping'
        // );
    }, []);

    const getCart = async ({ uuid }) => {
        setLoading(true);
        try {
            const res = await DataController.checkout.get({ uuid });
            if (res.success) {
                dispatch({
                    type: actions.SET_CART,
                    payload: res.data,
                });
            } else {
                enqueueSnackbar(message, { variant: 'error' });
            }
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e, { variant: 'error' });
        }
        setLoading(false);
    };

    const addToCart = async ({ size, color, roll, qty }) => {
        setLoading(true);

        if (typeof size === 'object') {
            size = size.value;
        }
        if (typeof color === 'object') {
            color = color.value;
        }
        if (typeof roll === 'object') {
            roll = roll.value;
        }

        let uuid = state.cart.data?.uuid ?? null;

        if (!uuid) {
            try {
                const res = await DataController.checkout.create();
                if (res.success) {
                    dispatch({
                        type: actions.SET_CART,
                        payload: res.data,
                    });
                    uuid = res.data.uuid;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e, { variant: 'error' });
            }
        }

        try {
            const { success, data, errors, message } =
                await DataController.checkout.addItem({
                    uuid,
                    size,
                    color,
                    roll,
                    qty,
                });
            if (success) {
                dispatch({ type: actions.SET_CART, payload: data });
            } else {
                enqueueSnackbar(message, { variant: 'error' });
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err, { variant: 'error' });
        }

        setLoading(false);
    };

    const editCartItem = async ({ uuid, itemId, qty }) => {
        // TODO: item level loader
        setCurrentEditingItem(itemId);
        try {
            const { success, data, errors, message } =
                await DataController.checkout.editItem({ uuid, itemId, qty });

            if (success) {
                dispatch({ type: actions.SET_CART, payload: data });
                enqueueSnackbar(message);
            } else {
                enqueueSnackbar(err, { variant: 'error' });
            }
        } catch (err) {
            console.error(err);
        }
        setCurrentEditingItem(false);
    };

    const deleteCartItem = async ({ uuid, itemId }) => {
        setCurrentDeletingItem(itemId);
        try {
            const { success, data, errors, message } =
                await DataController.checkout.deleteItem({ uuid, itemId });

            if (success) {
                dispatch({ type: actions.SET_CART, payload: data });
                enqueueSnackbar(message);
            } else {
                enqueueSnackbar(err, { variant: 'error' });
            }
        } catch (err) {
            console.error(err);
        }
        setCurrentDeletingItem(false);
    };

    const claimCart = async ({ uuid }) => {
        setLoading(true);
        try {
            const { success, data, errors, message } =
                await DataController.checkout.claim({ uuid });

            if (success) {
                dispatch({ type: actions.SET_CART, payload: data });
            } else {
                enqueueSnackbar(err, { variant: 'error' });
            }
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    const clearCartData = () => {
        dispatch({ type: actions.CLEAR_CART });
    };

    return (
        <CartContext.Provider
            value={{
                cart: state.cart.data,
                loaded: state.cart.loaded,
                loading,
                addToCart,
                getCart,
                editCartItem,
                currentEditingItem,
                deleteCartItem,
                currentDeletingItem,
                claimCart,
                clearCartData,
            }}
        >
            {children}
        </CartContext.Provider>
    );
}

const useCart = () => {
    return useContext(CartContext);
};

export { CartProvider, useCart };
