import { Typography, Box } from '@mui/material';
import palette from '../../../../theme/palette.js';

export default function TagDetails({ title, subtitle, backgroundColor }) {
  return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
      }}>
          <Box sx={{
              height: '100%',
              width: 6,
              backgroundColor: backgroundColor,
              mr: 1.25,
          }}/>
          <Box>
              <Typography 
                  variant="body2" 
                  element="h2" 
                  sx={{ 
                      fontWeight: 'bold',
                      color: palette.neutral[100],
                  }}
              >
                  {title}
              </Typography>
              <Typography
                  variant="body2"
                  sx={{ 
                      color: palette.neutral[200],
                  }}
              >
                  {subtitle}
              </Typography>
          </Box>
      </Box>
  );
}
