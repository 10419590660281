import React from 'react';
import { Box } from '@mui/material';
import TagAlpineOrange from '../../../assets/graphics/TagAlpineOrange.svg';
import TagPearlWhite from '../../../assets/graphics/TagPearlWhite.svg';
import TagMarineBlue from '../../../assets/graphics/TagMarineBlue.svg';
import TagSignalRed from '../../../assets/graphics/TagSignalRed.svg';
import TagTurfGreen from '../../../assets/graphics/TagTurfGreen.svg';
import TagBlazeOrange from '../../../assets/graphics/TagBlazeOrange.svg';
import TagNeonYellow from '../../../assets/graphics/TagNeonYellow.svg';
import TagLagoonBlue from '../../../assets/graphics/TagLagoonBlue.svg';
import TagJetBlack from '../../../assets/graphics/TagJetBlack.svg';

import { keyframes } from '@emotion/react';

const flyIn = keyframes`
    0% {
        transform: translateZ(100px) translateY(20px) rotateX(20deg);
    }
    100% {
        transform: translateZ(10px) translateY(0px) rotateX(0deg);
    }`;

const curve = keyframes`
        0% { transform: translateZ(1px) rotateX(10deg)); }
        100% { transform: translateZ(1px) rotateX(2deg); }
    `;

const shadow = keyframes`
        0% { 
            transform: translateX(-50%) rotateX(80deg) rotateY(5deg) translateZ(-80px) scale(1.6);
            opacity: 0; 
        }
        100% { 
            transform: translateX(-50%) rotateX(90deg) rotateY(5deg) translateZ(-20px) scale(1); 
            opacity: 1; 
        }
    `;

const backgrounds = {
    'Alpine Orange': TagAlpineOrange,
    'Marine Blue': TagMarineBlue,
    'Signal Red': TagSignalRed,
    'Turf Green': TagTurfGreen,
    'Pearl White': TagPearlWhite,
    'Blaze Orange': TagBlazeOrange,
    'Neon Yellow': TagNeonYellow,
    'Lagoon Blue': TagLagoonBlue,
    'Jet Black': TagJetBlack,
};

export default function AnimatedListaTag({ colorway }) {
    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100%',
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    scale: '65%',
                    transformStyle: 'preserve-3d',
                    '--duration': '2s',
                    '--easing': 'cubic-bezier(.36,.07,.25,1)',
                    '--fill-mode': 'forwards',

                    '*': {
                        transformStyle: 'preserve-3d',
                        margin: 0,
                        padding: 0,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box
                    sx={{
                        width: 120,
                        height: 200,
                        margin: '0 auto !important',
                        position: 'relative',
                        perspective: 500,
                        transform: 'matrix(0.97, -0.14, 0.28, 0.99, 0, 0)',

                        '.AnimatedListaTag-paper': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',

                            boxSizing: 'border-box',
                            padding: 0,
                            margin: 0,

                            '--segments': 10,
                            '--segment': 'calc(100% * 1 / var(--segments))',

                            animation: `${flyIn} var(--duration) var(--easing) var(--fill-mode)`,

                            '& > .AnimatedListaTag-segment': {
                                height: 'var(--segment)',
                            },
                        },

                        '.AnimatedListaTag-segment': {
                            position: 'relative',
                            left: 0,
                            width: '100%',
                            height: '100%',
                            '--rotate': '10deg',
                            transformOrigin: 'top center',
                            background: `url(${backgrounds[colorway]})`,
                            backgroundSize: '120px 200px',
                            backgroundPosition: '0 calc(-20px * var(--index))',
                            backfaceVisibility: 'hidden',

                            '& > .AnimatedListaTag-segment': {
                                top: '96%',
                                transform: 'rotateX(var(--rotate))',
                                animation: `${curve} var(--duration) var(--easing) var(--fill-mode)`,
                            },
                        },

                        '.AnimatedListaTag-shadow': {
                            width: 0,
                            height: 0,
                            borderRadius: '50%',
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform:
                                'translateX(-50%) rotateX(83deg) rotateY(5deg) translateZ(-80px) scale(1.4)',
                            boxShadow: '0px 0px 47px 16px rgba(0, 0, 0, 0.8)',

                            animation: `${shadow} var(--duration) var(--easing) var(--fill-mode)`,
                        },
                    }}
                >
                    <Box className="AnimatedListaTag-shadow"></Box>
                    <Box className="AnimatedListaTag-paper">
                        <Box
                            className="AnimatedListaTag-segment"
                            style={{ '--index': 0 }}
                        >
                            <Box
                                className="AnimatedListaTag-segment"
                                style={{ '--index': 1 }}
                            >
                                <Box
                                    className="AnimatedListaTag-segment"
                                    style={{ '--index': 2 }}
                                >
                                    <Box
                                        className="AnimatedListaTag-segment"
                                        style={{ '--index': 3 }}
                                    >
                                        <Box
                                            className="AnimatedListaTag-segment"
                                            style={{ '--index': 4 }}
                                        >
                                            <Box
                                                className="AnimatedListaTag-segment"
                                                style={{ '--index': 5 }}
                                            >
                                                <Box
                                                    className="AnimatedListaTag-segment"
                                                    style={{ '--index': 6 }}
                                                >
                                                    <Box
                                                        className="AnimatedListaTag-segment"
                                                        style={{ '--index': 7 }}
                                                    >
                                                        <Box
                                                            className="AnimatedListaTag-segment"
                                                            style={{
                                                                '--index': 8,
                                                            }}
                                                        >
                                                            <Box
                                                                className="AnimatedListaTag-segment"
                                                                style={{
                                                                    '--index': 9,
                                                                }}
                                                            ></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
