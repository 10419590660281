import { createContext, useContext, useEffect, useState } from 'react';
import useAppData from '../store/useAppData';
import { DateTime } from 'luxon';

const FiltersContext = createContext();

function FiltersProvider({ children }) {
    const state = useAppData({
        queries: ['getProducts'],
    });

    const filterOptionsLoaded = state.products.loaded;
    const colorOptions = filterOptionsLoaded
        ? state.products.data.options.colors
        : [];

    const [enabled, setEnabled] = useState(false);

    const [editorTypes, setEditorTypes] = useState({
        owner: {
            value: 'owner',
            checked: true,
            label: 'My Tags',
        },
        editor: {
            value: 'editor',
            checked: true,
            label: 'Team Tags',
        },
    });

    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [colors, setColors] = useState([]);
    const [dateRangeError, setDateRangeError] = useState('');

    const [filterParams, setFilterParams] = useState('');

    useEffect(() => {
        setDateRangeError('');

        if (!dateStart || !dateEnd) return;

        const ds = DateTime.fromISO(dateStart);
        const de = DateTime.fromISO(dateEnd);

        if (de < ds)
            setDateRangeError('The end date must come after the start date.');
    }, [dateStart, dateEnd]);

    useEffect(() => {
        let paramsObj = {};

        /**
         * Editor types comma delimited string
         */
        let editor_types = '';
        for (let key in editorTypes) {
            if (editorTypes[key].checked) {
                if (editor_types != '') {
                    editor_types += ',';
                }

                editor_types += key;
            }
        }

        if (editor_types != '') paramsObj.editor_types = editor_types;

        /**
         * Dates
         */
        if (dateStart)
            paramsObj.date_start = DateTime.fromISO(dateStart).toISODate();
        if (dateEnd) paramsObj.date_end = DateTime.fromISO(dateEnd).toISODate();

        /**
         * Colors comma delimited string
         */
        if (colors.length > 0) paramsObj.colors = colors.join(',');

        /**
         * final params string
         */
        let params = new URLSearchParams([
            ...Object.entries(paramsObj),
        ]).toString();

        console.log('PARAMS CHANGED: ', params);
        setFilterParams(params);
    }, [editorTypes, colors, dateStart, dateEnd]);

    // on product load, pre-select all colors
    useEffect(() => {
        if (state.products.loaded) {
            let arr = [];
            state.products.data.options.colors.forEach((color) =>
                arr.push(color.value)
            );
            setColors(arr);
        }
    }, [state.products.loaded]);

    const toggleFiltersEnabled = () => {
        setEnabled((val) => !val);
    };

    const toggleEditorType = (value) => {
        const otherValue = value === 'owner' ? 'editor' : 'owner';

        setEditorTypes((oldVal) => {
            const newVal = JSON.parse(JSON.stringify(oldVal));

            if (!newVal[value].checked) {
                // if not checked, just check it
                newVal[value].checked = true;
            } else {
                // if checked, need to check if it's the only one checked,
                // in which case we need to check the other one before unchecking
                if (!newVal[otherValue].checked) {
                    newVal[otherValue].checked = true;
                }
                newVal[value].checked = false;
            }

            return newVal;
        });
    };

    const toggleColor = (color) => {
        setColors((oldVal) => {
            const newVal = JSON.parse(JSON.stringify(oldVal));

            const index = newVal.indexOf(color);
            if (index > -1) {
                newVal.splice(index, 1);
            } else {
                newVal.push(color);
            }

            return newVal;
        });
    };

    return (
        <FiltersContext.Provider
            value={{
                filterOptionsLoaded,

                enabled,
                toggleFiltersEnabled,

                editorTypes,
                toggleEditorType,

                colors,
                colorOptions,
                toggleColor,

                dateStart,
                setDateStart,
                dateEnd,
                setDateEnd,
                dateRangeError,

                filterParams,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}

const useFilters = () => {
    return useContext(FiltersContext);
};

export { FiltersProvider, useFilters };
