import { Box, Button, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import sharedStyles from '../../../theme/sharedStyles';

export default function CartHeader({ title }) {
    const [windowWidth] = useWindowSize();

    return (
        <Stack
            component="header"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            pb={1}
            sx={{
                borderBottom: `1px solid`,
                borderColor: 'neutral.300',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 10,
                ...sharedStyles.getFixedContainerStyles(windowWidth),
                ...sharedStyles.glassmorphism,
            }}
        >
            <Box>
                <Typography variant="h3">{title}</Typography>
            </Box>

            <Link to="/shop">
                <Button size="small" color="secondary">
                    back to shop
                </Button>
            </Link>
        </Stack>
    );
}
