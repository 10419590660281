import { Grid, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import palette from '../../../../theme/palette';
import { forwardRef } from 'react';
import folderCoverThumbnail from '../../../../assets/img/lista-thumb-placeholder.png';

const Folder = forwardRef(({ data }, ref) => {
    return (
        <Grid item xs={6}>
            <Box
                ref={ref}
                component={Link}
                to={`/folder/${data?.id}`}
                sx={{
                    background: `url(${data?.folder_thumbnail_url})`,
                    backgroundColor: palette.neutral[200],
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-reapeat',
                    backgroundPosition: 'center',
                    height: 166,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    textDecoration: 'none',
                }}
            >
                {!data?.folder_thumbnail_url && (
                    <Box
                        src={folderCoverThumbnail}
                        alt={data?.title || 'Lista Folder'}
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                    />
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    mt: 0.5,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: palette.neutral[800],
                        fontWeight: 600,
                    }}
                >
                    {data?.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: palette.neutral[600],
                        fontWeight: 400,
                    }}
                >
                    {data?.subtitle}
                </Typography>
            </Box>
        </Grid>
    );
});
Folder.displayName = 'Folder';

export default Folder;
