import palette from '../palette';

const MuiOutlinedInput = {
    styleOverrides: {
        root: {
            height: 48,
            lineHeight: 'inherit',
            color: 'inherit',
            borderRadius: 8,
        },

        input: {
            padding: '10px 14px',

            '&::placeholder': {
                color: palette.neutral[900],
            },
        },

        notchedOutline: {
            borderWidth: 2,
            borderColor: palette.neutral[400],
        },
    },
};

export default MuiOutlinedInput;
