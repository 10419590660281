import MoreMenu from './MoreMenu';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import DataController from '../../../lib/DataController';
import { useAppState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import ListaDialog from './ListaDialog';
import { useEffect } from 'react';
import AddressForm from './AddressForm';
import ListaButton from './ListaButton';

const AddressMoreMenu = ({ address }) => {
    const { state, dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const { setConfirmationPrompt } = useConfirmationPrompt();

    const [loading, setLoading] = useState(false);

    const [edit, setEdit] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editFields, setEditFields] = useState({});

    useEffect(() => {
        if (edit) {
            setEditFields({
                firstname: address.firstname || '',
                lastname: address.lastname || '',
                email: address.email || '',
                company: address.company || '',
                street: address.street || '',
                street2: address.street2 || '',
                street3: address.street3 || '',
                city: address.city || '',
                state: address.state || '',
                zip: address.zip || '',
                country: address.country || '',
            });
            setTimeout(() => setEditOpen(true), 10);
        }
    }, [edit]);

    useEffect(() => {
        if (!editOpen) {
            setTimeout(() => {
                setEdit(false);
                setEditFields({});
            }, 200);
        }
    }, [editOpen]);

    const options = [
        {
            label: 'Make Default',
            disabled: !!address.is_default,
            onClick: () => {
                setLoading(true);
                DataController.addresses
                    .makeDefault({ id: address.id })
                    .then(({ success, data, errors, message }) => {
                        if (success) {
                            dispatch({
                                type: actions.UPDATE_DEFAULT_ADDRESS,
                                payload: data,
                            });
                            enqueueSnackbar(message);
                        } else {
                            console.error(message);
                            enqueueSnackbar(message, { variant: 'error' });
                        }
                    })
                    .finally(() => setLoading(false));
            },
        },
        {
            label: 'Edit',
            onClick: () => setEdit(true),
        },
        {
            label: <Typography color="error">Delete</Typography>,
            onClick: () => {
                setConfirmationPrompt({
                    title: 'Are you sure?',
                    body: "This action can't be undone.",
                    confirmText: 'Yes, delete',
                })
                    .then(() => {
                        setLoading(true);

                        DataController.addresses
                            .delete({ id: address.id })
                            .then(({ success, data, errors, message }) => {
                                if (success) {
                                    dispatch({
                                        type: actions.DELETE_ADDRESS,
                                        payload: address.id,
                                    });
                                    enqueueSnackbar(message);
                                } else {
                                    console.error(message);
                                    enqueueSnackbar(message, {
                                        variant: 'error',
                                    });
                                }
                            })
                            .finally(() => setLoading(false));
                    })
                    .catch(() => {
                        console.log('canceled');
                        setLoading(false);
                    });
            },
        },
    ];

    const saveEdits = () => {
        setLoading(true);

        DataController.addresses
            .edit({ id: address.id, fields: editFields })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({
                        type: actions.EDIT_ADDRESS,
                        payload: data,
                    });
                    enqueueSnackbar(message);
                    setEditOpen(false);
                } else {
                    console.error(message);
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <MoreMenu options={options} loading={loading} />
            {edit && (
                <ListaDialog
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    fullScreen={true}
                    title={'Edit address'}
                    actions={
                        <ListaButton
                            variant="contained"
                            fullWidth
                            onClick={saveEdits}
                            loading={loading}
                        >
                            Save
                        </ListaButton>
                    }
                >
                    <Box p={2}>
                        <AddressForm
                            fields={editFields}
                            setFields={setEditFields}
                        />
                    </Box>
                </ListaDialog>
            )}
        </>
    );
};

export default AddressMoreMenu;
