import {
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useAppState } from '../../../store/store';
import { Box, Button } from '@mui/material';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/CheckboxIcon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/CheckboxCheckedIcon.svg';
import { motion } from 'framer-motion';
import ShippingForm from '../checkout/ShippingForm';
import { scrollIntoView } from '../../../lib/functions';
import ListaAccordion from './ListaAccordion';
import AddressMoreMenu from './AddressMoreMenu';
import AddressForm from './AddressForm';
import ListaButton from './ListaButton';
import DataController from '../../../lib/DataController';
import { useSnackbar } from 'notistack';

export default function AddressPicker({
    selectedAddressId,
    onChange,
    title = 'Addresses',
    mode = 'checkout', // checkout | account
}) {
    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch, actions } = useAppState();

    const [expanded, setExpanded] = useState(false);
    const [settingAddress, setSettingAddress] = useState(false);
    const [displayForm, setDisplayForm] = useState(false);
    const dividerRef = useRef();

    const [fields, setFields] = useState({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        street: '',
        street2: '',
        street3: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        phone: state.user.phone,
        address_id: null,
    });
    const [addButtonLoading, setAddButtonLoading] = useState(false);

    const handleAddAddress = () => {
        setAddButtonLoading(true);

        DataController.addresses
            .create({ fields })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.ADD_ADDRESS, payload: data });
                    enqueueSnackbar(message);
                    hideAddressForm();
                } else {
                    console.error(message);
                    enqueueSnackbar(message, { type: 'error' });
                }
            });

        setAddButtonLoading(false);
    };

    const toggle = () => {
        if (expanded) {
            setDisplayForm(false);
        }

        setExpanded(!expanded);
    };

    const addressToString = (address) => {
        const lines = address.multiline.split('\n');
        return lines;
    };

    const handleChange = async (id) => {
        if (id === selectedAddressId) {
            return;
        }
        setSettingAddress(id);
        await onChange(id);
        setSettingAddress(false);
        toggle();
    };

    const hideAddressForm = () => {
        scrollIntoView(dividerRef.current);
        setDisplayForm(false);
    };

    const selectedAddress = state.user.addresses.find(
        (address) => address.id === selectedAddressId
    );

    const AddressItem = ({ address }) => {
        const addressLines = addressToString(address);
        const isSelected = address.id === selectedAddressId;

        return (
            <Stack
                component={motion.div}
                key={address.id}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    textAlign: 'left',
                    marginTop: 2,
                    width: '100%',
                }}
            >
                <Stack>
                    <Typography variant="caption">{addressLines[0]}</Typography>
                    <Typography variant="body2">{addressLines[1]}</Typography>
                    <Typography variant="body2">{addressLines[2]}</Typography>
                </Stack>
                {mode === 'checkout' && (
                    <IconButton
                        sx={{
                            minWidth: 32,
                            position: 'relative',
                            right: -2,
                        }}
                        onClick={() => handleChange(address.id)}
                    >
                        {settingAddress === address.id ? (
                            <>
                                <CircularProgress size={20} />
                            </>
                        ) : (
                            <>
                                {isSelected ? (
                                    <CheckboxCheckedIcon />
                                ) : (
                                    <CheckboxIcon />
                                )}
                            </>
                        )}
                    </IconButton>
                )}

                {mode === 'account' && <AddressMoreMenu address={address} />}
            </Stack>
        );
    };

    return (
        <ListaAccordion
            expanded={expanded}
            onToggle={toggle}
            title={title}
            contentExpanded={
                <>
                    {state.user.addresses.map((address) => (
                        <AddressItem key={address.id} address={address} />
                    ))}
                    <Box
                        component={motion.div}
                        animate={{
                            opacity: expanded ? 1 : 0,
                            height: expanded ? 'auto' : 0,
                            marginTop: expanded ? 16 : 0,
                        }}
                        sx={{ overflow: 'hidden' }}
                    >
                        <Divider sx={{ mt: 2 }} ref={dividerRef} />
                        <Box
                            key="addbutton"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 0 : 'auto',
                                opacity: displayForm ? 0 : 1,
                                marginBottom: displayForm ? 0 : -16,
                            }}
                            sx={{ overflow: 'hidden' }}
                        >
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => setDisplayForm(true)}
                            >
                                + Add address
                            </Button>
                        </Box>
                        <Box
                            key="form"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 'auto' : 0,
                                opacity: displayForm ? 1 : 0,
                                marginTop: displayForm ? 16 : 0,
                            }}
                            sx={{ overflow: 'hidden', height: 0 }}
                        >
                            {/* TODO: Use AddressForm component, use fields and setFields, add actions bellow. */}
                            {/* on actions, use address endpoints to add address */}

                            {mode === 'checkout' && (
                                // must have state.cart context to work
                                <ShippingForm
                                    inlineForm
                                    onCancel={hideAddressForm}
                                    onContinue={toggle}
                                />
                            )}

                            {mode === 'account' && (
                                <Box>
                                    <AddressForm
                                        fields={fields}
                                        setFields={setFields}
                                    />
                                    <Stack direction="row" spacing={1} mt={2}>
                                        <Button
                                            onClick={hideAddressForm}
                                            sx={{ flex: 1 }}
                                            color="neutral"
                                        >
                                            Cancel
                                        </Button>
                                        <ListaButton
                                            sx={{ flex: 1 }}
                                            variant="contained"
                                            disabled={!state.user.token}
                                            onClick={handleAddAddress}
                                            loading={addButtonLoading}
                                        >
                                            Add
                                        </ListaButton>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </>
            }
            contentClosed={
                selectedAddress && (
                    <>
                        <Box
                            component={motion.div}
                            sx={{ overflow: 'hidden', width: '100%' }}
                            animate={{
                                opacity: !expanded ? 1 : 0,
                                height: !expanded ? 'auto' : 0,
                            }}
                        >
                            <AddressItem address={selectedAddress} />
                        </Box>
                    </>
                )
            }
        />
    );
}
