import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import PageWrapDefault from '../layout/PageWrapDefault';

export default function DesignSystem() {
    return (
        <PageWrapDefault>
            <Box p={4}>
                <Typography variant="h1" sx={{ mb: 3 }}>
                    DESIGN SYSTEM
                </Typography>

                <Typography variant="h2" sx={{ mb: 1 }}>
                    Typography
                </Typography>
                <Grid>
                    <Typography variant="h1" sx={{ mb: 3 }}>
                        H1
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 3 }}>
                        H2
                    </Typography>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        H3
                    </Typography>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        H4
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        H5
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                        H6
                    </Typography>
                </Grid>
            </Box>
        </PageWrapDefault>
    );
}
