import React from 'react';
import { TextField } from '@mui/material';

export default function ListaTextField({ sx, ...props }) {
    return (
        <TextField
            sx={{
                '& input': { pt: 3, pb: 1 },

                '& .MuiInputBase-root': {
                    height: 52,
                },

                '& legend': {
                    maxWidth: '0.01px !important', // block width to show the full border
                },

                '& .MuiInputLabel-shrink': {
                    transform: 'scale(0.65) translate(22px, 11px)',
                },

                '& .MuiInputBase-inputAdornedStart': {
                    paddingLeft: '5px',
                },

                ...sx,
            }}
            {...props}
        />
    );
}
