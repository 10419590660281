import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Box } from '@mui/material';

export default function StyledSnackbarProvider({ children }) {
    return (
        <Box
            sx={{
                '.SnackbarContainer-root': {
                    bottom: '67px !important',
                    alignItems: 'flex-start',
                },

                '.SnackbarContent-root': {
                    borderRadius: 2,
                },

                '.SnackbarItem-message': {
                    pt: '4px',
                    pb: '4px',
                },
            }}
        >
            <SnackbarProvider maxSnack={2}>{children}</SnackbarProvider>
        </Box>
    );
}
