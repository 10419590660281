import PageWrapDefault from '../layout/PageWrapDefault';
import Feed from '../elements/feed/Feed';
import HomeTabPanel from '../elements/home/HomeTabPanel';
import { useAppState } from '../../store/store';
import { useCallback, useEffect } from 'react';
import actions from '../../store/actions';
import { useLocation } from 'react-router-dom';
import { debounce } from '../../lib/functions';
import scan from '../../assets/lottie/scan.json';
import { Link } from 'react-router-dom';
import AnimatedFab from '../elements/shared/AnimatedFab';

const TABS = { TAGS: 'TAGS', FOLDERS: 'FOLDERS', SHOP: 'SHOP' };

export default function HomePage() {
    const { dispatch, state } = useAppState();
    const { pathname } = useLocation();

    const setCurrentTab = useCallback((newTab) => {
        dispatch({
            type: actions.SET_TAB,
            payload: newTab,
        });
    }, []);

    useEffect(() => {
        const setScrollOffset = debounce(() => {
            if (pathname === '/') {
                dispatch({
                    type: actions.SET_TAGS_SCROLL_OFFSET,
                    payload: window.scrollY,
                });
            }
        }, 200);

        window.addEventListener('scroll', setScrollOffset);
        return () => {
            window.removeEventListener('scroll', setScrollOffset);
        };
    }, [pathname]);

    return (
        <PageWrapDefault>
            <Feed currentTab={state?.currentTab || TABS.TAGS} tabs={TABS} />
            <HomeTabPanel
                currentTab={state?.currentTab || TABS.TAGS}
                setCurrentTab={setCurrentTab}
                tabs={TABS}
            />
            <Link to={`/scan`}>
                <AnimatedFab
                    animationData={scan}
                    sx={{
                        bottom: 94,
                    }}
                />
            </Link>
        </PageWrapDefault>
    );
}
