import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import {
    Box,
    Stack,
    Card,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
    Skeleton,
    CircularProgress,
} from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../assets/icons/TrashIcon.svg';
import DataController from '../../../lib/DataController';
import { useConfirmationPrompt } from '../../../contexts/ConfirmationPrompt';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../../store/store';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';

const slickSettings = {
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    centerMode: true,
};

export default function LinksCarousel({
    links,
    isEdit = false,
    goToFirstSlide = false,
}) {
    const slider = useRef(null);

    // anythime this flag is changed, slider will go to the first slide
    // trigger a change from parent component when you want the carousel to reset
    useEffect(() => {
        if (!slider.current) return;
        slider.current.slickGoTo(0);
    }, [goToFirstSlide]);

    return (
        <Box
            sx={{
                mx: -2,

                '& .slick-list': {
                    padding: '0 16px 0 0 !important',
                },

                '& .slick-track': {
                    display: 'flex !important',
                    alignItems: 'stretch !important',
                },

                '& .slick-slide': {
                    height: 'auto !important',
                    display: 'flex',
                    alignItems: 'stretch',
                },

                '& .slick-slide>div': {
                    pl: 2,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'stretch',
                },
            }}
        >
            <Slider ref={slider} {...slickSettings}>
                {links.map((link, index) => (
                    <LinkCard
                        key={index}
                        link={link}
                        isEdit={isEdit}
                        skeleton={link.isSkeleton}
                    />
                ))}
            </Slider>
        </Box>
    );
}

function LinkCard({ link, skeleton, isEdit }) {
    const [deleting, setDeleting] = useState(false);
    const { setConfirmationPrompt } = useConfirmationPrompt();
    const { t } = useTranslation();
    const { dispatch, actions } = useAppState();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = (e) => {
        e.preventDefault();

        setConfirmationPrompt({
            title: t('addDocumentsDeletePromptTitle'),
            body: t('addDocumentsDeletePromptBody'),
            confirmText: t('addDocumentsDeletePromptConfirmText'),
        }).then(
            // on confirmed
            () => {
                setDeleting(true);

                DataController.link
                    .delete({ id: link.id })
                    .then(({ success, data, errors, message }) => {
                        if (success) {
                            dispatch({
                                type: actions.REMOVE_POST_BOOKMARK, // TODO: REMOVE POST LINK
                                payload: link.id,
                            });
                            enqueueSnackbar(message);
                        } else {
                            enqueueSnackbar(message, {
                                variant: 'error',
                            });
                        }
                    })
                    .finally(() => setDeleting(false));
            },
            // on rejected
            () => {}
        );
    };

    return (
        <Card
            sx={{
                display: 'block',
                border: `1px solid`,
                borderColor: 'neutral.300',
                borderRadius: 2,
                position: 'relative',
                width: '100%',
                opacity: deleting ? 0.5 : 1,
                pointerEvents: deleting ? 'none' : 'all',
            }}
            elevation={0}
            component={skeleton ? Box : 'a'}
            href={link.url}
            target="_blank"
            rel="noreferrer"
        >
            {skeleton ? (
                <CardMedia sx={{ height: 140 }}>
                    <Skeleton variant="rectangular" height={140} />
                </CardMedia>
            ) : (
                <CardMedia sx={{ height: 140 }} image={link.metadata.image} />
            )}
            <CardContent>
                {skeleton ? (
                    <Typography gutterBottom variant="body1" component="div">
                        <Skeleton />
                    </Typography>
                ) : (
                    <Typography gutterBottom variant="body1" component="div">
                        {link.metadata.title}
                    </Typography>
                )}
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {skeleton ? (
                        <Typography variant="body2" color="text.secondary">
                            {link.url}
                        </Typography>
                    ) : (
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{
                                '& img': {
                                    objectFit: 'contain',
                                    objectPosition: 'center center',
                                },
                            }}
                        >
                            <img
                                src={link.metadata.favicon}
                                width={16}
                                height={16}
                            />
                            <Typography variant="body2" color="text.secondary">
                                {link.metadata.domain}
                            </Typography>
                        </Stack>
                    )}

                    {!skeleton && isEdit && (
                        <Box>
                            <IconButton color="error" onClick={handleDelete}>
                                {deleting ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <TrashIcon width="16" />
                                )}
                            </IconButton>
                        </Box>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
