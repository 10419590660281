import palette from '../palette';

const MuiButton = {
    defaultProps: {
        disableElevation: true,
    },

    styleOverrides: {
        root: {
            minHeight: 48,
            borderRadius: 8,
        },

        contained: {
            border: '2px solid',

            '&:hover': {
                border: '2px solid',
            },
        },

        containedPrimary: {
            borderColor: palette.primary[600],

            '&:hover': {
                backgroundColor: palette.primary[600],
            },

            '&.Mui-disabled': {
                borderColor: palette.primary[600],
                backgroundColor: palette.primary[600],
                color: palette.neutral[100],
                opacity: 0.5,
            },
        },

        containedFullWidth: {
            border: '2px solid',
            backgroundColor: palette.primary.main,
            borderColor: palette.primary[600],
            color: palette.white.main,
            width: '100%',

            '&:hover': {
                border: '2px solid',
            },
        },

        containedNeutral: {
            borderColor: palette.neutral[400],
            backgroundColor: palette.neutral[300],
            color: palette.neutral[700],

            '&:hover': {
                backgroundColor: palette.neutral[400],
            },
        },

        outlined: {
            border: '2px solid',

            '&:hover': {
                border: '2px solid',
            },
        },

        outlinedNeutral: {
            borderColor: palette.neutral[400],
        },

        text: {
            // textDecoration: 'underline',
        },
    },
};

export default MuiButton;
