import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { duration, easing } from '../../theme/motionConfig';

export default function PageWrapModal({ children }) {
    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, 0);
    //     }, 10);
    // }, []);

    return (
        <Paper
            elevation={10}
            sx={{
                minHeight: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 100,
            }}
            component={motion.div}
            initial={{
                y: 100,
                scale: 0.9,
                opacity: 0,
            }}
            animate={{
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                    duration: duration.long,
                    ease: easing.decelerate,
                },
            }}
            exit={{
                y: 100,
                scale: 1,
                opacity: 0,
                transition: {
                    duration: duration.longOut,
                    easing: easing.accelerate,
                },
            }}
        >
            {children}
        </Paper>
    );
}
