import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { useTags } from '../../../contexts/TagsContext';
import useWindowSize from '../../../lib/hooks/useWindowSize';
import { useAppState } from '../../../store/store';
import sharedStyles from '../../../theme/sharedStyles';

export default function Header({
    title,
    colorway,
    cssColor,
    isFolderView = false,
}) {
    const { t } = useTranslation();
    const [windowWidth] = useWindowSize();
    const { state } = useAppState();
    const { invalidate } = useTags();

    let backLink = '/';
    if (isFolderView) {
        backLink = '/';
    } else if (state.tagsGridContext.grid === 'tags') {
        backLink = '/';
    } else if (state.tagsGridContext.grid === 'folder') {
        backLink = `/folder/${state.tagsGridContext.folderId}`;
    }

    return (
        <Stack
            component="header"
            direction="row"
            justifyContent="space-between"
            alignItems={isFolderView ? 'center' : 'flex-start'}
            px={2}
            py={1}
            sx={{
                borderBottom: `1px solid`,
                borderColor: 'neutral.300',
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 10,
                ...sharedStyles.getFixedContainerStyles(windowWidth),
                ...sharedStyles.glassmorphism,
            }}
        >
            <Box>
                <Typography variant="h4" sx={{
                    ml: -0.5,
                    mt: 1,
                    mb: .5,
                    color:
                        colorway === 'Pearl White'
                            ? "#000"
                            : cssColor,
                }}>
                    {title}
                </Typography>
                {/*
                {!isFolderView && (
                    <Stack direction="row" alignItems="flex-end">
                        <Typography variant="h6" color="neutral.500">
                            {t('in')}
                        </Typography>
                        <Typography variant="h5" color={cssColor}>
                            &nbsp;
                            <Box
                                component="span"
                                sx={{
                                    textShadow:
                                        colorway === 'Pearl White'
                                            ? '0px 0px 2px rgba(0, 0, 0, 0.4)'
                                            : 'none',
                                }}
                            >
                                {colorway}
                            </Box>
                        </Typography>
                    </Stack>
                )}
                */}
            </Box>

            <Link to={backLink} onClick={isFolderView ? invalidate : undefined}>
                <IconButton
                    color="secondary"
                    sx={{ width: 21, height: 21, p: 0, mt: .5 }}
                >
                    <Box
                        component={CloseIcon}
                        viewBox="6 6 21 21"
                        width="100%"
                    />
                </IconButton>
            </Link>
        </Stack>
    );
}
