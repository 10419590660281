import {
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useAppState } from '../../../store/store';
import { Box, Button } from '@mui/material';
import { motion } from 'framer-motion';
import ShippingForm from '../checkout/ShippingForm';
import { scrollIntoView } from '../../../lib/functions';
import ListaAccordion from '../shared/ListaAccordion';
import ListaButton from '../shared/ListaButton';
import CompaniesMoreMenu from './CompaniesMoreMenu';
import CompaniesForm from './CompaniesForm';
import DataController from '../../../lib/DataController';
import { useSnackbar } from 'notistack';

export default function CompaniesPicker({
    selectedAddressId,
    onChange,
    title = 'Companies',
}) {
    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch, actions } = useAppState();

    const [expanded, setExpanded] = useState(false);
    const [settingAddress, setSettingAddress] = useState(false);
    const [displayForm, setDisplayForm] = useState(false);
    const dividerRef = useRef();

    const [fields, setFields] = useState({
        name: '',
        email: '',
        phone: '',
        website: '',
        company_id: null,
    });
    const [errors, setErrors] = useState(null);
    const [addButtonLoading, setAddButtonLoading] = useState(false);

    const handleCreateCompany = async () => {
        setAddButtonLoading(true);

        await DataController.companies
            .create({
                fields,
            })
            .then(({ success, data, errors, message }) => {
                if (success) {
                    dispatch({ type: actions.ADD_COMPANY, payload: data });
                    enqueueSnackbar(message);
                    hideForm();
                } else {
                    setErrors(errors);
                    console.error(message);
                    enqueueSnackbar(message, { type: 'error' });
                }
            });

        setAddButtonLoading(false);
    };

    const toggle = () => {
        if (expanded) {
            setDisplayForm(false);
        }

        setExpanded(!expanded);
    };

    const handleChange = async (id) => {
        if (id === selectedAddressId) {
            return;
        }
        setSettingAddress(id);
        await onChange(id);
        setSettingAddress(false);
        toggle();
    };

    const hideForm = () => {
        scrollIntoView(dividerRef.current);
        setDisplayForm(false);
        setFields({
            name: '',
            email: '',
            phone: '',
            website: '',
            company_id: null,
        });
        setErrors(null);
    };

    const CompanyItem = ({ company }) => {
        return (
            <Stack
                component={motion.div}
                key={company.id}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    textAlign: 'left',
                    marginTop: 2,
                    width: '100%',
                }}
            >
                <Stack>
                    <Typography variant="caption">
                        {company?.name ?? ''}
                    </Typography>
                    <Typography variant="body2">
                        {company?.phone ?? ''}
                    </Typography>
                    <Typography variant="body2">
                        {company?.email ?? ''}
                    </Typography>
                    <Typography variant="body2">
                        {company?.website ?? ''}
                    </Typography>
                </Stack>

                <CompaniesMoreMenu company={company} />
            </Stack>
        );
    };

    return (
        <ListaAccordion
            expanded={expanded}
            onToggle={toggle}
            title={title}
            contentExpanded={
                <>
                    {(state.user.companies || []).map((company) => (
                        <CompanyItem key={company.id} company={company} />
                    ))}
                    <Box
                        component={motion.div}
                        animate={{
                            opacity: expanded ? 1 : 0,
                            height: expanded ? 'auto' : 0,
                            marginTop: expanded ? 16 : 0,
                        }}
                        sx={{ overflow: 'hidden' }}
                    >
                        <Divider sx={{ mt: 2 }} ref={dividerRef} />
                        <Box
                            key="addbutton"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 0 : 'auto',
                                opacity: displayForm ? 0 : 1,
                                marginBottom: displayForm ? 0 : -16,
                            }}
                            sx={{ overflow: 'hidden' }}
                        >
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => setDisplayForm(true)}
                            >
                                + Add company
                            </Button>
                        </Box>
                        <Box
                            key="form"
                            component={motion.div}
                            animate={{
                                height: displayForm ? 'auto' : 0,
                                opacity: displayForm ? 1 : 0,
                                marginTop: displayForm ? 16 : 0,
                            }}
                            sx={{ overflow: 'hidden', height: 0 }}
                        >
                            <CompaniesForm
                                fields={fields}
                                setFields={setFields}
                                errors={errors}
                            />

                            <Stack direction="row" spacing={1} mt={2}>
                                <Button
                                    onClick={hideForm}
                                    sx={{ flex: 1 }}
                                    color="neutral"
                                >
                                    Cancel
                                </Button>
                                <ListaButton
                                    sx={{ flex: 1 }}
                                    variant="contained"
                                    disabled={!state.user.token}
                                    onClick={handleCreateCompany}
                                    loading={addButtonLoading}
                                >
                                    Add
                                </ListaButton>
                            </Stack>
                        </Box>
                    </Box>
                </>
            }
        />
    );
}
